<template>
  <div id="reimbursement-setting">
    <!-- 报销设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="reimbursement-setting-screen-tabs">
          <template v-for="(item, index) in tabList">
            <div
              :class="{
                'reimbursement-setting-screen-tab': true,
                'is-active': tabIndex == item.text,
              }"
              v-showFunction="{
                active: item.trueActive,
                array: allFunctionButtons,
              }"
              :key="index"
              @click="changeTab(item, index)"
            >
              {{ item.text }}
            </div>
          </template>
          <div class="spl"></div>
          <div
            class="but"
            v-if="tabIndex == '报销流程'"
            @click="handleClick('add')"
          >
            <i class="el-icon-plus"></i>添加条件
          </div>
          <div
            class="but"
            v-if="tabIndex == '报销流程'"
            @click="handleClick('save')"
          >
            <i class="el-icon-finished"></i>保存
          </div>
          <div
            class="but"
            v-if="tabIndex == '常用信息设置'"
            @click="handleClick('addInformation')"
          >
            <i class="el-icon-plus"></i>添加常用信息
          </div>
          <!-- v-showFunction="{
              active: 'addContractSubject',
              array: allFunctionButtons,
            }" -->
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="reimbursement-table-card" v-loading="loading">
        <!-- 1. 选项值设置 -->
        <div
          class="reimbursement-table-card-content"
          v-if="tabIndex == '选项值设置'"
          v-showFunction="{
            active: 'showOption',
            array: allFunctionButtons,
          }"
        >
          <div class="reimbursement-table-card-content-tags-setting">
            <div class="reimbursement-table-card-content-tags-setting-left">
              <div
                :class="{
                  'reimbursement-table-card-content-tags-setting-left-line': true,
                  'is-active': index == tagsIndex,
                }"
                v-for="(item, index) in optionValueList1"
                :key="index"
                @click="tagsIndex = index"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="reimbursement-table-card-content-tags-setting-right">
              <div
                class="reimbursement-table-card-content-tags-setting-right-add"
              >
                <!-- v-showFunction="{
                  active: 'editOption',
                  array: allFunctionButtons,
                }" -->
                <div class="butt" @click="addTags(1, -1)">添加选项</div>
              </div>
              <div
                class="reimbursement-table-card-content-tags-setting-right-list"
                v-if="optionValueList1[tagsIndex].value.length == 0"
              >
                没有相关数据!
              </div>
              <div
                class="reimbursement-table-card-content-tags-setting-right-list"
                v-if="optionValueList1[tagsIndex].value.length > 0"
              >
                <div
                  class="reimbursement-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 || !optionFlag
                        ? ''
                        : (item.flag = !item.flag)
                    "
                  >
                    {{ index + 1 }}、{{ item.name }}
                  </div>
                  <div v-else>
                    {{ index + 1 }}、
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(3, index)"
                      @keyup.enter.native="addTags(3, index)"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 2. 报销流程 -->
        <div
          class="reimbursement-table-card-content1"
          v-if="tabIndex == '报销流程'"
          v-showFunction="{
            active: 'process',
            array: allFunctionButtons,
          }"
        >
          <!-- <div
            class="item"
            v-for="(item, index) in reimbursementList"
            :key="index"
          >
            <reimbursement_item
              :reimbursementItemData="item"
              :itemIndex="index"
              @itemClick="itemClick"
            ></reimbursement_item>
          </div> -->
          <div class="block">
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in reimbursementList"
                :key="index"
                :timestamp="`优先级${item.priority}`"
                placement="top"
              >
                <el-card class="card">
                  <reimbursement_item
                    :reimbursementItemData="item"
                    :itemIndex="index"
                    @itemClick="itemClick"
                  ></reimbursement_item>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <!-- 3. 常用信息设置 -->
        <div
          class="reimbursement-table-card-content2"
          v-if="tabIndex == '常用信息设置'"
        >
          <div class="reimbursement-table-card-content2-content">
            <el-table
              :data="tableData"
              highlight-current-row
              :row-style="{ height: '60px' }"
              style="width: 100%"
              height="98%"
            >
              <el-table-column type="index" width="40" fixed> </el-table-column>
              <el-table-column prop="payeeName" label="收款人全称">
              </el-table-column>
              <el-table-column prop="bankAccount" label="银行账号">
              </el-table-column>
              <el-table-column
                prop="bankOfDeposit"
                label="开户行"
              ></el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    icon="el-icon-edit-outline"
                    @click.native="handleClick('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    plain
                    icon="el-icon-edit-outline"
                    @click.native="handleClick('delete', scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="reimbursement-table-card-content2-bottom">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageData.currentPage"
              :page-size="pageData.pageSize"
              layout="total,sizes, prev, pager, next,jumper"
              :total="pageData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as reimbursementJsData from "./js/reimbursement.js";
import * as baseData from "@/assets/js/baseData.js";
import reimbursement_item from "./components/reimbursement_item.vue";
import my_dialog from "@/components/dialog.vue";
export default {
  components: { reimbursement_item, my_dialog },
  data() {
    return {
      tags: [
        { name: "标签一", type: "" },
        { name: "标签二", type: "success" },
        { name: "标签三", type: "info" },
        { name: "标签四", type: "warning" },
        { name: "标签五", type: "danger" },
      ],
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      tabIndex: "选项值设置",
      tagsIndex: 0,
      tabList: [
        {
          text: "选项值设置",
          name: "选项值设置",
          flag: 0,
          active: "showOption",
        },
        {
          text: "报销流程",
          name: "报销流程",
          flag: 0,
          active: "reimbursementFlow",
        },
        {
          text: "常用信息设置",
          name: "常用信息设置",
          flag: 0,
          active: "informationSetting",
        },
      ],
      optionValueList1: [{ name: "", value: [{ name: "", value: "" }] }], // 选项值设置
      tempTagList: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加流程条件",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      optionFlag: true, // 选项值是否可编辑
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "reimburseSetting"
      ),

      // reimbursementList: [],
      reimbursementList: reimbursementJsData.initReimbursementData(),
      options: {
        deCon: [], // 部门树
        approvers: [], // 审核人
        twoApprovers: [], // 第二审核人
        payer: [], // 付款确认人
        priority: [], // 优先级
      },
      tempReimbursement: { id: "" },
      tempReimbursementIdex: 0,
      tempReimbursemenPriority: -1,
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "reimburseSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
          // 单独处理编辑选项值  editOption
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            if (element.key == "editOption") {
              if (element.authority == "1") {
                this.optionFlag = true;
              } else {
                this.optionFlag = false;
              }
            }
          }
          console.log("当前选项值是否可以编辑:", this.optionFlag);
        }
      );
    },
    /**
     * 修改标签
     */
    updateTagList(array) {
      let requestData = {
        list: array,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/updateTagList",
        requestData,
        (data) => {
          this.getTagList();
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.getTagList();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加标签选项
     */
    addTags(flag, index) {
      switch (flag) {
        case 1:
          //  添加一般选项,即添加一个输入框
          let dd = {
            name: "",
            value: "",
            flag: false,
          };
          this.optionValueList1[this.tagsIndex].value.push(dd);
          break;

        case 3:
          //  输入框失去焦点事件
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          this.optionValueList1[this.tagsIndex].value[index].flag = !this
            .optionValueList1[this.tagsIndex].value[index].flag;
          if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
            this.optionValueList1[this.tagsIndex].value[
              index
            ].value = this.optionValueList1[this.tagsIndex].value[index].name;
          } else {
            this.optionValueList1[this.tagsIndex].value.splice(index, 1);
          }
          this.updateTagList(this.tempTagList);
          break;
        default:
          break;
      }
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.options.deCon = data;
          this.getEmployeeListByCompany();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取表格数据
     * state:是否包含离职员工
     */
    getEmployeeListByCompany() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        search: "",
        isDiMission: false, // 是否包含离职
      };
      this.$request(
        "post",
        "/user/getEmployeeList",
        requestData,
        (data) => {
          this.options.approvers = [];
          this.options.payer = [];
          this.options.twoApprovers = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.options.approvers.push(dd);
            this.options.payer.push(dd);
            this.options.twoApprovers.push(dd);
          }
        },
        (errorMessage) => {
          this.Loading();
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取选项值标签
     */
    getTagList() {
      let requestData = {
        // model: "thread",
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.tempTagList = [];
          this.optionValueList1 = [];
          if (data.length == 0) {
            // 初次获取该模块标签,读取配置文件
            requestData.type.forEach((element) => {
              this.tempTagList.push(
                baseData.getTagList(element, "reimbursement")
              );
            });
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          } else {
            this.tempTagList = data;
            if (data.length != requestData.type.length) {
              baseData
                .initTagListPart(data, requestData.type)
                .forEach((element) => {
                  this.tempTagList.push(
                    baseData.getTagList(element, "reimbursement")
                  );
                });
            }
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          }

          console.log("当前模块标签:", this.optionValueList1);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 切换tab
     */
    changeTab(data, index) {
      // if (this.tabList[index].flag < 3) {
      this.loading = true;
      this.tabIndex = data.text;
      switch (index) {
        case 0:
          // 选项值设置
          this.Loading();
          this.getTagList();
          break;
        case 1:
          // 报销流程设置
          this.Loading();
          this.seeProcess();
          break;
        case 2:
          // 常用信息设置
          this.Loading();
          this.initTableData();
          break;

        default:
          break;
      }
    },
    /**
     * 查看流程
     */
    seeProcess() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "报销流程",
      };
      this.$request(
        "post",
        "/reimburse-mode/seeProcess",
        requestData,
        (data) => {
          this.Loading();
          if (data.length > 0) {
            this.reimbursementList = data;
            // 保证最后一条数据的isDefault为0
            if (data.length > 0) {
              this.reimbursementList[
                this.reimbursementList.length - 1
              ].isDefault = "0";
            }
          }
        }
      );
    },
    // 报销流程单流程的编辑事件
    itemClick(data) {
      console.log("编辑事件", data);
      // if (data.data.isDefault === "0") {
      //   this.$message({
      //     type: "warning",
      //     message: "默认条件不可编辑",
      //   });
      //   return;
      // }
      switch (data.active) {
        case "edit":
          // 编辑
          if (data.index == this.reimbursementList.length - 1) {
            this.$message({
              type: "warning",
              message: "默认条件不可编辑",
            });
            return;
          }
          this.dialogData.title = "编辑流程条件";
          this.dialogData.buttonArray = [
            { text: "确定", active: "editReimbursement" },
            { text: "取消", active: "cancel" },
          ];
          this.tempReimbursement = JSON.parse(JSON.stringify(data.data));
          this.tempReimbursementIdex = data.index;
          this.options.priority = [];
          for (
            let index = 0;
            index < this.reimbursementList.length - 1;
            index++
          ) {
            const element = this.reimbursementList[index];
            this.options.priority.push({ label: index + 1, value: index + 1 });
          }
          this.tempReimbursemenPriority = data.data.priority;
          this.dialogData.data = reimbursementJsData.getDialogDefaultData(
            data.data,
            this.options
          );
          this.dialogData.flag = true;
          break;
        case "delete":
          // 删除
          if (data.index == this.reimbursementList.length - 1) {
            this.$message({
              type: "warning",
              message: "默认条件不可删除",
            });
            return;
          }
          this.$confirm("此操作将永久删除该条件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.reimbursementList = reimbursementJsData.deleteIndex(
                this.reimbursementList,
                data.index
              );
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    // 添加条件/保存设置
    handleClick(active, data) {
      this.tempData = data;
      let requestData = {};
      switch (active) {
        case "add":
          // 添加条件
          this.tempReimbursement = { id: "" };
          this.tempReimbursementIdex = -1;
          this.dialogData.title = "添加流程条件";
          this.dialogData.buttonArray = [
            { text: "确定", active: "addReimbursement" },
            { text: "取消", active: "cancel" },
          ];
          let dd = {
            id: "",
            name: "报销流程",
            companyId: this.baseInfo.company.companyId,
            deCon: [],
            approvers: [],
            twoApprovers: [],
            payer: [],
            moneyCon: ["", ""],
            priority: "",
            isDefault: "1",
          };
          this.options.priority = [];
          for (let index = 0; index < this.reimbursementList.length; index++) {
            const element = this.reimbursementList[index];
            this.options.priority.push({ label: index + 1, value: index + 1 });
          }
          this.dialogData.data = reimbursementJsData.getDialogDefaultData(
            dd,
            this.options
          );
          this.dialogData.flag = true;
          break;
        case "save":
          // 保存设置
          console.log("保存流程设置", this.reimbursementList);
          requestData = {
            companyId: this.baseInfo.company.companyId,
            name: "报销流程",
            model: this.reimbursementList,
          };
          this.$request(
            "post",
            "/reimburse-mode/saveProcess",
            requestData,
            (data) => {
              this.$message({
                message: "流程设置成功!",
                type: "success",
              });
              this.seeProcess();
            }
          );
          break;
        case "addInformation":
          this.dialogData.title = "添加常用支付信息";
          this.dialogData.buttonArray = [
            { text: "确定", active: "addInformation" },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "收款人全称",
              type: "input",
              value: "",
            },
            {
              title: "银行账户",
              type: "input",
              value: "",
            },
            {
              title: "开户行",
              type: "input",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "edit":
          this.dialogData.title = "编辑常用支付信息";
          this.dialogData.buttonArray = [
            { text: "确定", active: "editInformation" },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "收款人全称",
              type: "input",
              value: this.tempData.payeeName,
            },
            {
              title: "银行账户",
              type: "input",
              value: this.tempData.bankAccount,
            },
            {
              title: "开户行",
              type: "input",
              value: this.tempData.bankOfDeposit,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "delete":
          requestData = {
            ids: [this.tempData.id],
          };
          this.$confirm("确定要删除该项数据吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contractCommonExpenses/del",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.dialogData.flag = false;
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    // dialog操作事件
    drawerOper(active) {
      let requestData = {};
      let priority = 0;
      switch (active) {
        case "addReimbursement":
          // 确定添加流程条件
          requestData = reimbursementJsData.getReturnDialogData(
            this.dialogData.data,
            this
          );
          console.log("当前dialog数据值:", requestData);
          this.$set(requestData, "id", "");
          this.$set(
            requestData,
            "isDefault",
            this.tempReimbursementIdex == -1
              ? "1"
              : this.tempReimbursementIdex == this.reimbursementList.length - 1
              ? "0"
              : "1"
          );
          priority =
            requestData.priority == ""
              ? this.reimbursementList.length - 1
              : requestData.priority;
          requestData.priority = this.reimbursementList.length;
          this.reimbursementList.push(requestData);
          // 修改优先级
          this.reimbursementList = reimbursementJsData.changeIndex(
            this.reimbursementList,
            priority,
            this.reimbursementList.length - 1
          );
          this.dialogData.flag = false;
          break;
        case "editReimbursement":
          // 确定编辑流程条件
          requestData = reimbursementJsData.getReturnDialogData(
            this.dialogData.data,
            this
          );
          console.log("当前dialog数据值:", requestData);
          this.$set(requestData, "id", this.tempReimbursement.id);
          this.$set(
            requestData,
            "isDefault",
            this.tempReimbursementIdex == -1
              ? "1"
              : this.tempReimbursementIdex == this.reimbursementList.length - 1
              ? "0"
              : "1"
          );
          requestData.priority =
            requestData.priority > this.tempReimbursemenPriority // 修改后的优先级比原优先级大  即往后调
              ? requestData.priority + 1
              : requestData.priority == this.tempReimbursemenPriority // 没有修改优先级  不动
              ? requestData.priority
              : requestData.priority - 1; // 修改后的优先级比原优先级小  即往前调整
          // 修改当前索引的基本信息
          this.reimbursementList.splice(
            this.tempReimbursementIdex,
            1,
            requestData
          );
          console.log("修改对应索引信息后的当前流程:", this.reimbursementList);
          // 优先级排序
          this.reimbursementList = this.$sortUtil.positiveSequence(
            this.reimbursementList,
            "priority"
          );
          // 重构优先级
          this.reimbursementList = reimbursementJsData.initReimbursementDataPriority(
            this.reimbursementList
          );
          this.dialogData.flag = false;
          break;
        case "addInformation":
          // 添加常用信息
          requestData = {
            userId: this.baseInfo.userId,
            payeeName: this.dialogData.data[0].value, // 收款人全称
            bankAccount: this.dialogData.data[1].value, // 银行账号
            bankOfDeposit: this.dialogData.data[2].value, // 开户行
          };
          this.$request(
            "post",
            "/contractCommonExpenses/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "editInformation":
          // 编辑常用信息
          requestData = {
            id: this.tempData.id,
            userId: this.baseInfo.userId,
            payeeName: this.dialogData.data[0].value, // 收款人全称
            bankAccount: this.dialogData.data[1].value, // 银行账号
            bankOfDeposit: this.dialogData.data[2].value, // 开户行
          };
          this.$request(
            "post",
            "/contractCommonExpenses/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // dialog关闭
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    // dialog关闭事件
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    initTableData() {
      let requestData = {
        currentPage: this.pageData.currentPage, // 第几页
        pageSize: this.pageData.pageSize, // 每页多少条
      };
      this.$request(
        "post",
        "/contractCommonExpenses/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    // this.getProtectDay(true);
    // this.getTagList();
    this.loading = false;
    this.isCharge();
    this.getTagList();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#reimbursement-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .reimbursement-setting-screen-tabs {
    width: 98%;
    height: 100%;
    display: flex;
    font-size: 14px;
    margin-left: 20px;
    align-items: center;
    .reimbursement-setting-screen-tab {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: 0.3s;
      box-sizing: border-box;
    }
    .reimbursement-setting-screen-tab:hover {
      cursor: pointer;
      color: @activeColor;
    }
    .is-active {
      color: @activeColor;
      border-bottom: 4px solid @activeColor;
      box-sizing: border-box;
    }
    .spl {
      flex: 1;
    }
    > .but {
      width: auto;
      padding: 0 20px;
      box-sizing: border-box;
      border: 1px solid @activeColor;
      background-color: @activeBackground;
      color: @activeColor;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @contentFontSize4;
      cursor: pointer;
      border-radius: 5px;
      margin: 0 5px;
      i {
        margin-right: 5px;
      }
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .reimbursement-table-card {
      width: 98%;
      height: 95%;
      max-height: 830px;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 1px;
      }
      .reimbursement-table-card-content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .reimbursement-table-card-content-line,
        .reimbursement-table-card-content-line2 {
          width: 100%;
          flex: 1;
          // min-height: 200px;
          height: 200px;
          border-bottom: 1px solid @boderColor;
          display: flex;
          flex-direction: column;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .reimbursement-table-card-content-line-title {
            width: 98%;
            height: 40px;
            margin-left: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              height: 60%;
              display: flex;
              align-items: flex-end;
              color: @tipColor;
              font-size: @contentFontSize;
              margin-left: 10px;
            }
            .reimbursement-table-card-content-line-title-save {
              width: auto;
              height: 30px;
              // margin-right: 20px;
              padding: 0 10px;
              border: 1px solid @activeColor3;
              background-color: @activeBackground;
              border-radius: 5px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s;
              font-size: 12px;
              &:hover {
                cursor: pointer;
                // transform: scale(1.02);
                background-color: @activeColor;
                color: #fff;
              }
            }
          }
          .reimbursement-table-card-content-line-value {
            width: 95%;
            height: 90%;
            display: flex;
            margin-left: 40px;
            .el-tag + .el-tag {
              margin-left: 20px;
            }
            .button-new-tag {
              margin-left: 20px;
              height: 32px;
              line-height: 30px;
              padding-top: 0;
              padding-bottom: 0;
            }
            .input-new-tag {
              width: 90px;
              margin-left: 10px;
              vertical-align: bottom;
            }
            .div-tags {
              height: auto;
              width: 96%;
              display: flex;
              flex-wrap: wrap;
              & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                & > span {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .div-tag {
                width: auto;
                min-width: 30px;
                height: 25px;
                margin: 10px;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid @boderActiveColor;
                transition: 0.5s;
                font-size: @contentFontSize;
              }
              .div-tag:hover {
                cursor: pointer;
                border: 1px dashed @activeColor;
                background-color: @activeBackground;
                color: @activeColor;
              }
            }
            .reimbursement-table-card-content-line-value-button {
              width: 98%;
              flex: 1;
              height: 0;
              // overflow: auto;
              display: flex;
              .reimbursement-table-card-content-line-value-button-title {
                width: 15%;
                height: 98%;
              }
              .reimbursement-table-card-content-line-value-button-values {
                flex: 1;
                height: 98%;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                min-height: 190px;

                &::-webkit-scrollbar {
                  width: 1px;
                }
              }
            }
            .reimbursement-table-card-content-line-value-button:nth-child(1) {
              // height: 120px;
              border-bottom: 1px solid @boderColor;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
          }
          .div-flex-column {
            flex-direction: column;
          }
          .div-flex-space-between {
            justify-content: space-between;
          }
        }
        .reimbursement-table-card-content-line2 {
          max-height: 400px;
        }
        .reimbursement-table-card-content-line {
          min-height: 200px;
        }

        .reimbursement-table-card-content-tags-setting {
          width: 100%;
          height: 100%;
          display: flex;
          .reimbursement-table-card-content-tags-setting-left {
            width: 8vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid @activeColor3;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            .reimbursement-table-card-content-tags-setting-left-line {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: @contentFontSize;
              transition: 0.3s;
              color: #000;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
            .is-active {
              color: @activeColor;
            }
          }
          .reimbursement-table-card-content-tags-setting-right {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .reimbursement-table-card-content-tags-setting-right-add {
              width: 100%;
              height: 8vh;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 20px;
              .butt {
                width: 100px;
                height: 40px;
                border: 1px solid @activeColor;
                background-color: @activeColor;
                color: #fff;
                font-size: @contentFontSize;
                display: flex;
                align-items: center;
                border-radius: 10px;
                justify-content: center;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: @activeColor2;
                }
              }
            }
            .reimbursement-table-card-content-tags-setting-right-list {
              flex: 1;
              height: 0;
              width: 97%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 1px;
              }
              .reimbursement-table-card-content-tags-setting-right-line {
                height: 50px;
                min-height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                box-sizing: border-box;
                border-bottom: 1px solid @boderColor;
                justify-content: space-between;
                & > div {
                  display: flex;
                  width: 100%;
                }
              }
            }
          }
        }
        .reimbursement-table-card-content-con {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          margin: 50px;
          .reimbursement-table-card-content-con_title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            .reimbursement-table-card-content-con_title_tip {
              color: rgb(250, 92, 0);
              font-size: 12px;
              font-weight: normal;
              margin-left: 10px;
              margin-top: 5px;
            }
          }
          .reimbursement-table-card-content-con_value {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            .el-input {
              width: 220px !important;
              .el-input__inne {
                width: 100% !important;
              }
            }
            span {
              margin-left: 10px;
            }
          }
          .reimbursement-table-card-content-con_buttons {
            width: 60%;
            height: auto;
            display: flex;
            margin-top: 10px;
            .reimbursement-table-card-content-con_button {
              width: 90px;
              height: 30px;
              margin-left: 20px;
              background-color: rgb(53, 162, 250);
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              transition: 0.3s;
              &:hover {
                background-color: rgb(46, 137, 211);
                cursor: pointer;
              }
            }
          }
        }
        .el-table {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .reimbursement-table-card-content-search,
        .reimbursement-table-card-content-page {
          width: 100%;
          height: 4vh;
          display: flex;
          align-items: center;
          // background-color: aqua;
          padding: 0 1%;
          box-sizing: border-box;
          > .line {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > .but {
              width: auto;
              min-width: 50px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid @activeColor;
              font-size: @contentFontSize;
              background-color: @activeBackground;
              color: @activeColor;
              margin: 0 5px;
              margin-right: 10px;
              border-radius: 4px;
              transition: 0.5s;
              padding: 0 10px;
              box-sizing: border-box;
              &:hover {
                cursor: pointer;
                background-color: @activeBackground2;
              }
            }
          }
        }
      }
      .reimbursement-table-card-content1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 8px 0;
        box-sizing: border-box;
        > .item {
          width: 350px;
          height: 330px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          margin: 0 20px;
        }
      }
      .reimbursement-table-card-content2 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        .reimbursement-table-card-content2-content {
          flex: 1;
          width: 100%;
          height: 0;
          display: flex;
          align-content: center;
          justify-content: center;
          font-size: @contentFontSize;
          color: @closeColor;
        }
        .reimbursement-table-card-content2-bottom {
          width: 100%;
          height: 40px;
          border-top: 1px solid @boderColor;
          display: flex;
          align-items: center;
          .el-input__inner {
            width: 100px !important;
          }
        }
      }
    }
    .reimbursement-table-card-content1 {
      margin: 20px 0 0 100px;
    }
  }

  ::v-deep .el-timeline-item__timestamp.is-top {
    position: absolute;
    left: -84px;
    top: 144px;
    color: #909399;
  }

  .block {
    flex-direction: column;
  }

  ::v-deep
    #reimbursement-setting
    .reimbursement-table-card
    .reimbursement-table-card-content1[data-v-7089bd88] {
    flex-direction: column;
  }

  ::v-deep
    #reimbursement-setting
    .reimbursement-table-card
    .reimbursement-table-card-content1[data-v-7089bd88] {
    flex-direction: column;
  }

  .block {
    width: 90%;
  }
  .card {
    min-height: 200px;
  }
}
</style>
