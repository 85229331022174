<template>
  <div id="my-reimbursement">
    <!-- 我的报销 -->
    <div class="my-reimbursement-screen">
      <div class="my-reimbursement-screen-line">
        <div class="my-reimbursement-screen-search">
          <el-input
            placeholder="报销编号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="my-reimbursement-screen-search">
          <el-select
            v-model="searchData.type"
            placeholder="请选择报销类型"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.typeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="my-reimbursement-screen-search">
          <el-select
            v-model="searchData.status"
            placeholder="请选择报销状态"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.statusOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="my-reimbursement-screen-search">
          <el-date-picker
            v-model="searchData.submitTime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="提交开始月份"
            end-placeholder="提交结束月份"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="my-reimbursement-screen-search">
          <el-date-picker
            v-model="searchData.examineTime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="审批开始月份"
            end-placeholder="审批结束月份"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="my-reimbursement-screen-line">
        <div class="my-reimbursement-screen-search">
          <el-input
            placeholder="收款人全称"
            v-model="searchData.payeeName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="my-reimbursement-screen-search" style="width: 450px">
          <span style="width: 70px; font-size: 14px">金额范围:</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[0]"
              @keyup.enter.native="initTableData"
              @change="initTableData"
            >
            </el-input>
          </span>
          <span>-</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[1]"
              @keyup.enter.native="initTableData"
              @change="initTableData"
            >
            </el-input>
          </span>
          <span>元</span>
        </div>
        <div class="my-reimbursement-screen-active"></div>
        <div
          class="my-reimbursement-screen-but-select"
          style="backgroundColor: #67C23A; "
          @click="importReimbursement(3)"
          v-showFunction="{ active: 'payment', array: allFunctionButtons }"
        >
          付款
        </div>
        <div
          class="my-reimbursement-screen-but-select"
          @click="importReimbursement(0)"
          v-showFunction="{ active: 'addReimburse', array: allFunctionButtons }"
        >
          <!-- -->
          新建报销
        </div>
        <div
          class="my-reimbursement-screen-but-boder"
          @click="importReimbursement(1)"
          v-showFunction="{
            active: 'importReimburse',
            array: allFunctionButtons,
          }"
        >
          <!--  -->
          导入
        </div>
        <div
          class="my-reimbursement-screen-but-boder"
          @click="importReimbursement(2)"
          v-showFunction="{
            active: 'reportReimburse',
            array: allFunctionButtons,
          }"
        >
          <!--  -->
          导出
        </div>
      </div>
    </div>
    <div class="my-reimbursement-table">
      <div class="my-reimbursement-table-card">
        <div class="my-reimbursement-table-card-tabs">
          <div
            :class="{
              'my-reimbursement-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            未报销
          </div>
          <div
            :class="{
              'my-reimbursement-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            已报销
          </div>
          <div
            :class="{
              'my-reimbursement-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
            @click="changeTab('0')"
          >
            全部
          </div>
          <div class="my-reimbursement-table-card-active"></div>
          <div class="all-amount">
            总金额:<span>{{ amountAll }}</span
            >元
          </div>
        </div>
        <div class="my-reimbursement-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :property="item.key"
                :key="index"
                :label="item.name"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span
                    :class="{ 'table-tip': item.key == 'type' ? true : false }"
                    :style="{
                      color: item.key == 'type' ? scope.row.borderColor : '',
                      borderColor: scope.row.borderColor,
                    }"
                    v-if="scope.row[item.key] != ''"
                    >{{ scope.row[item.key] }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="140">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 'show')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'reimburseInfo',
                    array: allFunctionButtons,
                  }"
                  >查看</el-button
                >
                <el-button
                  v-if="
                    scope.row.status == '审批中' ||
                      scope.row.status == '已驳回' ||
                      scope.row.status == '已撤销'
                  "
                  @click="handleClick(scope.row, 'reSubmit')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'resubmitReimburse',
                    array: allFunctionButtons,
                  }"
                  >重新提交</el-button
                >
                <el-button
                  v-if="scope.row.status == '审批中'"
                  @click="handleClick(scope.row, 'revocation')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'cancelReimburse',
                    array: allFunctionButtons,
                  }"
                  >撤销</el-button
                >
                <el-button
                  v-if="scope.row.status == '已撤销'"
                  @click="handleClick(scope.row, 'delete')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'delReimburse',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="my-reimbursement-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
        @deleteItem="deleteItem"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as reimbursementJsData from "./js/reimbursement.js";
import * as allContractData from "@/views/contract/js/contractData.js";
export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        submitTime: ["", ""], // 提交时间
        examineTime: ["", ""], // 审批时间
        type: "", // 报销类型
        typeOptions: [],
        payOptions: [],
        status: "", // 报销状态
        statusOptions: [
          { name: "审批中", value: "1" },
          { name: "已驳回", value: "2" },
          { name: "已撤销", value: "3" },
          { name: "待付款", value: "4" },
          { name: "已完成", value: "5" },
        ],
        amountAll: ["", ""], // 金额范围
        check1: false,
        check2: false,
        payeeName: "",
      },

      allFunctionButtons: [],
      tabindex: "1",
      tabMenu: reimbursementJsData.getMyReimbursementTabMenu(),
      tableData: [],
      multipleSelection: [],
      amountAll: "0", // 总报销金额
      defaultSort: { prop: "", order: "" },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "45%",
        title: "新建报销",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      tempReimbursement: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "myReimburse"
      ),
      accountList: [], // 收款列表
      tempData: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "myReimburse",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "报销类型") {
              this.searchData.typeOptions = element.value;
            }
            if (element.type == "付款方式") {
              this.searchData.payOptions = element.value;
            }
          }
          console.log(this.searchData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    changeTab(status) {
      this.tabindex = status;
      this.initTableData();
    },
    // 初始化表格数据
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        userId: this.baseInfo.userId, // 用户id
        amountAll: this.searchData.amountAll, // 金额范围
        submitTime: this.searchData.submitTime, // 提交时间范围
        examineTime: this.searchData.examineTime, // 审批时间范围
        type: this.searchData.type, // 报销类型
        numbers: this.searchData.input, // 审批编号(不能模糊)
        status: this.searchData.status, // 状态
        statusFlag: this.tabindex, // 0全部 1已付款 2未付款
        payeeName: this.searchData.payeeName, // 收款人全称
        currentPage: this.pageData.currentPage, // 第几页
        pageSize: this.pageData.pageSize, // 每页多少条
      };
      this.$request(
        "post",
        "/reimburse/getMyReimburse",
        requestData,
        (data) => {
          this.loading = false;
          this.pageData.total = data.total;
          this.tableData = reimbursementJsData.initTableData(data.tableData);
          console.log("this.tableData", this.tableData);

          this.tableData.forEach((item) => {
            switch (item.status) {
              case "审批中":
                item.color = "#409EFF";
                break;
              case "已驳回":
                item.color = "#F56C6C";
                break;
              case "已撤销":
                item.color = "";
                break;
              case "待付款":
                item.color = "#E6A23C";
                break;
              case "已完成":
                item.color = "#67C23A";
                break;
              default:
                break;
            }
          });
          this.amountAll = data.totalAmount;
        },
        (errorMessage) => {
          this.loading = false;
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 报销信息操作
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {
        id: item.id,
      };
      this.$request(
        "post",
        "/reimburse/seeDetails",
        requestData,
        (data) => {
          this.tempReimbursement = JSON.parse(JSON.stringify(data));
          this.$set(this.tempReimbursement, "id", item.id);
          switch (active) {
            case "show":
              // 查看详情
              this.dialogData.title = "查看报销";
              this.dialogData.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                  key: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  type: "show-reimbursemen",
                  title: "",
                  isEditFlag: false,
                  value: data,
                },
              ];
              console.log("拿到详情数据:", data);
              this.dialogData.flag = true;
              break;
            case "reSubmit":
              // 重新提交
              this.dialogData.title = "重新提交";
              this.dialogData.buttonArray = [];
              this.dialogData.data = [];
              if (this.tempData.expenditureId) {
                this.dialogData.width = "700px";
                this.dialogData.buttonArray.push(
                  {
                    text: "确定",
                    active: "sure_readdExpend",
                  },
                  {
                    text: "取消",
                    active: "cancel",
                  }
                );
                this.$request(
                  "get",
                  "/contractExpenditure/getInfoById?id=" +
                    this.tempData.expenditureId,
                  requestData,
                  (data2) => {
                    this.dialogData.data = [
                      {
                        type: "contract_expend",
                        data: allContractData.contractExpend(
                          this,
                          this.searchData.payOptions,
                          "",
                          data2
                        ),
                      },
                    ];
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              } else {
                this.dialogData.buttonArray = [
                  {
                    text: "确定",
                    active: "makesure_reSubmitReimbursement",
                    key: "cancel",
                  },
                  {
                    text: "取消",
                    active: "cancel",
                    key: "cancel",
                  },
                ];
                this.dialogData.data = [
                  {
                    type: "add-reimbursemen",
                    title: "",
                    isEditFlag: true,
                    value: data,
                  },
                ];
              }

              this.dialogData.flag = true;
              break;
            case "revocation":
              // 撤销
              this.$confirm("确认撤销本次报销信息?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$request(
                    "post",
                    "/reimburse/cancelReimburse",
                    requestData,
                    (dd) => {
                      this.$message({
                        type: "success",
                        message: "撤销成功!",
                      });
                      this.initTableData();
                    }
                  );
                })
                .catch(() => {});
              break;
            case "delete":
              // 删除
              this.$confirm("确认删除本次报销信息?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$request(
                    "post",
                    "/reimburse/delReimburse",
                    requestData,
                    (data) => {
                      this.$message({
                        type: "success",
                        message: "删除成功!",
                      });
                      this.initTableData();
                    }
                  );
                })
                .catch(() => {});
              break;
            default:
              break;
          }
        },
        (errorMessage) => {
          this.loading = false;
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加/导入/导出报销
     */
    importReimbursement(active) {
      switch (active) {
        case 0:
          // 新建报销
          this.dialogData.title = "新建报销";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_addReimbursement",
              key: "cancel",
            },
            {
              text: "取消",
              active: "cancel",
              key: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "add-reimbursemen",
              title: "",
              isEditFlag: true,
              value: {
                type: "", // 报销类型
                amountAll: "", // 总金额
                modeName: "报销流程",
                submitUser: "",
                des: "",
                detail: [
                  {
                    payWay: "", // 付款方式
                    amount: "", // 明细金额（元）
                    occurTime: "", // 发生时间
                    remark: "", // 报销说明
                    appendix: [], // 附件
                  },
                ],
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 导入报销
          console.log("导入报销");
          this.dialogData.title = "导入报销";
          this.dialogData.data = reimbursementJsData.improtReimbursementDialogData(
            this
          );
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_improt_reimbursement",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          // 导出报销
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择想要导出的报销!");
            return;
          }
          let requestData = "";
          this.multipleSelection.forEach((element) => {
            if (requestData == "") {
              requestData = element.id;
            } else {
              requestData = requestData + "," + element.id;
            }
          });
          console.log("传入参数:", requestData);
          window.location.href =
            this.$baseurl +
            "/reimburse/exportReimburse?reimburseId=" +
            requestData;
          break;
        case 3:
          // 付款
          this.dialogData.width = "700px";
          this.dialogData.title = "新建支出";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "sure_addExpend",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "contract_expend",
              data: allContractData.contractExpend(
                this,
                this.searchData.payOptions,
                "",
                "",
                "reimbursement"
              ),
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 弹出框事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "makesure_addReimbursement":
          // 确定添加报销
          try {
            requestData = this.formatReimbursementItem(
              this.dialogData.data[0].value
            );
          } catch (error) {
            return this.$message.error(error);
          }
          this.$request(
            "post",
            "/reimburse/createReimburse",
            requestData,
            (data) => {
              this.$message({
                message: "报销添加成功!",
                type: "success",
              });
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.loading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makesure_reSubmitReimbursement":
          // 确定重新提交报销
          requestData = this.formatReimbursementItem(
            this.dialogData.data[0].value
          );
          this.$set(requestData, "oldId", this.tempReimbursement.id);
          console.log("重新提交", requestData);
          this.$request(
            "post",
            "/reimburse/resubmitReimburse",
            requestData,
            (data) => {
              this.$message({
                message: "报销添加成功!",
                type: "success",
              });
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.loading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makesure_improt_reimbursement":
          // 确定导入报销
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            url: this.dialogData.data[0].value.url,
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          this.$request(
            "post",
            "/reimburse/importReimburse",
            requestData,
            (data) => {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.dialogData.flag = false;
              this.initTableData();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.initTableData();
            }
          );
          break;
        case "sure_addExpend":
          // 确认添加支出
          requestData = {
            companyId: this.baseInfo.company.companyId,
            createUserId: this.baseInfo.userId, // 创建人 id
            reasons: this.dialogData.data[0].data.reasons, // 付款事由
            receiptNumber: this.dialogData.data[0].data.receiptNumber, //  收款单号
            paymentAmount: this.dialogData.data[0].data.paymentAmount, //  付款金额
            paymentMethod: this.dialogData.data[0].data.paymentMethod, //  付款方式
            paymentDate: this.dialogData.data[0].data.paymentDate, // 付款日期
            payeeName: this.dialogData.data[0].data.payeeName, // 收款人全称
            bankAccount: this.dialogData.data[0].data.bankAccount, // 银行账户
            bankOfDeposit: this.dialogData.data[0].data.bankOfDeposit, // 开户行
            remark: this.dialogData.data[0].data.remark, // 备注说明
            attachments: this.dialogData.data[0].data.attachments.toString(), // 附件
            payer: this.dialogData.data[0].data.payer, // 付款单位
          };
          console.log("requestData", requestData);
          if (!requestData.paymentAmount) {
            this.$message.error("付款金额必填!");
            return;
          }
          if (!requestData.paymentMethod) {
            this.$message.error("付款方式必填!");
            return;
          }
          if (!requestData.reasons) {
            this.$message.error("付款理由必填!");
            return;
          }
          if (!requestData.paymentDate) {
            this.$message.error("付款日期必填!");
            return;
          }
          if (!requestData.payeeName) {
            this.$message.error("收款人全称必填!");
            return;
          }
          if (!requestData.bankAccount) {
            this.$message.error("银行账户必填!");
            return;
          }
          if (!requestData.bankOfDeposit) {
            this.$message.error("开户行必填!");
            return;
          }
          if (!requestData.payer) {
            this.$message.error("付款单位必填!");
            return;
          }
          this.$request(
            "post",
            "/contractExpenditure/addExpenditure",
            requestData,
            (data) => {
              this.$message({
                message: "支出添加成功!",
                type: "success",
                duration: 1000,
              });
              // 添加常用信息
              let requestData2 = {
                userId: this.baseInfo.userId,
                payeeName: this.dialogData.data[0].data.payeeName, // 收款人全称
                bankAccount: this.dialogData.data[0].data.bankAccount, // 银行账户
                bankOfDeposit: this.dialogData.data[0].data.bankOfDeposit, // 开户行
              };
              this.$request(
                "post",
                "/contractCommonExpenses/add",
                requestData2,
                (data) => {
                  this.dialogData.flag = false;
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
              // this.$operationRecords.saveOperationRecords(
              //   this.tempContract.id,
              //   "新建支出!",
              //   this.baseInfo,
              //   "contract",
              //   this,
              //   (data2) => {
              //     this.getExpendByContractId();
              //   }
              // );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_readdExpend":
          // 重新提交支出
          let requestData = {
            companyId: this.baseInfo.company.companyId,
            createUserId: this.baseInfo.userId, // 创建人 id
            reasons: this.dialogData.data[0].data.reasons, // 付款事由
            receiptNumber: this.dialogData.data[0].data.receiptNumber, // 收款单号
            paymentAmount: this.dialogData.data[0].data.paymentAmount, // 付款金额
            paymentMethod: this.dialogData.data[0].data.paymentMethod, // 付款方式
            paymentDate: this.dialogData.data[0].data.paymentDate, // 付款日期
            payeeName: this.dialogData.data[0].data.payeeName, // 收款人全称
            bankAccount: this.dialogData.data[0].data.bankAccount, // 银行账户
            bankOfDeposit: this.dialogData.data[0].data.bankOfDeposit, // 开户行
            remark: this.dialogData.data[0].data.remark, // 备注说明
            attachments: this.dialogData.data[0].data.attachments.toString(), // 附件
            payer: this.dialogData.data[0].data.payer, // 付款单位
            revokeReimburseId: this.tempData.id, // 撤销id
          };
          console.log("requestData", requestData);
          if (!requestData.paymentAmount) {
            this.$message.error("付款金额必填!");
            return;
          }
          if (!requestData.paymentMethod) {
            this.$message.error("付款方式必填!");
            return;
          }
          if (!requestData.reasons) {
            this.$message.error("付款理由必填!");
            return;
          }
          if (!requestData.paymentDate) {
            this.$message.error("付款日期必填!");
            return;
          }
          if (!requestData.payeeName) {
            this.$message.error("收款人全称必填!");
            return;
          }
          if (!requestData.bankAccount) {
            this.$message.error("银行账户必填!");
            return;
          }
          if (!requestData.bankOfDeposit) {
            this.$message.error("开户行必填!");
            return;
          }
          if (!requestData.payer) {
            this.$message.error("付款单位必填!");
            return;
          }
          this.$request(
            "post",
            "/contractExpenditure/addExpenditure",
            requestData,
            (data) => {
              this.$message({
                message: "重新提交成功!",
                type: "success",
                duration: 1000,
              });

              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 格式化报销数据
     */
    formatReimbursementItem(value) {
      if (value.type == "") throw new Error("报销类型不可为空!");
      let amountAll = 0;
      for (let index = 0; index < value.detail.length; index++) {
        const element = value.detail[index];
        if (element.amount == "") throw new Error("明细金额不可为空");
        if (element.occurTime == "") throw new Error("发生时间不可为空!");
        amountAll += Number.parseFloat(element.amount);
      }
      value.amountAll = amountAll;
      value.submitUser = this.baseInfo.userId;
      this.$set(value, "modeName", "报销流程");
      this.$set(value, "companyId", this.baseInfo.company.companyId);
      return JSON.parse(JSON.stringify(value));
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 附件上传成功
     */
    handleAvatarSuccess(data) {
      if (data.result.title == "客户资质") {
        this.$message({
          message: "付款凭证上传成功!",
          type: "success",
          duration: 1000,
        });
        this.dialogData.data[4].value.value = data.result;
      }
      if (data.result.title == "新建支出") {
        // 新建支出
        this.dialogData.data[0].data.attachments.push(data.result.path);
      }
    },
    deleteItem(index) {
      this.dialogData.data[0].data.attachments.splice(index, 1);
      console.log("deleteItem", this.dialogData.data[0].data.attachments);
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 2) {
          sums[index] = "合计";
          return;
        }
        if (index !== 7) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    // /**
    //  * 查看详情
    //  */
    // expendDetails(expenditureId) {
    //   this.$request(
    //     "get",
    //     "/contractExpenditure/getInfoById?id=" + expenditureId,
    //     {},
    //     (data) => {
    //       data.attachments = data.attachments.split(",");
    //       data.reimburse = {
    //         status: this.tempData.status,
    //         color: this.tempData.color,
    //       };

    //       this.dialogData.title = "支出";
    //       this.dialogData.width = "40%";
    //       this.dialogData.buttonArray = [];
    //       this.dialogData.buttonArray.push({
    //         text: "取消",
    //         active: "cancel",
    //       });
    //       this.dialogData.data = [
    //         {
    //           type: "expend_details",
    //           title: "",
    //           value: data,
    //         },
    //       ];
    //       this.dialogData.flag = true;
    //     },
    //     (errorMessage) => {
    //       this.$message.error(errorMessage);
    //     }
    //   );
    // },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.singleTable.doLayout();
    });
  },
  mounted() {
    this.loading = false;
    this.getTagList();
    this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#my-reimbursement {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .my-reimbursement-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .my-reimbursement-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .my-reimbursement-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .my-reimbursement-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .my-reimbursement-screen-search {
        width: 235px;
        min-width: 235px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          margin-left: 8px;
          // margin-right: 15px;
        }
        > .in {
          width: 0;
          flex: 1;
          height: 100%;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 3px;
          box-sizing: border-box;
        }
      }
      .my-reimbursement-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .my-reimbursement-screen-active {
        flex: 1;
      }
      .my-reimbursement-screen-but-select {
        width: 80px;
        min-width: 80px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .my-reimbursement-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .my-reimbursement-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .my-reimbursement-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .my-reimbursement-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .my-reimbursement-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .my-reimbursement-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .my-reimbursement-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .my-reimbursement-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .my-reimbursement-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .my-reimbursement-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .my-reimbursement-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .my-reimbursement-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .my-reimbursement-table-card-active {
          flex: 1;
        }
        .my-reimbursement-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
        .all-amount {
          width: auto;
          height: 100%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          > span {
            color: #fc8a2e;
            margin: 0 3px;
          }
        }
      }
      .my-reimbursement-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .my-reimbursement-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      cursor: pointer;
      // color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
