<template>
  <div id="payroll-detail">
    <div class="payroll-detail-list">
      <div class="payroll-detail-list-item">
        <div class="line">
          <div class="title">姓名:</div>
          <div class="value">
            <el-select v-model="value.name" placeholder="请选择" filterable>
              <el-option
                v-for="item in value.employeeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="payroll-detail-list-item">
        <div class="line">
          <div class="title">月份:</div>
          <div class="value">
            <el-date-picker
              v-model="value.date"
              type="month"
              placeholder="选择月"
              value-format="yyyy-MM"
              :clearable="false"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div
        class="payroll-detail-list-item"
        v-for="item in value.rowData"
        :key="item.name"
      >
        <div
          class="line"
          v-if="
            item.name !== '实发工资' &&
              item.name !== '扣除总计' &&
              item.name !== '累计工资收入'
          "
        >
          <div class="title">{{ item.name }}:</div>
          <div class="value">
            <el-input v-model="item.data"></el-input>
          </div>
        </div>
        <div class="line" style="align-items: start;">
          <div
            class="title"
            v-if="
              item.name == '实发工资' ||
                item.name == '扣除总计' ||
                item.name == '累计工资收入'
            "
          >
            {{ item.name }}备注:
          </div>
          <div class="title" v-else-if="item.name !== '所在公司'">备注:</div>
          <div class="value" v-if="item.name !== '所在公司'">
            <el-input type="textarea" v-model="item.remark"></el-input>
          </div>
        </div>
      </div>
      <div class="payroll-detail-list-item">
        <div class="line">
          <div class="title">备注:</div>
          <div class="value">
            <el-input type="textarea" v-model="value.remark"></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payrollDetail: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      value: [],
    };
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
  },
  created() {
    this.value = this.payrollDetail;
    console.log("this.payrollDetail", this.payrollDetail);
  },
};
</script>

<style lang="less" scoped>
#payroll-detail {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  /deep/ .el-input__inner {
    width: 175px;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  .payroll-detail-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .payroll-detail-list-item {
      font-size: 15px;
      width: 48%;
      margin-bottom: 20px;
      .line {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .title {
          min-width: 90px;
          text-align: right;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
