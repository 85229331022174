/**
 * 获取界面筛选数据
 * 
 */

export function taskInfoSearchData( tipsData, productList, clientCondition) {
    if (tipsData == "") {
        tipsData = {
            clientTipsList: [],
            clientLevelList: [],
        }
    }
    let data = [{
            title: "服务产品",
            type: "cascader",
            hasSearch: false,
            flag: "0",
            key: "servicesTypeOrItem",
            value: [],
            // value: ["1b7e460bcd904d098d88d4915f56e209", "34a3886be0864f8788a39e778dc5a164"],
            options: productList,
        }, 
        {
            title: "增值税类型",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "addedValueTaxType",
            value: [
                { text: "一般纳税人", is_active: false, id: "" },
                { text: "小规模纳税人", is_active: false, id: "" }
            ],
        },
        {
            title: "是否是开票客户",
            type: "radio",
            flag: "0",
            hasSearch: false,
            key: "isInvoicing",
            value: "",
        },
        {
            title: "是否包含社保",
            type: "radio",
            flag: "0",
            hasSearch: false,
            key: "isSocialSecurity",
            value: "",
        },
        {
            title: "是否包含流水",
            type: "radio",
            flag: "0",
            hasSearch: false,
            key: "isStatement",
            value: "",
        },
        {
            title: "客户标签",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientTips",
            search_value: "",
            value: [],
            options: tipsData.clientTipsList,
        },       
        {
            title: "客户等级",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientLevel",
            search_value: "",
            value: [],
            options: tipsData.clientLevelList,
        },
    ]
    data.forEach(item => {
        clientCondition.forEach(it => {
            if(item.key == it.key) {
                if(item.addedValueTaxType == 'addedValueTaxType') {
                    it.value.forEach(value => {
                        let index = item.value.findIndex(find => find.text === value.text);
                        item.value[index].is_active = value.is_active
                    })
                }else {

                    item.value = it.value
                }
            }
        })
    })
    return data
}

  