 function validateData(data) {
    let flag = false
    for (let key in data) {
        if (data[key] === null || data[key] === undefined ||
            (typeof data[key] === 'string' && data[key].trim() === '') ||
            (Array.isArray(data[key]) && data[key].length === 0) ||
            (typeof data[key] === 'object' && Object.keys(data[key]).length === 0)) {
                console.log('字段为空或无效', key)
                // 字段为空或无效
                flag = true
        } else {
            // 字段有效
        }
    }
    return flag
}
export default validateData