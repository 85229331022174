<template>
  <div id="contract-payment">
    <!-- 财务收款单 -->
    <div class="contract-payment-screen">
      <div class="contract-payment-screen-search">
        <el-input
          placeholder="请输入交易单号"
          v-model="searchData.input"
          @keyup.enter.native="resetPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetPage"
          ></el-button>
        </el-input>
      </div>
      <div class="contract-payment-screen-search">
        <el-input
          placeholder="请输入付款人"
          v-model="searchData.operationOf"
          @keyup.enter.native="resetPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetPage"
          ></el-button>
        </el-input>
      </div>
      <div class="contract-payment-screen-search">
        <el-select
          v-model="searchData.userId"
          placeholder="请选择创建人"
          filterable
          @change="selectInitTableData"
        >
          <el-option
            v-for="item in searchData.userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="contract-payment-screen-search">
        <el-date-picker
          v-model="searchData.month"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
          :clearable="false"
          @change="resetPage"
        >
        </el-date-picker>
      </div>
      <div class="contract-payment-screen-checked">
        <el-checkbox v-model="searchData.checked" @change="resetPage"
          >包含已领取收款单</el-checkbox
        >
      </div>
      <div class="contract-payment-screen-checked">
        <el-checkbox v-model="searchData.refund" @change="resetPage"
          >有退款</el-checkbox
        >
      </div>
      <div class="contract-payment-screen-active"></div>
      <el-upload
        class="avatar-uploader"
        :action="action"
        :show-file-list="false"
        :headers="headers"
        :data="uploadParameter"
        :on-success="handleSuccess"
        :before-upload="beforeAvatarUpload"
        v-showFunction="{ active: 'import', array: allFunctionButtons }"
      >
        <div class="attachment-content-item-img-upload">
          <div
            class="contract-payment-screen-but-boder"
            @click="handleClick({}, 1)"
          >
            导入
          </div>
        </div>
      </el-upload>
      <div style="background-color: #ECF5FF;">
        <el-dropdown>
          <el-button type="small" class="drop-button">
            操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item
              @click.native="handleClick({}, 2)"
              v-showFunction="{
                active: 'export',
                array: allFunctionButtons,
              }"
              >导出</el-dropdown-item
            > -->
            <el-dropdown-item
              @click.native="handleClick({}, 5)"
              v-showFunction="{
                active: 'synchronous',
                array: allFunctionButtons,
              }"
              >企业微信同步</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="handleClick({}, 0)"
              v-showFunction="{
                active: 'add',
                array: allFunctionButtons,
              }"
              >添加收款单</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="contract-payment-table">
      <div class="contract-payment-table-card">
        <div class="contract-payment-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column
              prop="voucherNo"
              label="交易单号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="money" label="收款总金额"> </el-table-column>
            <el-table-column prop="remainingAmount" label="剩余金额">
            </el-table-column>
            <el-table-column prop="haveReceivedAmount" label="已领金额">
            </el-table-column>
            <el-table-column prop="refundMoney" label="退款金额">
            </el-table-column>
            <el-table-column
              prop="paymentTime"
              label="收款时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="operationOf" label="付款人">
            </el-table-column>
            <el-table-column
              prop="paymentAccount"
              label="收款账号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="creater" label="创建人"> </el-table-column>
            <el-table-column prop="des" label="备注" show-overflow-tooltip>
            </el-table-column>

            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 3)"
                  type="text"
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 4)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  type="text"
                  size="small"
                  >删除</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 6)"
                  type="text"
                  v-showFunction="{
                    active: 'refund',
                    array: allFunctionButtons,
                  }"
                  size="small"
                  >退款</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-payment-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @handleAvatarSuccess="handleAvatarSuccess"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as serveItemData from "@/assets/js/serveItemData.js";
import * as allClientData from "../client/js/clientData.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: false,
      whetherAgencyHead: false,
      searchData: {
        input: "", // 交易单号
        operationOf: "", // 付款人
        month: [
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime(),
            "YYYY-MM"
          ),
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime(),
            "YYYY-MM"
          ),
        ],
        checked: false,
        options: [
          { label: "全部产品类型", value: "全部产品类型" },
          { label: "周期服务", value: "周期服务" },
          { label: "单次服务", value: "单次服务" },
        ],
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        screenButton_export: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        userIdList: [],
        userList: [],
        tempUserId: [],
        refund: false,
      },
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
      uploadParameter: {
        systemName: "finance",
        title: "收款单附件",
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableData: [],
      tempData: {}, // 临时数据
      dialogData: {
        flag: false,
        width: "30%",
        title: "收款数据添加",
        id: "",
        price: "", // 临时存储价格
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      multipleSelection: [],
      accountList: [],
      buttonAuth: {
        add: 3,
        edit: 3,
        oper: 3,
        dele: 3,
        refund: 3,
        synchronous: 3,
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "ContractPayment"
      ),
      employeeList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "ContractPayment",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeList();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.employeeList.push(dd);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        hasRemainingAmount: this.searchData.checked, // 是否包含金额全部领取的,即是否包含总金额 == 已领金额
        refund: this.searchData.refund, // 有退款
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        month: this.searchData.month,
        search: this.searchData.input, // 交易单号
        operationOf: this.searchData.operationOf, // 付款人
        createIds: this.searchData.tempUserId, // 员工id
      };

      this.$request(
        "post",
        "/voucher/getAllVoucherList",
        requestData,
        (data) => {
          this.pageData.total = parseInt(data.total); // 获取总条目数
          this.tableData = data.tableData;
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 导入,即上传文件到服务器
     */
    handleAvatarSuccess(response, file, fileList) {
      console.log("收款单地址:", response.result);
      this.loading = true;
      let requestData = {
        path: response.result,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/voucher/importReceivedInfo",
        requestData,
        (data) => {
          this.$message({
            message: "导入成功!",
            type: "success",
            duration: 1000,
          });
          this.loading = false;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 上传收款单前的判断
     */
    beforeAvatarUpload(file) {
      console.log("file:", file.type);
      let fileUrlList = file.name.split(".");
      let imgType = ["xls", "xlsx"];
      if (imgType.indexOf(fileUrlList[fileUrlList.length - 1]) >= 0) {
        return true;
      } else {
        this.$message.error("上传文件只能是 Excel 格式!");
        return false;
      }
    },
    /**
     * 操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      switch (flag) {
        case 0:
          // 添加收款单
          this.dialogData.title = "添加收款单";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "交易单号",
              value: "",
            },
            {
              type: "time",
              title: "收款时间",
              value: "",
            },
            {
              type: "input",
              title: "收款金额",
              value: "",
              placeholder: "0.00",
            },
            {
              type: "select",
              title: "收款账号",
              value: "",
              options: this.accountList,
            },
            {
              type: "input",
              title: "付款人",
              value: "",
            },
            {
              type: "input",
              title: "备注",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 导入
          break;

        case 2:
          // 导出

          break;
        case 3:
          // 编辑收款单
          if (parseFloat(data.haveReceivedAmount) > 0) {
            this.$message({
              message: "该交易单号已进行收款,不可修改!",
              type: "error",
              duration: 1000,
            });
            return;
          } else {
            this.dialogData.title = "编辑收款单";
            this.dialogData.buttonArray = [
              {
                text: "确定",
                active: "submit_edit",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                type: "input",
                title: "交易单号",
                value: data.voucherNo,
              },
              {
                type: "time",
                title: "收款时间",
                value: data.paymentTime,
              },
              {
                type: "input",
                title: "收款金额",
                value: data.money,
                placeholder: "0.00",
              },
              {
                type: "select",
                title: "收款账号",
                value: data.paymentAccount,
                options: this.accountList,
              },
              {
                type: "input",
                title: "付款人",
                value: data.operationOf,
              },
              {
                type: "input",
                title: "备注",
                value: data.des,
              },
            ];
            this.dialogData.flag = true;
          }
          console.log("paymentAccount", data.paymentAccount);
          break;
        case 4:
          // 删除交易单号
          if (parseFloat(data.haveReceivedAmount) > 0) {
            this.$message({
              message: "该交易单号已进行收款,不可删除!",
              type: "error",
              duration: 1000,
            });
            return;
          } else {
            this.$confirm("此操作将永久删除该交易单号, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/voucher/deleteVoucher",
                  { id: data.id },
                  (data) => {
                    this.$message({
                      message: "删除成功!",
                      type: "success",
                      duration: 1000,
                    });
                    this.initTableData();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                    this.loading = false;
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          }
          break;
        case 5:
          // 企业微信同步
          this.dialogData.title = "企业微信同步收款记录";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_synchronous",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "times",
              title: "同步时间段",
              value: [],
              pickerOptions: {
                shortcuts: [
                  {
                    text: "今天",
                    onClick(picker) {
                      let end = new Date();
                      let start = new Date(
                        new Date(new Date().toLocaleDateString()).getTime()
                      );
                      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                      picker.$emit("pick", [start, end]);
                    },
                  },
                  {
                    text: "昨天",
                    onClick(picker) {
                      let end = new Date();
                      let start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24);
                      picker.$emit("pick", [start, end]);
                    },
                  },
                  {
                    text: "最近一周",
                    onClick(picker) {
                      let end = new Date();
                      let start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                      picker.$emit("pick", [start, end]);
                    },
                  },
                ],
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 6:
          // 退款
          this.dialogData.width = "30%";
          this.dialogData.title = "退款";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "sure_refund",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });

          this.dialogData.data = [
            {
              title: "最大退款金额",
              type: "input",
              value: this.tempData.remainingAmount,
              flag: true,
            },
            {
              title: "退款金额",
              type: "input-number",
              value: "",
            },
            {
              title: "微信退款",
              type: "radio",
              value: 0,
              options: [
                {
                  label: "是",
                  value: 1,
                },
                {
                  label: "否",
                  value: 0,
                },
              ],
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog 操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        case "submit_add":
          //   确定添加收款单
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            voucherNo: this.dialogData.data[0].value, //交易单号
            paymentTime: this.dialogData.data[1].value, //收款时间
            money: this.dialogData.data[2].value, // 收款总金额
            paymentAccount: this.dialogData.data[3].value, // 收款账号
            operationOf: this.dialogData.data[4].value, // 操作人
            des: this.dialogData.data[5].value, // 备注
          };
          if (requestData.voucherNo == "") {
            this.$message({
              message: "交易单号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (
            requestData.money == "" ||
            !this.$formatMathDecimal(requestData.money)
          ) {
            this.$message({
              message: "收款金额为空或金额格式不对,请校验后重新输入!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.paymentAccount == "") {
            this.$message({
              message: "收款账号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          this.$request(
            "post",
            "/voucher/addVoucher",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_edit":
          //   确定变价收款单
          requestData = {
            id: this.tempData.id,
            voucherNo: this.dialogData.data[0].value, //交易单号
            paymentTime: this.dialogData.data[1].value, //收款时间
            money: this.dialogData.data[2].value, // 收款总金额
            paymentAccount: this.dialogData.data[3].value, // 收款账号
            operationOf: this.dialogData.data[4].value, // 操作人
            des: this.dialogData.data[5].value, // 操作人
          };
          if (requestData.voucherNo == "") {
            this.$message({
              message: "交易单号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (
            requestData.money == "" &&
            !this.$formatMathDecimal(requestData.money)
          ) {
            this.$message({
              message: "收款金额为空或金额格式不对,请校验后重新输入!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.paymentAccount == "") {
            this.$message({
              message: "收款账号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          this.$request(
            "post",
            "/voucher/editVoucher",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_synchronous":
          // 确定同步企业微信
          requestData = {
            companyId: this.baseInfo.company.companyId,
            beginTime: this.dialogData.data[0].value[0],
            endTime: this.dialogData.data[0].value[1],
          };
          this.$request(
            "post",
            "/voucher/synchronousReceivedInfo",
            requestData,
            (data) => {
              this.$message({
                message: "收款记录同步成功!",
                type: "success",
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          this.dialogData.flag = false;
          break;
        case "sure_refund":
          // 确认退款
          if (
            parseFloat(this.tempData.remainingAmount) <
            parseFloat(this.dialogData.data[1].value)
          ) {
            this.$message.error("退款金额不可超过最大退款金额!");
            return;
          }
          requestData = {
            receivedId: this.tempData.id, // 财务收款单
            transactionId: this.tempData.voucherNo, // 微信支付订单号
            refund: this.dialogData.data[1].value, // 退款金额
            total: this.tempData.money, // 原订单金额
          };
          if (this.dialogData.data[2].value) {
            // 微信退款
            this.$request(
              "post",
              "/wxPay/refund",
              requestData,
              (res) => {
                this.$message({
                  message: "退款成功!",
                  type: "success",
                  duration: 1000,
                });
                this.dialogData.flag = false;
                this.$operationRecords.saveOperationRecords(
                  this.tempData.id,
                  "退款记录,收款单号:" + this.tempData.voucherNo + "!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          } else {
            // 本地退款
            this.$request(
              "post",
              "/voucher/refundVoucher",
              requestData,
              (res) => {
                this.$message({
                  message: "退款成功!",
                  type: "success",
                  duration: 1000,
                });
                this.dialogData.flag = false;
                this.$operationRecords.saveOperationRecords(
                  this.tempData.id,
                  "退款记录,收款单号:" + this.tempData.voucherNo + "!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          }

          break;

        default:
          break;
      }
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    handleSuccess(data) {
      this.dialogData.data[3].value.value = data.result.path;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.$store.dispatch("getCompantAccountList", this);
    let accountList = JSON.parse(
      JSON.stringify(this.$store.getters.getCompantAccountList)
    );
    this.accountList = [];
    accountList.forEach((element) => {
      let accountList1 = "";
      element.accountList.forEach((account) => {
        let dd = {
          label: account.account + "-" + element.owner,
          value: account.account,
        };
        this.accountList.push(dd);
      });
    });

    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#contract-payment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contract-payment-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .contract-payment-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin: 0 10px;
    }
    .contract-payment-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .contract-payment-screen-active {
      flex: 1;
      height: 100%;
    }
    .drop-button {
      background-color: #ecf5ff;
      transition: 0.5s;
      margin-right: 10px;
      font-size: 13px;
      color: #409eff;
      border: 1px solid #40a3ff;

      &:hover {
        background-color: #abbed3;
      }
    }
    .contract-payment-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .contract-payment-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .contract-payment-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .contract-payment-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .contract-payment-screen-but-boder {
      width: auto;
      min-width: 40px;
      height: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .contract-payment-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .contract-payment-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-payment-table-card {
      width: 98%;
      height: 95%;
      background-color: #fff;
      max-width: 90vw;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .contract-payment-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
        .table-tip {
          height: 30px;
          width: auto;
          padding: 2px 5px;
          margin: 0 3px;
          border: 1px solid #333;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
      .contract-payment-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
