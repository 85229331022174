/**
 * 物品设置菜单栏
 */
export function getMenuData() {
    let MenuData = [
        {
            name: '知识库分类设置'
        },
        {
            name: '企学院分类设置'
        }
    ]
    return MenuData 
}

