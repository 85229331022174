<template>
  <div id="dialog_transaction">
    <el-row>
      <el-col :span="12">
        <div class="line">
          <div class="title">
            客户名称:
          </div>
          <el-input v-model="data.name" disabled> </el-input>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="line">
          <div class="title">
            回款金额:
          </div>
          <el-input placeholder="" v-model="info.money"> </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="line">
          <div class="title">
            收款账户:
          </div>
          <el-select v-model="info.account" filterable>
            <el-option
              v-for="it in data.accountList"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col class="line3" :span="12">
        <div class="line">
          <div class="title">
            交易单号:
          </div>
          <el-input
            class="input"
            v-model="info.receivedId"
            placeholder="请输入交易单号或对公付款的公司名称"
          ></el-input>

          <el-button
            class="dialog-content-line-value-but"
            @click="searchTransaction"
            >搜索</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="line" style=" height: 64px">
          <div class="title" style="width: 10%">
            付款凭证:
          </div>
          <div class="upload-img" v-viewer="{ movable: false }">
            <img
              v-if="info.file"
              :src="info.file"
              alt=""
              style="width: 50px; height: 60px"
            />
          </div>

          <el-upload
            class="avatar-uploader"
            :action="upload.value.action"
            :show-file-list="false"
            :headers="upload.value.headers"
            :data="upload.value.uploadParameter"
            :on-success="handleAvatarSuccess"
            :disabled="upload.flag"
          >
            <el-button size="small" type="primary" style="height: 30px"
              >点击上传</el-button
            >
          </el-upload>
        </div>
      </el-col>
    </el-row>
    <el-divider class="divider"></el-divider>
    <!-- 表格 -->
    <el-table :data="tableInfo.tableData" style="width: 100%" height="250">
      <template slot="empty">
        {{ tableInfo.text == "" ? "" : tableInfo.text }}
      </template>
      <el-table-column prop="paymentTime" label="收款时间" width="150">
      </el-table-column>
      <el-table-column width="160" show-overflow-tooltip>
        <template slot="header">
          <span>交易单号</span>
          <i
            class="el-icon-refresh-right"
            style="color: #5CB6FF; cursor: pointer; margin-left: 3px;"
            @click="refresh"
          ></i>
        </template>
        <template slot-scope="scope">
          {{ scope.row.voucherNo }}
        </template>
      </el-table-column>
      <el-table-column
        prop="creater"
        label="创建人"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="money" label="收款总金额" width="100">
      </el-table-column>
      <el-table-column prop="haveReceivedAmount" label="已领金额" width="100">
      </el-table-column>
      <el-table-column prop="remainingAmount" label="剩余金额" width="100">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click.native.prevent="receive(scope.row)"
          >
            领取
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      id="transaction_dialog"
      title="确认回款金额"
      :visible.sync="flag"
      :append-to-body="true"
      width="23%"
      top="35vh"
    >
      <div class="dialog-line">
        <div class="dialog-title">本次可领金额:</div>
        <div class="dialog-value">{{ remainingAmount }}</div>
      </div>
      <div class="dialog-line">
        <div class="dialog-title">本次领取金额:</div>
        <div class="dialog-value">
          <el-input v-model="info.money"></el-input>
        </div>
      </div>
      <div class="dialog-line last">
        <el-button type="primary" size="small" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    transactionData: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      flag: false,
      loading: false,
      data: this.transactionData,
      baseInfo: {},
      remainingAmount: "", // 本次可领金额
      voucherNo: "", // 确认回款交易单号
      info: {
        id: "", // 收款id
        money: "", // 回款金额
        account: "", // 收款账户
        receivedId: "", // 交易单号
        file: "", // 回款单附件
      },
      tableInfo: {
        tableData: [],
        text: "",
      },
      // 付款凭证信息
      upload: {
        value: {
          flag: false,
          action: this.$fileBaseurl + "/uploadImageByMultipartFile",
          headers: {
            token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          uploadParameter: {
            systemName: "finance",
            title: "付款凭证附件",
          },
        },
      },
      handlePreview(file) {
        // axios的基地址和上传文件路径同时存在时可预览
        if (this.$axios.defaults.baseURL && this.certificatePath) {
          window.open(
            this.$axios.defaults.baseURL + this.certificatePath,
            "_blank"
          );
        } else {
          this.$message.warning("暂不支持预览");
        }
      },
    };
  },
  created() {},
  methods: {
    /**
     * 获取表格信息
     */
    initTableData() {
      let requestData = {
        companyId: this.data.id,
        voucherNo: this.info.receivedId,
      };
      this.$request(
        "post",
        "/contract/getReceivedBeforeCharge",
        requestData,
        (data) => {
          console.log(data);
          if (data.text == "ok") {
            this.tableInfo.tableData = data.tableData;
          } else {
            this.tableInfo.text = data.text;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 点击搜索交易单号
     */
    searchTransaction() {
      this.initTableData();
    },
    receive(row) {
      if (this.info.account == "") {
        this.$message({
          message: "收款账户不能为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (this.info.money == "") {
        this.$message({
          message: "回款金额不能为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (this.info.receivedId == "") {
        this.$message({
          message: "交易单号不能为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.voucherNo = row.voucherNo;
      this.remainingAmount = row.remainingAmount;
      this.info.id = row.id;
      this.flag = true;
    },
    /**
     * 附件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      this.$message({
        message: "付款凭证上传成功!",
        type: "success",
        duration: 1000,
      });
      this.info.file = response.result.path;
    },

    /**
     * 刷新交易单号
     */
    refresh() {
      // 刷新收款单号
      let requestData = {};
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      requestData = {
        companyId: this.baseInfo.company.companyId,
        beginTime: this.$formatTime(start, "YYYY-MM-DD HH:mm:ss"),
        endTime: this.$formatTime(end, "YYYY-MM-DD HH:mm:ss"),
      };

      this.$request(
        "post",
        "/voucher/synchronousReceivedInfo",
        requestData,
        (data) => {
          this.$message({
            message: "收款记录同步成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 确认回款
     */
    confirm() {
      let data = {
        money: this.info.money,
        service: this.data.options[0].value,
        account: this.info.account,
        receivedId: {
          id: this.info.id,
          voucherNo: this.voucherNo,
        },
        file: this.info.file,
      };
      this.$emit("confirm", data);
    },
  },

  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#dialog_transaction {
  width: 100%;

  height: auto;
  .el-row {
    display: flex;
    margin-bottom: 20px;
    min-height: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .line {
      display: flex;
      align-items: center;
      .upload-img {
        margin: 0 2px;
      }
      .title {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        margin-right: 5px;
        min-height: 30px;
      }
      .input {
        width: 18.2vw;
      }
      .dialog-content-line-value-but {
        width: auto;
        min-width: 70px;
        height: 30px;
        padding: 0 5px;
        margin-left: 5px;
        box-sizing: border-box;
        border: 1px solid @activeColor;
        color: @activeColor;
        font-size: 12px;
        border-radius: 3px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          background-color: @activeColor;
          color: #fff;
        }
      }
    }
  }
  .divider {
    margin-top: 0;
  }
}
#transaction_dialog {
  .dialog-line {
    display: flex;
    align-items: center;
    min-height: 25px;
    .dialog-title {
      margin-right: 2px;
    }
  }
  .last {
    display: flex;
    justify-content: end;
  }
}
</style>
