<template>
  <!-- 考试试题 添加 编辑 -->
  <div class="examination-add-content">
    <examinationAdd></examinationAdd>
    <div class="examination-add-content-box">
      <div class="examination-add-content-line">
        <div class="examination-add-content-line-item">
          <div class="title">题型:</div>
          <div class="value">
            <el-select
              v-model="formData.type"
              @change="changeType"
              :disabled="editFlag"
            >
              <el-option
                v-for="item in examinationTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="examination-add-content-line-item">
          <div class="title">分类:</div>
          <div class="value">
            <el-cascader
              v-model="formData.enterpriseCollegeCategoryId"
              :props="{ emitPath: false, value: 'id' }"
              :options="examinationInfo"
              clearable
              :disabled="editFlag"
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="examination-add-content-line">
        <div class="examination-add-content-line-item top">
          <div class="title">题干:</div>
          <div class="value">
            <el-input
              v-model="formData.title"
              type="textarea"
              resize="none"
              :rows="5"
              :disabled="editFlag"
            ></el-input>
            <!-- <myEditor
                      :content="formData.content"
                      @setHtml="setHtml"
                    ></myEditor> -->
          </div>
        </div>
      </div>
      <div class="examination-add-content-line">
        <div class="examination-add-content-line-item">
          <div class="title"></div>
          <div class="value">
            <el-table :data="formData.options" style="width: 100%">
              <el-table-column prop="label" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="content" label="内容"> </el-table-column>
              <el-table-column label="操作" width="150" fixed="right">
                <template slot="header">
                  <div
                    class="header"
                    @click="handleClick({}, 3)"
                    v-showFunction="{
                      active: 'editQuestion',
                      array: allFunctionButtons,
                    }"
                  >
                    <span>添加</span><i class="el-icon-circle-plus-outline"></i>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="handleClick(scope.row, 4, scope.$index)"
                    v-showFunction="{
                      active: 'editQuestion',
                      array: allFunctionButtons,
                    }"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="handleClick(scope.row, 5, scope.$index)"
                    v-showFunction="{
                      active: 'editQuestion',
                      array: allFunctionButtons,
                    }"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="examination-add-content-line">
        <div class="examination-add-content-line-item">
          <div class="title">参考答案:</div>
          <div class="value">
            <el-select
              v-model="formData.answer"
              :multiple="formData.type == '多选题' ? true : false"
              :disabled="editFlag"
            >
              <el-option
                v-for="item in formData.options"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="examination-add-content-line">
        <div class="examination-add-content-line-item top">
          <div class="title">试题分析:</div>
          <div class="value">
            <el-input
              v-model="formData.analyse"
              type="textarea"
              resize="none"
              :rows="5"
              :disabled="editFlag"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div
      class="examination-add-content-button"
      v-if="btnFlag"
      v-showFunction="{ active: 'editQuestion', array: allFunctionButtons }"
    >
      <el-button type="primary" @click="addExamination">确认</el-button>
      <!-- <el-button type="danger" @click="cancelExamination"> 取消</el-button> -->
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    my_dialog: () => import("@/components/dialog.vue"),
  },
  props: {
    allFunctionButtons: {
      type: Array,
      default: function() {
        return [];
      },
    },
    examinationInfo: {
      type: Array,
      default: function() {
        return [];
      },
    },
    formData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    btnFlag: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      editFlag: true,
      examinationTypeList: this.$examinationTypeList,
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加账号",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    /**
     * 切换题型
     */
    changeType(e) {
      if (e == "多选题") {
        this.formData.answer = [];
      } else {
        this.formData.answer = "";
      }
    },
    /**
     * 添加 编辑 考试题目
     */
    addExamination() {
      this.$emit("addExamination", this.formData);
    },
    /**
     * 取消考试题目
     */
    cancelExamination() {
      this.$emit("cancelExamination");
    },
    handleClick(data, flag, index) {
      let requestData = {};
      this.tempData = data;
      switch (flag) {
        case 3:
          // 添加题目选项
          this.dialogData.width = "600px";
          this.dialogData.title = "添加选项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_add",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "内容",
              type: "textarea",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          // 编辑题目选项
          this.tempData.index = index;
          this.dialogData.width = "600px";
          this.dialogData.title = "编辑选项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_edit",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "内容",
              type: "textarea",
              value: this.tempData.content,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 5:
          // 删除题目选项
          this.formData.options.splice(index, 1);
          break;
        default:
          break;
      }
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "confirm":
          // 确认
          if (!this.tempData.id) {
            console.log("新增");
            requestData = {
              type: this.formData.type, // 题目类型（单选题、多选题、判断题）
              enterpriseCollegeCategoryId: this.formData
                .enterpriseCollegeCategoryId, // 企学院类别 id
              title: this.formData.title, // 标题
              options: this.formData.options,
              answer: this.formData.answer,
              analyse: this.formData.analyse, // 试题分析
            };
            // 新增
            this.$request(
              "post",
              "/notice/addNotice",
              requestData,
              (data) => {
                this.$message.success("添加成功!");

                this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          } else {
            // 修改
            console.log("修改");
            requestData = {
              id: this.tempData.id, // id
              title: this.formData.title, // 标题
              content: this.formData.content, // 内容
            };
            this.$request(
              "post",
              "/notice/updateNotice",
              requestData,
              (data) => {
                this.$message.success("修改成功!");
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          }
          break;
        case "sure_add":
          // 确认添加题目选项
          // 获取数组中的最后一个元素
          let lastLetter = "";
          let asciiCode = "";
          let nextLetter = "";
          if (this.formData.options.length > 0) {
            lastLetter = this.formData.options.at(-1).label;
            // 将最后一个字母转换为 ASCII 码
            asciiCode = lastLetter.charCodeAt(0);
            // 生成下一个字母
            nextLetter = String.fromCharCode(asciiCode + 1);
          } else {
            nextLetter = "A";
          }
          this.formData.options.push({
            label: nextLetter,
            content: this.dialogData.data[0].value,
          });
          this.dialogData.flag = false;
          break;
        case "sure_edit":
          // 确认编辑题目选项
          console.log("his.formData.options", this.formData.options);
          this.formData.options[
            this.tempData.index
          ].content = this.dialogData.data[0].value;
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.allFunctionButtons.forEach((item) => {
      if (item.key == "editQuestion" && item.authority == "1") {
        this.editFlag = false;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.examination-add-content {
  position: relative;
  width: 100%;
  height: 95%;
  max-width: 90vw;
  max-height: 95vh;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .examination-add-content-box {
    height: 100%;
    overflow-y: auto;
    padding: 50px;
    padding-bottom: 100px;
    &::-webkit-scrollbar {
      height: 2px;
    }

    .examination-add-content-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .examination-add-content-line-item {
        width: 100%;
        display: flex;
        align-items: center;

        .title {
          min-width: 110px;
          font-size: 15px;
          font-weight: 700;
          margin-right: 10px;
          text-align: right;
        }
        .value {
          width: 100%;
          text-align: left;
          .header {
            display: flex;
            align-items: center;
            color: #409eff;
            cursor: pointer;
            span {
              margin-right: 5px;
            }
          }
          /deep/ .el-cascader {
            max-width: 217px;
          }
        }
      }
      .top {
        align-items: flex-start;
      }
    }
  }

  .examination-add-content-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 98%;
    height: 100px;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>
