<template>
  <div id="all-reimbursement">
    <!-- 全部报销 -->
    <div class="all-reimbursement-screen">
      <div class="all-reimbursement-screen-line">
        <div class="all-reimbursement-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-input
            placeholder="报销编号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id', emitPath: false }"
            filterable
          ></el-cascader>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-select
            v-model="searchData.status"
            placeholder="请选择报销状态"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.statusOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-select
            v-model="searchData.type"
            placeholder="请选择报销类型"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.typeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="all-reimbursement-screen-search">
          <el-date-picker
            v-model="searchData.submitTime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="提交开始月份"
            end-placeholder="提交结束月份"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-date-picker
            v-model="searchData.examineTime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="审批开始月份"
            end-placeholder="审批结束月份"
            @change="initTableData"
          >
          </el-date-picker>
        </div> -->
      </div>
      <div class="all-reimbursement-screen-line">
        <div class="all-reimbursement-screen-search">
          <el-input
            placeholder="收款人全称"
            v-model="searchData.payeeName"
            @keyup.enter.native="initTableData()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="all-reimbursement-screen-search money" style="width: 450px">
          <span style="width: 70px; font-size: 14px">金额范围:</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[0]"
              @keyup.enter.native="initTableData"
            >
            </el-input>
          </span>
          <span>-</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[1]"
              @keyup.enter.native="initTableData"
            >
            </el-input>
          </span>
          <span>元</span>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-select
            v-model="searchData.examineUser"
            placeholder="请选择审批人"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.examineOption"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="all-reimbursement-screen-search">
          <el-select
            v-model="searchData.payUser"
            placeholder="请选择付款人"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.payerOption"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="all-reimbursement-screen-active"></div>
        <div
          class="all-reimbursement-screen-but-boder"
          @click="importReimbursement(2)"
          v-showFunction="{
            active: 'reportReimburse',
            array: allFunctionButtons,
          }"
        >
          <!---->
          导出
        </div>
      </div>
    </div>
    <div class="all-reimbursement-table">
      <div class="all-reimbursement-table-card">
        <div class="all-reimbursement-table-card-tabs">
          <div
            :class="{
              'all-reimbursement-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            未报销
          </div>
          <div
            :class="{
              'all-reimbursement-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            已报销
          </div>
          <div
            :class="{
              'all-reimbursement-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
            @click="changeTab('0')"
          >
            全部
          </div>
          <div class="all-reimbursement-table-card-active"></div>
          <div class="all-amount">
            总金额:<span>{{ amountAll }}</span
            >元
          </div>
        </div>
        <div class="all-reimbursement-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            :default-sort="defaultSort"
            @sort-change="sortChange"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu"
              ><el-table-column
                :property="item.key"
                :key="index"
                :label="item.name"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row[item.key] != ''"
                    :class="{ 'table-tip': item.key == 'type' ? true : false }"
                    :style="{
                      color: item.key == 'type' ? scope.row.borderColor : '',
                      borderColor: scope.row.borderColor,
                    }"
                    >{{ scope.row[item.key] }}</span
                  >
                  <span v-else>--</span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 'showDetails')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'reimburseInfo',
                    array: allFunctionButtons,
                  }"
                  >查看</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 'delete')"
                  type="text"
                  style="color: #fd5012"
                  size="small"
                  v-showFunction="{
                    active: 'delReimburse',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="all-reimbursement-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @expendDetails="expendDetails"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as reimbursementJsData from "./js/reimbursement.js";
export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        submitTime: ["", ""], // 提交时间
        examineTime: ["", ""], // 审批时间
        type: "", // 报销类型
        typeOptions: [],
        status: "", // 报销状态
        statusOptions: [
          { name: "审批中", value: "1" },
          { name: "已驳回", value: "2" },
          { name: "已撤销", value: "3" },
          { name: "待付款", value: "4" },
          { name: "已完成", value: "5" },
        ],
        userId: "",
        userList: [],
        tempUserId: [],
        department: "",
        departTreeData: [],
        amountAll: ["", ""], // 金额范围
        examineUser: "",
        examineOption: [],
        payUser: "",
        payerOption: [],
        payeeName: "",
        screenList: [],
        payOptions: [],
      },
      tempAllEmpolyeeList: [],
      popoData: {
        popoVisible: false,
        list: reimbursementJsData.myReimbursementSearchData([]),
      },
      allFunctionButtons: [],
      tabindex: "1",
      tabMenu: reimbursementJsData.getMyReimbursementTabMenu(),
      tableData: [],
      amountAll: "0", // 总报销金额
      defaultSort: { prop: "", order: "" },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "45%",
        title: "新建报销",
        buttonArray: [],
        data: [],
      },

      employeeList: [], // 所有员工列表
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "allReimburse"
      ),
      multipleSelection: [],
      tempData: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "allReimburse",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getDepartmentTreeByUserId();
          // this.drawerOptions.allUser = this.employeeList;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门树 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          console.log(data);
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.searchData.userId = "all";
          this.searchData.tempUserId = this.tempAllEmpolyeeList;
          this.getTagList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "报销类型") {
              this.searchData.typeOptions = element.value;
            }
            if (element.type == "付款方式") {
              this.searchData.payOptions = element.value.map((item) => {
                return { label: item.name, value: item.value };
              });
            }
          }
          console.log("当前标签组", this.searchData);
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    // 初始化表格数据
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        userId:
          this.searchData.tempUserId.length == 0
            ? [this.baseInfo.userId]
            : this.searchData.tempUserId, // 用户id
        department: this.searchData.department, // 部门ID
        amountAll: this.searchData.amountAll, // 金额范围
        // submitTime: this.searchData.submitTime, // 提交时间范围
        // examineTime: this.searchData.examineTime, // 审批时间范围
        type: this.searchData.type, // 报销类型
        numbers: this.searchData.input, // 审批编号(不能模糊)
        status: this.searchData.status, // 状态
        statusFlag: this.tabindex, // 0全部 1-未报销；2-已报销
        examineUser: this.searchData.examineUser, // 审批人
        payUser: this.searchData.payUser, // 付款人
        payeeName: this.searchData.payeeName, // 收款人全称
        screenList: this.searchData.screenList, // 其余筛选条件
        pageSize: this.pageData.pageSize, // 每页多少条
        currentPage: this.pageData.currentPage, // 第几页
      };
      this.$request(
        "post",
        "/reimburse/getAllReimburse",
        requestData,
        (data) => {
          // console.log("获取到的表格数据是", data);
          this.loading = false;
          this.pageData.total = data.total;
          this.tableData = reimbursementJsData.initTableData(data.tableData);
          this.amountAll = data.totalAmount;
        },
        (errorMessage) => {
          this.loading = false;
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格数据
     * state:是否包含离职员工
     */
    getEmployeeListByCompany() {
      let requestData = {
        userId:
          this.searchData.tempUserId.length == 0
            ? [this.baseInfo.userId]
            : this.searchData.tempUserId, // 用户id
        companyId: this.baseInfo.company.companyId,
        search: "",
        isDiMission: false, // 是否包含离职
      };
      this.$request(
        "post",
        "/user/getEmployeeList",
        requestData,
        (data) => {
          console.log("获取到的数据是", data);
          this.searchData.examineOption = [];
          this.searchData.payerOption = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let dd = {
              name: element.userName,
              value: element.userId,
            };
            this.searchData.examineOption.push(dd);
            this.searchData.payerOption.push(dd);
          }
        },
        (errorMessage) => {
          this.Loading();
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 添加/导入/导出报销
     */
    importReimbursement(active) {
      switch (active) {
        case 2:
          // 导出报销
          // if (this.searchData.screenButton_export == 2) {
          //   this.$message.error(
          //     this.$errorMessage.getErrorMessageInfo("authority")
          //   );
          //   return;
          // }
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择想要导出的报销!");
            return;
          }
          let requestData = "";
          this.multipleSelection.forEach((element) => {
            if (requestData == "") {
              requestData = element.id;
            } else {
              requestData = requestData + "," + element.id;
            }
          });
          console.log("传入参数:", requestData);
          window.location.href =
            this.$baseurl +
            "/reimburse/exportReimburse?reimburseId=" +
            requestData;
          break;
      }
    },
    /**
     * 报销表格操作
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = { id: item.id };
      switch (active) {
        case "showDetails":
          // 查看详情
          this.$request(
            "post",
            "/reimburse/seeDetails",
            requestData,
            (data) => {
              this.dialogData.title = "查看报销";
              this.dialogData.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                  key: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  type: "show-reimbursemen",
                  title: "",
                  isEditFlag: false,
                  value: data,
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.loading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "delete":
          // 删除
          this.$confirm("此操作将永久删除该报销, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(
              () => {
                this.$request(
                  "post",
                  "/reimburse/delReimburse",
                  requestData,
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.initTableData();
                  }
                );
              },
              (errorMessage) => {
                this.loading = false;
                this.$message.error(errorMessage);
              }
            )
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },

    seleteInitTableData(val) {
      console.log("选择人员被修改");
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 查看详情
     */
    expendDetails(expenditureId) {
      this.$request(
        "get",
        "/contractExpenditure/getInfoById?id=" + expenditureId,
        {},
        (data) => {
          data.attachments = data.attachments.split(",");
          data.reimburse = {
            status: this.tempData.status,
            color: this.tempData.color,
          };

          this.dialogData.title = "支出";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "expend_details",
              title: "",
              value: data,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 2) {
          sums[index] = "合计";
          return;
        }
        if (index !== 7) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let tipsData = {
        payOptions: this.searchData.payOptions,
      };
      this.popoData.list = reimbursementJsData.myReimbursementSearchData(
        tipsData
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    // 立即报销
    check1() {
      if (this.searchData.check1) {
        this.searchData.amountAll[0] = "100";
        this.searchData.amountAll[1] = "";
        this.initTableData();
      } else {
        this.searchData.amountAll[0] = "";
        this.searchData.amountAll[1] = "";
        this.initTableData();
      }
    },
    // 立即付款
    check2() {
      if (this.searchData.check2) {
        this.searchData.type = "付款";
        this.initTableData();
      } else {
        this.searchData.type = "";
        this.initTableData();
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.singleTable.doLayout();
    });
  },
  mounted() {
    this.loading = true;
    this.searchData.userId = this.baseInfo.userId;
    // this.searchData.tempUserId = [this.baseInfo.userId];
    this.getEmployeeList();
    this.getEmployeeListByCompany();
    // this.initTableData();
    // this.searchData.tempUserId = ["all"];
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#all-reimbursement {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .all-reimbursement-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .all-reimbursement-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .all-reimbursement-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .all-reimbursement-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .money {
        margin-right: 20px;
      }
      .all-reimbursement-screen-search {
        width: 235px;
        // min-width: 235px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: 8px;
        > .in {
          width: 0;
          flex: 1;
          height: 100%;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 3px;
          box-sizing: border-box;
        }
        &:nth-child(1) {
          margin-left: 8px;
        }
      }
      .all-reimbursement-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .all-reimbursement-screen-active {
        flex: 1;
      }
      .all-reimbursement-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .all-reimbursement-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .all-reimbursement-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .all-reimbursement-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .all-reimbursement-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .all-reimbursement-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .all-reimbursement-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .all-reimbursement-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .all-reimbursement-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .all-reimbursement-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .all-reimbursement-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .all-reimbursement-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .all-reimbursement-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .all-reimbursement-table-card-active {
          flex: 1;
        }
        .all-reimbursement-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
        .all-amount {
          width: auto;
          height: 100%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          > span {
            color: #fc8a2e;
            margin: 0 3px;
          }
        }
      }
      .all-reimbursement-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .all-reimbursement-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      cursor: pointer;
      // color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
