<template>
  <!-- 考试试卷 -->
  <div id="knowledge-paper">
    <div class="knowledge-paper-screen">
      <div class="knowledge-paper-screen-line">
        <div class="knowledge-paper-screen-search">
          <el-input
            placeholder="请输入试卷标题"
            v-model="searchData.title"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="knowledge-paper-screen-search">
          <el-cascader
            placeholder="请选择试卷分类"
            v-model="searchData.categoryId"
            :props="{ emitPath: false, value: 'id' }"
            :options="searchData.categoryList"
            clearable
            @change="resetPage"
          ></el-cascader>
        </div>
        <div class="knowledge-paper-screen-active"></div>
      </div>
    </div>
    <div class="table-content">
      <div class="knowledge-paper-table-card" v-if="!examFlag">
        <div class="knowledge-paper-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40"></el-table-column>
            <el-table-column prop="title" label="试卷标题"></el-table-column>
            <el-table-column
              prop="totalScore"
              label="理论总分"
            ></el-table-column>
            <el-table-column prop="duration" label="考试时间"></el-table-column>
            <el-table-column
              prop="passingScore"
              label="考试及格分"
            ></el-table-column>
            <el-table-column
              prop="enterpriseCollegeCategoryName"
              label="试卷分类"
            ></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  v-showFunction="{
                    active: 'startExam',
                    array: allFunctionButtons,
                  }"
                  size="mini"
                  type="primary"
                  plain
                  @click="startTest(scope.row)"
                  >开始考试</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="knowledge-paper-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="knowledge-paper-table-card" v-else>
        <div class="knowledge-paper-content">
          <exam
            :examInfo="examInfo"
            @submitExam="submitExam"
            @returnExam="returnExam"
          ></exam>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_dialog from "@/components/dialog.vue";
import exam from "./components/exam.vue";
export default {
  components: {
    my_dialog,
    exam,
  },
  data() {
    return {
      baseInfo: {},
      whetherAgencyHead: false,
      loading: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgePaper"
      ),
      tableData: [],
      searchData: {
        title: "",
        categoryId: "",
        screenList: [],
        department: "",
        departTreeData: [],
        userId: "",
        userIdList: [],
        userList: [],
        tempUserId: [],
        categoryList: [], // 企学院分类
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      examFlag: false,
      examInfo: {},
      testPaperInfo: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgePaper",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getTreeList();
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取企学院分类
     */
    getTreeList() {
      let requestData = {
        flag: true,
      };
      this.$request(
        "post",
        "/enterpriseCollegeCategory/getPureTreeList",
        requestData,
        (data) => {
          if (data.length > 0) {
            this.searchData.categoryList = data.map((person) => {
              let { name, ...rest } = person; // 解构赋值，提取 name
              return {
                label: name, // 改为 label
                ...rest,
                children: person.children
                  ? person.children.map((child) => {
                      let { name, id } = child; // 对孩子也进行同样的操作
                      return {
                        label: name, // 改为 label
                        id: id,
                      };
                    })
                  : [], // 处理没有 children 的情况
              };
            });
          }
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        title: this.searchData.title, // 筛选项: 试卷标题
        categoryId: this.searchData.categoryId, // 筛选项: 试卷分类
        userId: this.baseInfo.userId,
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/examPreparation/page",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 界面操作
     * batch 是否为批量操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let dialogData = this.dialogData.data[0].value;
      switch (active) {
        case "submit_editTestPaper":
          requestData = dialogData.data;
          requestData.id = this.tempData.id;
          requestData.examPaperRules.forEach((item, index) => {
            item.sort = index; // 排列顺序
            item.enterpriseCollegeCategoryId =
              requestData.enterpriseCollegeCategoryId; // 企学院分类id
          });
          this.$request(
            "post",
            "/examPaper/update",
            requestData,
            (data) => {
              this.$message.success("编辑试卷成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 开始考试
     */
    startTest(item) {
      //  根据试卷id 生成对应试题
      let requestData = {
        examPaperId: item.id, // 试卷规则 id
        userId: this.baseInfo.userId, // 考生 id
      };
      this.$request(
        "post",
        "/examRecordsPaper/generate",
        requestData,
        (data2) => {
          this.examInfo = data2;
          this.examInfo.examQuestions.forEach((item) => {
            this.$set(item, "selected", []);
          });
          this.examFlag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 考试结束 提交试卷
     */
    submitExam() {
      let confirmText = "";
      let requestData = JSON.parse(JSON.stringify(this.examInfo));
      let flag = requestData.examQuestions.every(
        (item) => item.selected.length > 0
      );
      if (!flag) {
        confirmText = "本次考试还有未完成的题目, 是否确认交卷!";
      } else {
        confirmText = "本次作答已完成, 是否确认交卷!";
      }
      this.$confirm(confirmText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.startLoading();
          requestData.userId = this.baseInfo.userId; // 考生 id
          requestData.status = "2"; // 状态：'1' - 答题中；2 - 已完成
          requestData.takeDuration = ""; // 用时（分钟）
          requestData.examQuestions.forEach((item) => {
            item.selected = item.selected.toString();
          });

          this.$request(
            "post",
            "/examRecordsPaper/submit",
            requestData,
            (data) => {
              setTimeout(() => {
                this.loading.close();
                this.$message.success("交卷成功!");
                this.examFlag = false;
                this.initTableData();
              }, 1500);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          this.loading.close();
        })
        .catch(() => {});
    },
    returnExam() {
      this.examFlag = false;
    },
    startLoading() {
      this.loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: "正在提交试卷, 请耐心等待!", //显示在加载图标下方的加载文案
        spinner: "el-icon-loading", //自定义加载图标类名
        background: "rgba(0, 0, 0, 0.7)", //遮罩层颜色
        target: document.querySelector("#table"), //loadin覆盖的dom元素节点
      });
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  mounted() {
    this.searchData.userId = "all";
  },
};
</script>

<style lang="less" scoped>
#knowledge-paper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-paper-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .knowledge-paper-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .knowledge-paper-screen-line-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .knowledge-paper-screen-line-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .knowledge-paper-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
      }
      .knowledge-paper-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .archives-screen-but-select {
        padding: 0px 52px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .knowledge-paper-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-paper-content {
        width: 100%;
        height: 100%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .knowledge-paper-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
