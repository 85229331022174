<template>
  <!-- 考试 -->
  <div id="knowledge-examination">
    <div class="knowledge-examination-screen">
      <div class="knowledge-examination-screen-line">
        <div class="knowledge-examination-screen-search"></div>
        <div class="knowledge-examination-screen-active"></div>
        <div
          class="knowledge-examination-but-boder"
          @click="handleClick('', 0)"
          v-if="!editorFlag"
        >
          <!--  v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }" -->
          <i class="el-icon-plus" style="margin-right: 5px;"></i>
          添加信息
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="knowledge-examination-table-card">
        <div class="knowledge-examination-content" v-if="!editorFlag">
          <el-table
            :data="tableData"
            style="width: 100%"
            highlight-current-row
            height="98%"
            v-loading="loading"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column prop="title" label="题干"></el-table-column>
            <el-table-column
              prop="type"
              label="类型"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="knowledgeCategoryName"
              label="分类"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
              <template scope="scope">
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 1)"
                  >编辑</el-button
                >
                <!-- v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }" -->
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleClick(scope.row, 2)"
                  >删除</el-button
                >
                <!--  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }" -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="knowledge-examination-content" v-else>
          <examinationAdd
            :examinationInfo="searchData.categoryList"
            :formData="formData"
            @addExamination="addExamination"
            @cancelExamination="cancelExamination"
          ></examinationAdd>
        </div>
        <div class="knowledge-examination-table-card-bottom" v-if="!editorFlag">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import myEditor from "@/components/myEditor.vue";
import examinationAdd from "./knowledge/components/examination_add.vue";
export default {
  components: {
    my_dialog,
    myEditor,
    examinationAdd,
  },
  data() {
    return {
      loading: false,
      editorFlag: false,
      baseInfo: {
        userId: "",
        company: {
          companyName: "",
        },
      },
      whetherAgencyHead: false,
      searchData: {
        categoryList: [],
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加账号",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      formData: {
        type: "", // 题型 （单选题、多选题、判断题）
        enterpriseCollegeCategoryId: "", // 企学院类别 id
        title: "", // 题干
        options: [],
        answer: [], // 参考答案
        analyse: "", // 试题分析
      },
      tableData: [],
      flag: false,
      uploadData: {
        title: "上传附件",
        type: "upload",
        value: {
          value: "",
          action: this.$fileBaseurl + "/uploadImageByMultipartFile",
          headers: {
            token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          uploadParameter: {
            systemName: "finance",
            title: "最新政策",
          },
        },
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeExamination"
      ),
      tempData: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },

  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeExamination",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getTreeList();
      });
    },
    /**
     * 获取企学院分类
     */
    getTreeList() {
      let requestData = {
        flag: true,
      };
      this.$request(
        "post",
        "/enterpriseCollegeCategory/getPureTreeList",
        requestData,
        (data) => {
          if (data.length > 0) {
            this.searchData.categoryList = data.map((person) => {
              let { name, ...rest } = person; // 解构赋值，提取 name
              return {
                label: name, // 改为 label
                ...rest,
                children: person.children
                  ? person.children.map((child) => {
                      let { name, id } = child; // 对孩子也进行同样的操作
                      return {
                        label: name, // 改为 label
                        id: id,
                      };
                    })
                  : [], // 处理没有 children 的情况
              };
            });
          }
          this.initTableData();
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/examQuestion/query",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, flag, index) {
      let requestData = {};
      this.tempData = data;
      switch (flag) {
        case 0:
          // 编辑
          this.editorFlag = true;
          break;
        case 1:
          // 编辑
          this.editorFlag = true;
          this.formData = data;
          this.formData.answer = JSON.parse(data.answer);
          break;
        case 2:
          // 删除
          requestData = {
            ids: [data.id],
          };
          this.$confirm("确认要删除这条信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/notice/delNotice",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 3:
          // 添加题目选项
          this.dialogData.width = "600px";
          this.dialogData.title = "添加选项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_add",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "内容",
              type: "textarea",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          // 编辑题目选项
          this.tempData.index = index;
          this.dialogData.width = "600px";
          this.dialogData.title = "添加选项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_edit",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "内容",
              type: "textarea",
              value: this.tempData.content,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 5:
          // 删除题目选项
          this.formData.options.splice(data.index, 1);
          break;
        default:
          break;
      }
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          // 确认添加题目选项
          this.formData.options.push({
            label: "E",
            content: this.dialogData.data[0].value,
          });
          this.dialogData.flag = false;
          break;
        case "sure_edit":
          // 确认编辑题目选项
          console.log("his.formData.options", this.formData.options);
          this.formData.options[
            this.tempData.index
          ].content = this.dialogData.data[0].value;
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 添加 编辑 考试题目
     */
    addExamination(formData) {
      let requestData = {};
      if (this.tempData.id) {
        // 编辑考试题目
        requestData = this.formData;
        requestData.answer = JSON.stringify(requestData.answer);
        requestData.id = this.tempData.id;
        this.$request(
          "post",
          "/examQuestion/edit",
          requestData,
          (data) => {
            this.$message.success("编辑成功!");
            this.editorFlag = false;
            this.initTableData();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      } else {
        // 添加考试题目
        requestData = this.formData;
        requestData.answer = JSON.stringify(requestData.answer);
        this.$request(
          "post",
          "/examQuestion/add",
          requestData,
          (data) => {
            this.$message.success("添加成功!");
            this.editorFlag = false;
            this.initTableData();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
      return;
    },
    cancelExamination() {
      (this.formData = {
        type: "", // 题型 （单选题、多选题、判断题）
        enterpriseCollegeCategoryId: "", // 企学院类别 id
        title: "", // 题干
        options: [],
        answer: [], // 参考答案
        analyse: "", // 试题分析
      }),
        (this.editorFlag = false);
    },
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    handleAvatarSuccess(response, file, fileList) {
      console.log(file);

      this.formData.cover = response.result.path;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    setHtml(html) {
      this.formData.content = html;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));

    this.baseInfo.company.companyAdmins.forEach((element) => {
      if (element.companyAdminId == this.baseInfo.userId) {
        this.flag = true;
      }
    });
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#knowledge-examination {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-examination-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .knowledge-examination-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .knowledge-examination-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .knowledge-examination-screen-active {
        flex: 1;
      }
      .knowledge-examination-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .knowledge-examination-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .knowledge-examination-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-examination-content {
        position: relative;
        width: 100%;
        height: 95%;
        max-width: 90vw;
        max-height: 95vh;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .knowledge-examination-content-box {
          height: 100%;
          overflow-y: auto;
          padding: 50px;
          padding-bottom: 100px;
          .knowledge-examination-content-line {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            .knowledge-examination-content-line-item {
              width: 100%;
              display: flex;
              align-items: center;

              .title {
                min-width: 150px;
                font-size: 15px;
                font-weight: 700;
                margin-right: 10px;
                text-align: right;
              }
              .value {
                width: 100%;
                text-align: left;
                .header {
                  display: flex;
                  align-items: center;
                  color: #409eff;
                  cursor: pointer;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .top {
              align-items: flex-start;
            }
          }
        }

        .knowledge-examination-content-button {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 98%;
          height: 100px;
          background-color: #fff;
          overflow: hidden;
        }
      }
      .knowledge-examination-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
