<template>
  <div id="com_knowledge">
    <div class="line">
      <div class="title">可见部门</div>
      <div class="value">
        <el-cascader
          v-model="value.departmentIds"
          :options="value.departTreeData"
          :props="props"
          collapse-tags
          clearable
        ></el-cascader>
      </div>
    </div>
    <div class="line">
      <div class="title">分组</div>
      <div class="value">
        <el-cascader
          v-model="value.categoryId"
          :props="props2"
          :options="value.categoryList"
          clearable
        ></el-cascader>
      </div>
    </div>
    <div class="line">
      <div class="title">标题</div>
      <div class="value">
        <el-input
          v-model="value.title"
          placeholder="输入标题,方便识别与检索"
          clearable
          :disabled="value.isDisable"
        ></el-input>
      </div>
    </div>
    <div class="line">
      <div class="title">摘要</div>
      <div class="value">
        <el-input
          type="textarea"
          :rows="3"
          v-model="value.content"
          placeholder="输入问题的摘要,进一步描述"
          maxlength="500"
          clearable
          show-word-limit
          :disabled="value.isDisable"
        ></el-input>
      </div>
    </div>
    <!-- <div class="line">
      <div class="title">类型</div>
      <div class="value">
        <el-radio-group
          v-model="value.type"
          @change="changeFlag"
          :disabled="value.isDisable"
        >
          <el-radio
            :label="it.value"
            v-for="(it, ii) in typeOptions"
            :key="ii"
            >{{ it.label }}</el-radio
          >
        </el-radio-group>
      </div>
    </div> -->
    <div class="line">
      <div class="title">内容</div>
      <div class="value">
        <myEditor :content="value.additional" @setHtml="setHtml"></myEditor>
        <!-- <el-input
          v-if="value.type == '0'"
          type="textarea"
          :rows="8"
          v-model="value.textAdditional"
          placeholder="请输入内容"
          maxlength="500"
          clearable
          show-word-limit
          :disabled="value.isDisable"
        ></el-input>
        <div class="upload_content" v-if="value.type == '1'">
          <div
            class="upload_content_value"
            v-for="(item, index) in value.imgAdditional"
            :key="index"
          >
            <div class="upload_content_value_title">
              {{ item.title | controlWord }}
            </div>
            <div class="upload_content_value_data">
              <img
                :src="item.url"
                alt=""
                :style="{ width: '100%', height: '100%' }"
              />
              <div class="upload_content_value_data_hover">
                <i class="el-icon-view" @click="imgCilck(item, index, '0')">
                </i>
                <i
                  class="el-icon-delete"
                  @click="imgCilck(item, index, '1')"
                  v-if="!value.isDisable"
                ></i>
              </div>
            </div>
          </div>
          <div class="upload_content_value">
            <div class="upload_content_value_title">添加资料附件</div>
            <div class="upload_content_value_but">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :headers="headers"
                :data="uploadParameter"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeAvatarUpload1"
                :disabled="value.isDisable"
              >
                <div class="upload_content_value_but-upload">
                  <i class="el-icon-upload2"></i>
                  <span>点击上传</span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="upload_content" v-if="value.type == '2'">
          <div
            class="upload_content_value"
            v-if="value.documentAdditional.url != ''"
          >
            <div class="upload_content_value_title">
              {{ value.documentAdditional.title | controlWord }}
            </div>
            <div class="upload_content_value_data">
              <img
                :src="
                  value.documentAdditional.type == 'docx' ||
                  value.documentAdditional.type == 'doc'
                    ? documentImg1
                    : value.documentAdditional.type == 'xls' ||
                      value.documentAdditional.type == 'xlsx'
                    ? documentImg2
                    : documentImg3
                "
                alt=""
              />
              <div class="upload_content_value_data_hover">
                <i
                  class="el-icon-download"
                  @click="imgCilck(value.documentAdditional, 0, '2')"
                >
                </i>
                <i
                  class="el-icon-delete"
                  v-if="!value.isDisable"
                  @click="imgCilck(value.documentAdditional, 0, '3')"
                ></i>
              </div>
            </div>
          </div>
          <div class="upload_content_value" v-if="!value.isDisable">
            <div class="upload_content_value_title">添加资料附件</div>
            <div class="upload_content_value_but">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :headers="headers"
                :data="uploadParameter"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                :disabled="value.isDisable"
              >
                <div class="upload_content_value_but-upload">
                  <i class="el-icon-upload2"></i>
                  <span>点击上传</span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <el-input
          v-if="value.type == '3'"
          v-model="value.linkAdditional"
          placeholder="http://"
          clearable
          :disabled="value.isDisable"
        ></el-input> -->
      </div>
    </div>
    <div class="showImg" v-if="show" v-viewer="{ movable: false }">
      <div class="close" @click="show = false">X</div>
      <img :src="showImg" alt="" />
    </div>
  </div>
</template>

<script>
import myEditor from "@/components/myEditor.vue";
export default {
  components: {
    myEditor,
  },
  props: ["knowledgeData"],
  filters: {
    controlWord(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      value: this.knowledgeData,
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      uploadParameter: {
        systemName: "finance",
        title: "知识库",
      },
      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
      typeOptions: [
        {
          label: "文字",
          value: "0",
        },
        {
          label: "图片",
          value: "1",
        },
        {
          label: "文档",
          value: "2",
        },
        {
          label: "链接",
          value: "3",
        },
      ],
      index: "0",
      documentImg1: require("@/assets/img/knowledge/word.png"),
      documentImg2: require("@/assets/img/knowledge/excel.png"),
      documentImg3: require("@/assets/img/knowledge/PDF.png"),
      show: false,
      showImg: "",
      props: { multiple: true, emitPath: false, value: "id" },
      props2: { emitPath: false, value: "id" },
    };
  },
  watch: {
    knowledgeData() {
      //父组件值变化->>子组件也变化
      this.value = this.knowledgeData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    changeFlag(value) {
      this.index = value;
      if (value == "0" || value == "3") {
        this.value.additional = "";
      } else {
        this.value.additional = [];
      }
    },
    // 图片上传前图片校验
    beforeAvatarUpload1(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";
      let isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    // 图片上传成功
    handleAvatarSuccess1(response, file, fileList) {
      let dd = {
        url: response.result.path,
        title: file.name,
      };
      this.value.imgAdditional.push(dd);
    },
    // 文件上传前文档校验
    beforeAvatarUpload2(file) {
      let isJPG =
        file.type === "application/pdf" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type == "application/vnd.ms-excel" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type == "application/msword";
      let isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传文件只能是 pdf 、Word 、Excel格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    // 文件上传成功
    handleAvatarSuccess2(response, file, fileList) {
      let fileUrlList = file.name.split(".");
      let dd = {
        url: response.result.path,
        title: file.name,
        type: fileUrlList[fileUrlList.length - 1],
      };
      this.value.documentAdditional = dd;
    },
    /**
     * 图片点击事件
     */
    imgCilck(item, index, active) {
      switch (active) {
        case "0":
          // 查看大图
          this.showImg = item.url;
          this.show = true;
          break;
        case "1":
          // 删除图片
          this.value.imgAdditional.splice(index, 1);
          break;
        case "2":
          //   下载文档
          // (item.url, "_blank");
          if (this.$keyType.keyType(item.url) == "Object") {
            item.url.path, "_blank";
          } else if (this.$keyType.keyType(item.url) == "String") {
            item.url, "_blank";
          } else {
            this.$message.error("附件格式错误,请联系管理员!");
          }
          break;
        case "3":
          //   删除文档
          this.value.documentAdditional = {
            title: "",
            type: "",
            url: "",
          };
          break;

        default:
          break;
      }
    },
    setHtml(html) {
      console.log("html", html);
      this.value.additional = html;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#com_knowledge {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  > .line {
    width: 100%;
    height: auto;
    min-height: 35px;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    > .title {
      width: 14%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding-right: 8px;
      box-sizing: border-box;
      font-weight: bold;
    }
    > .value {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > .upload_content {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        > .upload_content_value {
          width: 150px;
          height: 100px;
          border: 1px solid @boderColor;
          border-radius: 3px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          align-items: center;
          margin: 5px;
          > .upload_content_value_title {
            width: 100%;
            height: 20px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            padding-left: 5px;
          }
          > .upload_content_value_data,
          > .upload_content_value_but {
            width: 92%;
            height: 0;
            flex: 1;
            box-sizing: border-box;
            // padding: 5px;
            margin: 5px;
            border: 1px solid #d1d3d862;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          > .upload_content_value_data {
            position: relative;
            > .upload_content_value_data_hover {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              z-index: 10;
              transition: 0.3s;
              &:hover {
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.281);
              }
              > i {
                margin: 0 10px;
                font-size: 30px;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  color: @tipColor;
                }
              }
            }
          }
          .upload_content_value_but-upload {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            color: rgba(68, 68, 68, 0.432);
            transition: 0.3s;
            font-size: 13px;
            > i {
              font-size: 30px;
            }
            &:hover {
              color: @activeColor;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  > .showImg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.144);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    > .close {
      width: auto;
      height: auto;
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      transition: 0.3s;
      font-size: 18px;
      &:hover {
        cursor: pointer;
        color: @closeColor;
      }
    }
  }
  /deep/ .el-cascader {
    width: 100%;
  }
}
</style>
