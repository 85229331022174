<template>
  <div id="filters">
    <div class="filters-type">
      <el-radio-group
        v-model="componentsData.playersType"
        @change="radioChange"
      >
        <el-radio label="1">人员</el-radio>
        <el-radio label="2">角色</el-radio>
      </el-radio-group>
    </div>
    <div class="filters-value" v-if="componentsData.playersType === '1'">
      <el-select
        v-model="componentsData.visibleUsers"
        multiple
        filterable
        value-key="userId"
      >
        <el-option
          v-for="it in componentsData.visibleUsersList"
          :key="it.userId"
          :label="it.userName"
          :value="it.userId"
        >
        </el-option>
      </el-select>
    </div>
    <div class="filters-value" v-if="componentsData.playersType === '2'">
      <el-select v-model="componentsData.businessRole" filterable>
        <el-option
          v-for="it in componentsData.businessRoleList"
          :key="it.value"
          :label="it.label"
          :value="it.value"
        >
        </el-option>
      </el-select>
      <div class="task-info-screen-fiftrate">
        <el-popover
          placement="bottom-start"
          width="250"
          trigger="manual"
          :visible-arrow="false"
          v-model="popoData.popoVisible"
        >
          <div class="popover-search">
            <popo_search
              :list="popoData.list"
              @cancel="popoCanael"
              @reset="popoReset"
              @makeSure="popoMakeSure"
            ></popo_search>
          </div>
          <div slot="reference" @click="popoverOpen">
            筛选 <i class="el-icon-s-operation"></i>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import * as taskData from "@/views/task/js/task.js";
import popo_search from "@/components/popo-search.vue";
export default {
  props: {
    componentsData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    popo_search,
  },
  data() {
    return {
      baseInfo: {},
      popoData: {
        popoVisible: false,
        list: taskData.taskInfoSearchData("", {}, []),
      },
      searchData: {
        input: "",
        allUserList: [],
        screenList: [],
      },
      clientDrawerOptions: {},
      productTreeList: [], // 服务产品列表
    };
  },
  methods: {
    getTagList() {
      this.$tagData.getTagList(
        this.clientDrawerOptions,
        ["增值税类型", "客户等级", "客户标签"],
        this.baseInfo.company.companyId,
        this
      );
      this.getServeTypeAndItem();
    },
    /**
     * 获取产品树
     */
    getServeTypeAndItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.productTreeList = data;
        }
      );
    },
    radioChange() {
      this.$forceUpdate();
      if (this.radio == "1") {
        console.log("人员");
      } else {
        console.log("角色");
      }
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },

    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let tipsData = {
        clientTipsList: this.clientDrawerOptions.tipList, // 客户标签
        clientLevelList: this.clientDrawerOptions.levelList, // 客户等级
      };
      this.popoData.list = taskData.taskInfoSearchData(
        tipsData,
        this.productTreeList,
        this.componentsData.clientCondition
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      console.log(
        "this.popoData.list",
        JSON.parse(JSON.stringify(this.popoData.list))
      );
      this.componentsData.clientCondition = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      console.log(
        "this.componentsData.clientCondition",
        JSON.parse(JSON.stringify(this.componentsData.clientCondition))
      );
      this.popoData.popoVisible = false;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getTagList();
    console.log(
      "componentsData",
      JSON.parse(JSON.stringify(this.componentsData))
    );
  },
};
</script>

<style lang="less" scoped>
#filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .filters-type {
    margin: 5px 0;
  }
  .filters-value {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .task-info-screen-fiftrate {
      width: 65px;
      min-width: 65px;
      height: 30px;
      border: 1px solid @boderColor;
      box-sizing: border-box;
      color: @boderActiveColor;
      font-size: @contentFontSize;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.3s;
      i {
        margin: 0 4px;
      }
    }
    .task-info-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
  }
}
</style>
