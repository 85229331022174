<template>
  <div id="human-payroll-setting">
    <!-- 工资单 -->
    <div class="human-payroll-setting-screen">
      <div class="human-payroll-setting-screen-line">
        <div class="human-payroll-setting-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入姓名"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div
          class="human-payroll-setting-screen-search"
          v-if="whetherAgencyHead"
        >
          <el-input
            v-model="searchData.company"
            placeholder="请输入所在公司"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div
          class="human-payroll-setting-screen-search"
          v-if="whetherAgencyHead"
        >
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div
          class="human-payroll-setting-screen-search"
          v-if="whetherAgencyHead"
        >
          <el-date-picker
            v-model="searchData.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
            :clearable="false"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="human-payroll-setting-screen-active"></div>
        <el-button
          type="primary"
          size="small"
          @click="handleClick('', 5)"
          v-showFunction="{
            active: 'backupCopy',
            array: allFunctionButtons,
          }"
          :loading="buttonLoading"
          >业务提成同步</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="handleClick('', 7)"
          v-showFunction="{
            active: 'batchIssue',
            array: allFunctionButtons,
          }"
          :loading="buttonLoading"
          >批量发布</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          >新增工资条</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="handleClick('', 3)"
          v-showFunction="{
            active: 'import',
            array: allFunctionButtons,
          }"
          >导入</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          style="margin-right: 10px"
          @click="handleClick('', 4)"
          v-showFunction="{
            active: 'export',
            array: allFunctionButtons,
          }"
          >导出</el-button
        >
      </div>
    </div>
    <div class="table-content">
      <div class="human-payroll-setting-table-card">
        <div class="human-payroll-setting-content">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            :row-style="{ height: '60px' }"
            :row-class-name="rowClassName"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              label="姓名"
              width="150"
              show-overflow-tooltip
              fixed
            >
              <template slot-scope="scope">
                <el-popover
                  placement="top"
                  trigger="hover"
                  :content="'离职时间: ' + scope.row.dimission"
                  :disabled="!scope.row.dimission"
                >
                  <span slot="reference">{{ scope.row.name }}</span>
                </el-popover>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableMenu">
              <el-table-column
                :key="index"
                :label="item.label"
                :fixed="index == 0"
                :prop="item.prop"
                :min-width="
                  item.prop == 'businessCommissionIncOrDec' ? 150 : 110
                "
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      item.prop == 'businessCommission' ||
                        item.prop == 'deliveryCommission'
                    "
                    style="color: #409EFF; cursor: pointer"
                    @click="handleClick(scope.row, 6, item.prop)"
                    >{{ scope.row[item.prop] }}</span
                  >
                  <el-tooltip
                    v-else
                    :content="scope.row[item.remark]"
                    placement="top"
                    :disabled="
                      index == 0 || index == 1 || !scope.row[item.remark]
                    "
                  >
                    <span
                      :style="{
                        color:
                          item.prop == 'wageIncome'
                            ? '#409EFF'
                            : item.prop == 'deductTotal'
                            ? '#F56C6C'
                            : item.prop == 'netPayroll'
                            ? '#67C23A'
                            : '',
                      }"
                    >
                      {{ scope.row[item.prop] }}
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="270" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  v-showFunction="{
                    active: 'issue',
                    array: allFunctionButtons,
                  }"
                  @click.native="handleClick(scope.row, 8)"
                  >发布</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  icon="el-icon-circle-close"
                  @click.native="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-payroll-setting-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as humanData from "./js/humanData";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      buttonLoading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        date: [],
        userList: [],
        userIdList: [],
        tempUserId: [],
        company: "",
        department: "",
        departTreeData: [],
      },
      defaultSort: { prop: "", order: "" },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanPayrollSetting"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      whetherAgencyHead: false,
      employeeList: [],
      tableMenu: humanData.getHumanPayrollSettingMenuData(),
      failFlag: false,
      i: 1,
      lastDate: "", // 上个月日期
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "humanPayrollSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门及部门以下的人员 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.employeeList.push(dd);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        name: this.searchData.input, // 姓名
        date: this.searchData.date, // 日期
        company: this.searchData.company, // 所在公司
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门
      };
      this.$request(
        "post",
        "/payroll/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag, prop) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 新增
          this.dialogData.width = "600px";
          this.dialogData.title = "新增";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "payroll_detail",
              data: humanData.getHumanDialogData(
                this.employeeList,
                this.tempData
              ),
            },
          ];
          console.log("新增dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 1:
          // 编辑
          this.dialogData.width = "600px";
          this.dialogData.title = "编辑";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "payroll_detail",
              data: humanData.getHumanDialogData(
                this.employeeList,
                this.tempData
              ),
            },
          ];
          console.log("编辑dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 2:
          // 删除
          requestData = {
            ids: [this.tempData.id],
          };
          this.$confirm("确定要删除该项数据吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/payroll/delPayroll",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 3:
          // 导入
          this.dialogData.width = "700px";
          this.dialogData.title = "导入工资单";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makeSure_import_human",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = humanData.importHumanDialogData(this);
          this.dialogData.flag = true;
          break;
        case 4:
          // 导出
          requestData = {
            currentPage: this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
            name: this.searchData.input, // 姓名
            date: this.searchData.date, // 日期
            userIds: this.searchData.tempUserId, // 人员
          };
          this.$message.success("正在导出, 请耐心等待!");
          window.location.href =
            this.$baseurl +
            "/payroll/exportExcel?data=" +
            JSON.stringify(requestData);
          break;
        case 5:
          // 业绩提成同步
          this.$message.success("正在同步, 请耐心等待!");
          this.buttonLoading = true;
          requestData = {
            companyId: this.baseInfo.company.companyId,
            date: this.lastDate, // 日期
          };
          this.$request(
            "post",
            "/contractPerformanceBackup/backupCopy",
            requestData,
            (data) => {
              this.$message.success("同步成功!");
              this.initTableData();
              this.buttonLoading = false;
            },
            (errorMessage) => {
              this.buttonLoading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case 6:
          if (prop == "businessCommission") {
            // 查看业务提成详情
            this.dialogData.width = "1300px";
            this.dialogData.title = "业务提成详情";
            this.dialogData.clientName = this.tempData.name;
            this.dialogData.buttonArray = [];
            this.dialogData.data = [
              {
                type: "table_payroll",
                data: {
                  searchData: [
                    {
                      type: "input",
                    },
                    {
                      type: "date",
                      disabled: true,
                    },
                  ],
                  requestData: {
                    userId: this.tempData.userId,
                    date: this.tempData.date,
                    clientName: "",
                  },
                  dateDisable: true,
                  url: "/contractPerformanceBackup/page",
                  tableMenuData: humanData.getHumanPerformanceMenuData(),
                  showSummary: true,
                  summary: [1, 2, 3, 9],
                },
              },
            ];
            console.log("dialog数据", this.dialogData);
            this.dialogData.flag = true;
          } else if (prop == "deliveryCommission") {
            // 查看绩效详情
            // 查看业务提成详情
            this.dialogData.width = "1300px";
            this.dialogData.title = "交付提成详情";
            this.dialogData.clientName = this.tempData.name;
            this.dialogData.buttonArray = [];
            this.dialogData.data = [
              {
                type: "table_payroll",
                data: {
                  searchData: [
                    {
                      type: "dataDate",
                      disabled: true,
                    },
                  ],
                  requestData: {
                    userId: this.tempData.userId,
                    dataDate: this.$formatTime(
                      new Date() - 62 * 24 * 3600 * 1000,
                      "YYYY-MM"
                    ),
                  },
                  url: "/contractCommission/pageStatisticsSomeone",
                  tableMenuData: humanData.getHumanContractMenuData(),
                  showSummary: true,
                  summary: [1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 15, 16, 18, 19],
                },
              },
            ];
            console.log("dialog数据", this.dialogData);
            this.dialogData.flag = true;
          }
          break;
        case 7:
          // 同步
          requestData = {
            companyId: this.baseInfo.company.companyId,
            userId: this.baseInfo.userId,
            currentPage: this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
            name: this.searchData.input, // 姓名
            date: this.searchData.date, // 日期
            company: this.searchData.company, // 所在公司
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "", // 部门
          };
          this.$request(
            "post",
            "/payroll/publishAll",
            requestData,
            (data) => {
              this.$message.success("同步成功!");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 8:
          requestData = {
            id: this.tempData.id,
            isPublish: 1,
          };
          this.$request(
            "post",
            "/payroll/updatePayroll",
            requestData,
            (data) => {
              this.$message.success("发布成功!");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          // 新增
          requestData = humanData.getRequestData(
            this.dialogData.data[0].data.rowData
          );
          requestData.userId = this.dialogData.data[0].data.name;
          requestData.date = this.dialogData.data[0].data.date;
          requestData.remark = this.dialogData.data[0].data.remark;
          if (!requestData.userId || !requestData.date) {
            this.$message.error("姓名, 月份为必填项!");
            return;
          }
          this.$request(
            "post",
            "/payroll/addPayroll",
            requestData,
            (data) => {
              this.$message.success("新增成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          // 编辑
          requestData = humanData.getRequestData(
            this.dialogData.data[0].data.rowData
          );
          requestData.userId = this.dialogData.data[0].data.name;
          requestData.id = this.dialogData.data[0].data.id;
          requestData.date = this.dialogData.data[0].data.date;
          requestData.remark = this.dialogData.data[0].data.remark;
          this.$request(
            "post",
            "/payroll/updatePayroll",
            requestData,
            (data) => {
              this.$message.success("修改成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_import_human":
          // 导入
          requestData = {
            date: this.dialogData.data[0].value,
            url: this.dialogData.data[1].value.url,
            companyId: this.baseInfo.company.companyId,
          };
          if (requestData.date == "") {
            this.$message.error("请先选择要导入的月份!");
            return;
          }
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          NProgress.start();
          this.pictLoading = true;
          this.$request(
            "post",
            "/payroll/importExcel",
            requestData,
            (data) => {
              // this.pictLoading = false;
              if (data.errData.length == 0) {
                this.$message.success(data.msg);
                this.dialogData.flag = false;
              } else {
                this.$message.warning(data.msg);
                this.failFlag = true;
                this.dialogData.title = "失败列表";
                this.dialogData.width = "1000px";
                this.dialogData.buttonArray = [];
                this.dialogData.data = [
                  {
                    type: "table",
                    height: "500",
                    data: data.errData,
                    props: [
                      {
                        label: "姓名",
                        prop: "name",
                      },
                      {
                        label: "基本工资",
                        prop: "basicPay",
                      },
                      {
                        label: "岗位工资",
                        prop: "postWage",
                      },
                      {
                        label: "绩效工资",
                        prop: "meritPay",
                      },
                      {
                        label: "业务提成",
                        prop: "businessCommission",
                      },
                      {
                        label: "业务提成 (增减) ",
                        prop: "businessCommissionIncOrDec",
                      },
                      {
                        label: "奖金",
                        prop: "bonus",
                      },
                      {
                        label: "津贴补助",
                        prop: "subsidy",
                      },
                      {
                        label: "考勤扣款",
                        prop: "attendanceDeduction",
                      },

                      {
                        label: "个税",
                        prop: "individualIncomeTax",
                      },
                      {
                        label: "五险代扣合计",
                        prop: "socialSecurityWithholding",
                      },
                      {
                        label: "扣分",
                        prop: "deductionOfPoints",
                      },
                      {
                        label: "所在公司",
                        prop: "company",
                        remark: "",
                      },
                    ],
                  },
                ];
                this.dialogData.flag = true;
              }
              this.pictLoading = false;
              NProgress.done();
              this.initTableData();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.initTableData();
              this.pictLoading = false;
              NProgress.done();
            }
          );
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      if (this.failFlag) {
        this.failFlag = false;
        this.initTableData();
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 客户地址导入成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "工资单导入成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[0].value.value = response.result;
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    rowClassName({ row, rowIndex }) {
      if (row.state == "0") {
        return "rowName";
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "合计";
          return;
        }
        if (
          index == 0 ||
          index == 2 ||
          index == 3 ||
          index == 18 ||
          index == 19 ||
          index == 20 ||
          index == 21
        ) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.lastDate = this.$formatTime(
      new Date() - 31 * 24 * 3600 * 1000,
      "YYYY-MM"
    );
    this.searchData.date[0] = this.$formatTime(
      new Date() - 31 * 24 * 3600 * 1000,
      "YYYY-MM"
    );
    this.searchData.date[1] = this.$formatTime(
      new Date() - 31 * 24 * 3600 * 1000,
      "YYYY-MM"
    );
  },
  mounted() {
    NProgress.configure({
      showSpinner: false,
      parent: "#human-payroll-setting .table-content",
    });
  },
};
</script>

<style lang="less" scoped>
#human-payroll-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .human-payroll-setting-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .human-payroll-setting-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .human-payroll-setting-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .human-payroll-setting-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .human-payroll-setting-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .human-payroll-setting-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .human-payroll-setting-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-payroll-setting-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .human-payroll-setting-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .human-payroll-setting-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .human-payroll-setting-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-payroll-setting-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-payroll-setting-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-payroll-setting-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .human-payroll-setting-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        /deep/.el-table .rowName {
          background: #fef0f0;
        }
      }
      .human-payroll-setting-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
