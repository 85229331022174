<template>
  <div id="table-payroll">
    <div class="table-payroll-search">
      <div class="table-payroll-search-line">
        <div
          class="table-payroll-search-line-item"
          v-for="(item, index) in data.searchData"
          :key="index"
        >
          <el-input
            v-if="item.type == 'input'"
            placeholder="请输入公司名称"
            v-model="data.requestData.clientName"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button
          ></el-input>
          <el-date-picker
            v-if="item.type == 'date'"
            v-model="data.requestData.date"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月"
            :clearable="false"
            @change="resetPage"
            :disabled="item.disabled"
          >
          </el-date-picker>
          <el-date-picker
            v-if="item.type == 'dataDate'"
            v-model="data.requestData.dataDate"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月"
            :clearable="false"
            @change="resetPage"
            :disabled="item.disabled"
          >
          </el-date-picker>
          <el-cascader
            style="margin-left: 10px"
            v-if="item.type == 'department'"
            v-model="data.requestData[item.key]"
            placeholder="请选择部门"
            :options="item.options"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
            @change="resetPage"
          ></el-cascader>
          <el-select
            style="margin-left: 10px"
            v-if="item.type == 'employeeList'"
            v-model="data.requestData[item.key]"
            placeholder="请选择客户负责人"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in item.options"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
          <el-select
            style="margin-left: 20px"
            v-if="item.type == 'select'"
            v-model="data.requestData[item.key]"
            placeholder="请选择"
            filterable
            clearable
            @change="resetPage"
          >
            <el-option
              v-for="item in item.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div>
      <el-table
        ref="tableData"
        class="tableData-content"
        :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        style="width:1300px"
        height="500"
        :row-style="{ height: '60px' }"
        v-loading="loading"
        border
        :show-summary="data.showSummary"
        :summary-method="summaryMethods"
      >
        <el-table-column type="index" width="50" fixed> </el-table-column>
        <el-table-column
          v-for="(item, index) in data.tableMenuData"
          :key="index"
          :property="item.prop"
          :fixed="item.fixed"
          :label="item.title"
          :width="item.prop == 'clientName' ? 170 : item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-if="
                item.prop == 'royaltyRate' ||
                  item.prop == 'chargePersonRatio' ||
                  item.prop == 'serviceConsultantRatio' ||
                  item.prop == 'sponsorAccountingRatio' ||
                  item.prop == 'accountManagerRatio' ||
                  item.prop == 'divisionHeadRatio'
              "
            >
              <span> {{ scope.row[item.prop] || 0 }}%</span>
            </div>
            <!-- 出访记录 -->
            <div v-else-if="item.prop == 'contactNumber'">
              <span
                v-if="
                  scope.row.registeredUserId == baseInfo.userId ||
                    whetherAgencyHead
                "
                >{{ scope.row.contactNumber }}</span
              >
              <span v-else>***********</span>
            </div>
            <div v-else-if="item.prop == 'duration'">
              <span v-if="scope.row.duration">
                {{ scope.row.duration + "小时" }}</span
              >
              <span v-else></span>
            </div>
            <div v-else-if="item.prop == 'status'">
              <span
                style="color: #F56C6C; cursor: pointer"
                v-if="scope.row.status == '未完成'"
                @click="showVisitDetail(scope.row)"
              >
                <i v-if="item.icon" class="el-icon-edit"></i
                >{{ scope.row.status }}</span
              >
              <span
                style="color: #67C23A; cursor: pointer"
                v-if="scope.row.status == '已完成'"
                @click="showVisitDetail(scope.row)"
              >
                <i v-if="item.icon" class="el-icon-edit"></i
                >{{ scope.row.status }}</span
              >
            </div>
            <div v-else-if="item.prop == 'operation'">
              <span v-for="it in item.list" :key="it.active">
                <el-button
                  v-if="data.model == 'task' && scope.row.status !== '已完成'"
                  :type="it.type"
                  :plain="it.plain"
                  :size="it.size"
                  :icon="it.icon"
                  @click="handleClick(scope.row, scope.$index, it.active)"
                  >{{ it.name }}</el-button
                >
              </span>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    allFunctionButtons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseInfo: {},
      loading: false,
      clientName: "",
      date: "",
      tempData: {},
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },

  methods: {
    initTableData() {
      this.loading = true;
      let requestData = {
        ...this.data.requestData,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      if (requestData.department) {
        console.log("requestData", requestData);
        requestData.department =
          requestData.department.length > 0
            ? requestData.department[requestData.department.length - 1]
            : "";
      }
      this.$request(
        "post",
        this.data.url,
        requestData,
        (data) => {
          this.tableData = data.records;
          if (this.data.url == "/visitRegistration/page") {
            this.tableData.forEach((item) => {
              if (item.details) {
                item.details = JSON.parse(item.details);
              }
            });
          }
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 表格合计计算
     */
    summaryMethods(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        console.log("column", column);
        if (index == 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "money" ||
          column.property == "cost" ||
          column.property == "paymentCollect" ||
          column.property == "amountsOw" ||
          column.property == "profits"
        ) {
          sums[index] = "";
          return;
        }
        for (let i = 0; i < this.data.summary.length; i++) {
          const element = this.data.summary[i];
          if (index == element) {
            sums[index] = "";
            return;
          }
        }
        const values = data.map((item) => Number(item[column.property]));
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
          if (!isNaN(value)) {
            return sum;
          } else {
            return prev;
          }
        }, 0);
        sums[index];
      });
      return sums;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    lazyLoading() {
      // 获取目标滚动元素
      let dom = this.$refs.tableData.bodyWrapper;
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // console.log("鼠标滑动-scrollDistance", scrollDistance);
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.tableData.length >= this.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          this.loading = true;
          let requestData = {
            ...this.data.requestData,
            currentPage: ++this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
          };
          if (requestData.department) {
            console.log("requestData", requestData);
            requestData.department =
              requestData.department.length > 0
                ? requestData.department[requestData.department.length - 1]
                : "";
          }
          this.$request(
            "post",
            this.data.url,
            requestData,
            (data) => {
              // 获取的数据追加到原来的数据中
              this.tableData = [...this.tableData, ...data.records];
              // this.waiteDataList = data.records;
              this.pageData.total = data.total;
              this.loading = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        }
      });
    },
    handleClick(row, index, active) {
      console.log("row", row);
      console.log("index", index);
      let requestData = {};
      this.tempData = row;
      switch (active) {
        case "complete":
          // 任务模块 完成任务
          this.$confirm("确认完成该日程吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                scheduleId: this.tempData.scheduleId,
                userId: this.baseInfo.userId,
                clientId: this.tempData.clientId,
                remarks: "",
              };
              if (this.tempData.userId !== this.baseInfo.userId) {
                return this.$message.error(
                  "当前用户只能完成自己的日程, 请勿操作!"
                );
              }
              this.$request(
                "post",
                "/scheduleComplete/add",
                requestData,
                (data) => {
                  this.$set(this.tableData[index], "status", "已完成");
                  this.$message.success("完成成功!");
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    showVisitDetail(row) {
      this.$emit("showVisitDetail", row);
    },
    selectInitTableData(val) {
      if (val == "all") {
        this.data.requestData.userIds = this.data.optionsList.tempAllEmpolyeeList;
      } else {
        this.data.requestData.userIds = [val];
      }
      this.resetPage();
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.initTableData();
    this.lazyLoading();
    console.log("data", JSON.parse(JSON.stringify(this.data)));
    console.log(
      "allFunctionButtons",
      JSON.parse(JSON.stringify(this.allFunctionButtons))
    );
  },
};
</script>

<style lang="less" scoped>
#table-payroll {
  height: 80%;
  display: flex;
  flex-direction: column;
  .table-payroll-search {
    height: 40px;
    display: flex;
    .table-payroll-search-line {
      width: 100%;
      margin-right: 5px;
      display: flex;
      align-items: center;
      .table-payroll-search-line-item {
        width: 250px;
      }
    }
  }
  .tableData-content {
    /deep/ .el-table {
      overflow: visible !important;
    }

    /deep/ .el-table__body-wrapper {
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .table-tip {
      height: 30px;
      width: auto;
      padding: 2px 5px;
      margin: 0 3px;
      border: 1px solid #333;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .social-security-table-card-bottom {
    width: 95%;
    height: 40px;
    border-top: 1px solid @boderColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
