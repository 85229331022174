/**
 * 功能按钮  保证和数据库中功能按钮一致
 */
let featureData = [{
        model: "clientInfo", // 客户资料
        feature: [
            { name: "新增", key: "create", authority: 1, id: "" },
            { name: "导入", key: "importRole", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "跟进", key: "followUp", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "申请流失", key: "lossOf", authority: 1, id: "" },
            { name: "添加关联客户", key: "associat", authority: 1, id: "" },
            { name: "新增联系人", key: "createContact", authority: 1, id: "" },
            { name: "删除联系人", key: "deleteContact", authority: 1, id: "" },
            { name: "编辑联系人", key: "editContact", authority: 1, id: "" },
            { name: "新增合同", key: "createContract", authority: 1, id: "" },
            { name: "查看详情", key: "detailsContract", authority: 1, id: "" },
            { name: "删除档案", key: "deleteArchives", authority: 1, id: "" },
            { name: "流失恢复", key: "recover", authority: 1, id: "" },
            { name: "新增档案", key: "addArchives", authority: 1, id: "" },
            { name: "云盘-上传文件", key: "addFile", authority: 1, id: "" },
            { name: "云盘-新建文件夹", key: "addFolder", authority: 1, id: "" },
            { name: "云盘-重命名", key: "reNameFile", authority: 1, id: "" },
            { name: "云盘-移动", key: "moveFile", authority: 1, id: "" },
            { name: "云盘-删除", key: "deleteFile", authority: 1, id: "" },
            { name: "云盘-下载", key: "downloadFile", authority: 1, id: "" },
            { name: "云盘-客户是否可见", key: "visibleFile", authority: 1, id: "" },
        ]
    }, {
        model: "clientConnect", // 工作交接(客户)
        feature: [
            { name: "分配", key: "assign", authority: 1, id: "" }
        ]
    }, {
        model: "clientCheck", // 流失审核(客户)
        feature: [
            { name: "审核", key: "check", authority: 1, id: "" },
            { name: "驳回", key: "reject", authority: 1, id: "" }
        ]
    }, {
        model: "clientSetting", // 设置(客户)
        feature: [
            // { name: "编辑选项值", key: "editOption", authority: 1, id: "" },
            { name: "选项值设置", key: "settingOption", authority: 1, id: "" },
            { name: "客户编号设置", key: "clientNumber", authority: 1, id: "" },
            { name: "绑定企业微信客户", key: "settingweixin", authority: 1, id: "" },
            { name: "开通移动端", key: "settingMobile", authority: 1, id: "" },
            { name: "添加选项", key: "addOption", authority: 1, id: "" },
            { name: "添加来源", key: "addSource", authority: 1, id: "" },
            { name: "绑定客户", key: "bindingClient", authority: 1, id: "" },
            { name: "绑定群", key: "bindingGroup", authority: 1, id: "" },
            { name: "客户解绑", key: "removeClient", authority: 1, id: "" },
            { name: "群解绑", key: "removeGroup", authority: 1, id: "" },
            { name: "开通", key: "open", authority: 1, id: "" },
            { name: "添加客户编号", key: "addClientNumber", authority: 1, id: "" },
            { name: "禁用客户编号", key: "disableClientNumber", authority: 1, id: "" },
            { name: "启用客户编号", key: "enableClientNumber", authority: 1, id: "" },
            { name: "删除客户编号", key: "deleteClientNumber", authority: 1, id: "" },
        ]
    }, {
        model: "myResources", // 我的资源(商机)
        feature: [
            { name: "跟进", key: "followUp", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "批量删除", key: "deleteBatch", authority: 1, id: "" },
            { name: "分配", key: "allocation", authority: 1, id: "" },
            { name: "导入", key: "import", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "新建", key: "create", authority: 1, id: "" },
        ]
    }, {
        model: "myThread", // 我的商机(商机)
        feature: [
            { name: "新增", key: "create", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "跟进", key: "followUp", authority: 1, id: "" },
            { name: "转成交", key: "turnover", authority: 1, id: "" },
            { name: "转为共享商机", key: "convertToShare", authority: 1, id: "" },
            { name: "置为无效", key: "invalid", authority: 1, id: "" },
            { name: "置入公海", key: "openSea", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
        ]
    }, {
        model: "allThread", // 全部商机(商机)
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "跟进", key: "followUp", authority: 1, id: "" },
            { name: "转成交", key: "turnover", authority: 1, id: "" },
            { name: "置为无效", key: "invalid", authority: 1, id: "" },
            { name: "置入公海", key: "openSea", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    }, 
    {
        model: "weixinResources", // 微信待加
        feature: [
            { name: "完成", key: "complete", authority: 1, id: "" },
           
        ]
    },{
        model: "clientThread", // 已签约客户(商机)
        feature: [
            { name: "调户", key: "adjustableIndoor", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
        ]
    }, {
        model: "threadPool", // 商机池(商机)
        feature: [
            { name: "导入", key: "import", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "分配", key: "distribution", authority: 1, id: "" },
            { name: "恢复", key: "restore", authority: 1, id: "" },
            { name: "置为无效", key: "invalid", authority: 1, id: "" },
            { name: "置入公海", key: "openSea", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    }, 
    {
        model: "threadShare", // 共享商机
        feature: []
    }, 
    {
        model: "threadRecycle", // 回收站(商机)
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "还原", key: "reduction", authority: 1, id: "" },
            { name: "彻底删除", key: "shiftDelete", authority: 1, id: "" },
        ]
    }, {
        model: "threadVisit", // 出访记录
        feature: [
            { name: "新增", key: "add", authority: 3, id: "" },
            { name: "删除", key: "del", authority: 3, id: "" },
            { name: "导出", key: "export", authority: 3, id: "" },
            { name: "导出详情", key: "exportDetails", authority: 3, id: "" },
        ]
    }, {
        model: "threadSetting", // 设置(商机)
        feature: [
            { name: "编辑选项值", key: "editOption", authority: 3, id: "" },
            { name: "编辑商机保护", key: "editProtect", authority: 3, id: "" },
            { name: "添加异常电话", key: "addPhone", authority: 1, id: "" },
            { name: "编辑异常电话", key: "editPhone", authority: 1, id: "" },
            { name: "导入异常电话", key: "importPhone", authority: 1, id: "" },
            { name: "导出异常电话", key: "exportPhone", authority: 1, id: "" },
            { name: "彻底删除异常电话", key: "deletePhone", authority: 1, id: "" },
            { name: "选项值", key: "showOption", authority: 1, id: "" },
            { name: "异常电话", key: "showPhone", authority: 1, id: "" },
            { name: "商机保护", key: "showProtect", authority: 1, id: "" },
            { name: "出访设置", key: "showVisit", authority: 1, id: "" },
        ]
    }, {
        model: "contractInfo", // 全部合同(合同)
        feature: [
            { name: "新增", key: "create", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "客户跟进", key: "followUp", authority: 1, id: "" },
            { name: "查账/回款", key: "receivable", authority: 1, id: "" },
            { name: "发票申领", key: "makeInvoice", authority: 1, id: "" },
            { name: "下单", key: "orders", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "电子合同", key: "electronicContract", authority: 1, id: "" },
            { name: "删除合同", key: "delete", authority: 1, id: "" },
            { name: "手动结束", key: "finish", authority: 1, id: "" },
            { name: "查看客户", key: "showClient", authority: 1, id: "" },
            { name: "查看收款记录", key: "showRecords", authority: 1, id: "" },
            { name: "查看收款附件", key: "showPaymentAttachment", authority: 1, id: "" },
            { name: "查看收费记录", key: "showChargeIs", authority: 1, id: "" },
            { name: "查看支出记录", key: "showSpend", authority: 1, id: "" },
            { name: "查看支出附件", key: "showSpendAttachment", authority: 1, id: "" },
            { name: "添加支出", key: "addSpend", authority: 1, id: "" },
            { name: "查看合同附件", key: "showAttachment", authority: 1, id: "" },
            { name: "添加附件", key: "addAttachment", authority: 1, id: "" },
            { name: "删除附件", key: "deleteAttachment", authority: 1, id: "" },
            { name: "查看合同发票", key: "showContractInvoice", authority: 1, id: "" },
            { name: "退款", key: "refund", authority: 1, id: "" },
            { name: "修改发票信息", key: "editInvoiceInfo", authority: 1, id: "" },
        ]
    }, {
        model: "contractCheck", // 合同审核(合同)
        feature: [
            { name: "合同审核", key: "checkInfo", authority: 1, id: "" },
            { name: "财务审核", key: "checkMoney", authority: 1, id: "" },
            { name: "查账", key: "audit", authority: 1, id: "" },
            { name: "彻底删除", key: "shiftDelete", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "电子合同", key: "electronicContract", authority: 1, id: "" },
            { name: "删除合同", key: "delete", authority: 1, id: "" },
            { name: "手动结束", key: "finish", authority: 1, id: "" },
            { name: "查看客户", key: "showClient", authority: 1, id: "" },
            { name: "查看收款记录", key: "showRecords", authority: 1, id: "" },
            { name: "查看收款附件", key: "showPaymentAttachment", authority: 1, id: "" },
            { name: "查看收费记录", key: "showChargeIs", authority: 1, id: "" },
            { name: "查看支出记录", key: "showSpend", authority: 1, id: "" },
            { name: "查看支出附件", key: "showSpendAttachment", authority: 1, id: "" },
            { name: "添加支出", key: "addSpend", authority: 1, id: "" },
            { name: "查看合同附件", key: "showAttachment", authority: 1, id: "" },
            { name: "添加附件", key: "addAttachment", authority: 1, id: "" },
            { name: "删除附件", key: "deleteAttachment", authority: 1, id: "" },
        ]
    }, {
        model: "ContractNote", // 记单合同(合同)
        feature: [
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "记单修改", key: "edit", authority: 1, id: "" },
        ]
    }, {
        model: "ContractPayment", // 财务收款(合同)
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "详情", key: "details", authority: 1, id: "" },
            { name: "导入", key: "import", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "企业微信同步", key: "synchronous", authority: 1, id: "" },
            { name: "添加收款单", key: "add", authority: 1, id: "" },
            { name: "退款", key: "refund", authority: 1, id: "" },
        ]
    }, 
    {
        model: "contractWork", // 合同派工(合同)
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "角色权限", key: "roleAuthority", authority: 1, id: "" },
        ]
    }, 
    {
        model: "contractRenew", // 续签管理(合同)
        feature: [ 
            { name: "续签", key: "renew", authority: 1, id: "" },
            { name: "结束续签", key: "end", authority: 1, id: "" },
        ]
    }, 
    {
        model: "ContractInvoice", // 合同发票(合同)
        feature: [
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "开票", key: "makeOutInvoice", authority: 1, id: "" },
            { name: "作废", key: "cancellation", authority: 1, id: "" },
            { name: "红冲", key: "red", authority: 1, id: "" },
            { name: "下载PDF", key: "pdf", authority: 1, id: "" },
            { name: "下载OFD", key: "ofd", authority: 1, id: "" },
            { name: "推送开具", key: "push", authority: 1, id: "" },
            { name: "拆分推送", key: "splitPush", authority: 1, id: "" },
        ]
    },
    {
        model: "contractSetting", // 设置(合同)
        feature: [
            { name: "编辑待办流程", key: "editProcess", authority: 1, id: "" },
            { name: "新增待办流程", key: "addProcess", authority: 1, id: "" },
            { name: "删除待办流程", key: "deleteProcess", authority: 1, id: "" },
            { name: "编辑签约主体", key: "editContractSubject", authority: 1, id: "" },
            { name: "新增签约主体", key: "addContractSubject", authority: 1, id: "" },
            { name: "删除签约主体", key: "deleteContractSubject", authority: 1, id: "" },
            { name: "显示签约主体", key: "showSignInfo", authority: 1, id: "" },
            { name: "显示待办流程", key: "showWaitProcess", authority: 1, id: "" },
        ]
    }, {
        model: "invoiceInfo", // 发票开具(发票)
        feature: [
            { name: "开票", key: "create", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    }, {
        model: "InvoiceCheck", // 发票审核(发票)
        feature: [
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "开票", key: "makeOutInvoice", authority: 1, id: "" },
            { name: "作废", key: "cancellation", authority: 1, id: "" },
            { name: "红冲", key: "red", authority: 1, id: "" },
            { name: "下载PDF", key: "pdf", authority: 1, id: "" },
            { name: "下载OFD", key: "ofd", authority: 1, id: "" },
            { name: "下载xml", key: "xml", authority: 1, id: "" },
            { name: "推送开具", key: "push", authority: 1, id: "" },
            { name: "批量推送", key: "pushBatch", authority: 1, id: "" },
        ]
    }, {
        model: "theirTickets", // 领票设置(发票)
        feature: [
            { name: "设置", key: "setting", authority: 1, id: "" },
            // { name: "测试", key: "test", authority: 1, id: "" },
        ]
    },{
        model: "invoiceAccount", // 数电账号
        feature: [
            { name: "修改", key: "edit", authority: 1, id: "" },
            { name: "批量修改", key: "editBatch", authority: 1, id: "" },
            { name: "清除", key: "delete", authority: 1, id: "" },
            { name: "批量清除", key: "deleteBatch", authority: 1, id: "" },
        ]
    }, {
        model: "invoiceRegister", // 理票登记(发票)
        feature: [
            { name: "理票设置", key: "setting", authority: 1, id: "" },
            { name: "理票打印", key: "print", authority: 1, id: "" },
        ]
    }, {
        model: "InvoiceGather", // 进销项采集(发票)
        feature: [
            { name: "录票", key: "create", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
        ]
    }, {
        model: "InvoiceSetting", // 客户设置(发票)
        feature: [
            { name: "发票备注", key: "invoiceRemark", authority: 1, id: "" },
            { name: "开票人设置", key: "drawerSetting", authority: 1, id: "" },
            { name: "新增常用客户", key: "addCommonCustomer", authority: 1, id: "" },
            { name: "导出常用客户", key: "exportCommonCustomer", authority: 1, id: "" },
            { name: "编辑常用客户", key: "editCommonCustomer", authority: 1, id: "" },
            { name: "删除常用客户", key: "deleteCommonCustomer", authority: 1, id: "" },
        ]
    }, {
        model: "CommoditySetting", // 常用商品设置(发票)
        feature: [
            { name: "新增常用客户", key: "addCommonCustomer", authority: 1, id: "" },
            { name: "导出常用客户", key: "exportCommonCustomer", authority: 1, id: "" },
            { name: "编辑常用客户", key: "editCommonCustomer", authority: 1, id: "" },
            { name: "删除常用客户", key: "deleteCommonCustomer", authority: 1, id: "" },
        ]
    }, {
        model: "productList", // 产品组合/合同模板设置(产品)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "停用", key: "stop", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    }, {
        model: "serveItem", // 服务项管理(产品)
        feature: [
            { name: "新增服务类型", key: "addType", authority: 1, id: "" },
            { name: "编辑服务类型", key: "editType", authority: 1, id: "" },
            { name: "删除服务类型", key: "deleteType", authority: 1, id: "" },
            { name: "新增服务项", key: "add", authority: 1, id: "" },
            { name: "编辑服务项", key: "edit", authority: 1, id: "" },
            { name: "删除服务项", key: "delete", authority: 1, id: "" },
        ]
    }, {
        model: "knowledgeBase", // 知识库(知识)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    },{
        model: "knowledgeCollege", // 企学院(知识)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "开始考试", key: "startExam", authority: 1, id: "" },
        ]
    },{
        model: "knowledgePaper", // 考试试卷(知识)
        feature: [
            { name: "开始考试", key: "startExam", authority: 1, id: "" },
        ]
    },{
        model: "knowledgeRecord", // 考试记录(知识)
        feature: [
            { name: "查看试卷", key: "look", authority: 1, id: "" },
        ]
    },{
        model: "knowledgeRecordAll", // 考试记录(全部可见)(知识)
        feature: [
            { name: "查看试卷", key: "look", authority: 1, id: "" },
        ]
    },{
        model: "knowledgeExamination", // 考试题库(知识)
        feature: [
            { name: "新增试题", key: "addQuestion", authority: 1, id: "" },
            { name: "编辑试题", key: "editQuestion", authority: 1, id: "" },
            { name: "删除试题", key: "deleteQuestion", authority: 1, id: "" },
            { name: "新增试卷", key: "addTestPaper", authority: 1, id: "" },
            { name: "编辑试卷", key: "editTestPaper", authority: 1, id: "" },
            { name: "发布试卷", key: "issueTestPaper", authority: 1, id: "" },
        ]
    },{
        model: "publicNotice", // 公告(知识)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    }, {
        model: "knowledgeSetting", // 设置(知识库)
        feature: [
            { name: "选项值设置", key: "add", authority: 1, id: "" },
        ]
    }, {
        model: "company", // 机构设置(系统设置)
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "添加收款账号", key: "addPaymentAccount", authority: 1, id: "" },
            { name: "编辑收款账号", key: "editPaymentAccount", authority: 1, id: "" },
            { name: "删除收款账号", key: "deletePaymentAccount", authority: 1, id: "" },
        ]
    }, 
    {
        model: "xcxSwiper",  // 小程序-轮播图
        feature: [
            { name: "添加", key: "add", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
        ]
    }, 
    {
        model: "xcxPolicy", // 小程序-最新政策
        feature: [
            { name: "添加", key: "add", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
        ]
    }, 
    {
        model: "xcxService", // 小程序-更多服务
        feature: [
            { name: "添加", key: "add", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
        ]
    },
   {
        model: "department", // 部门设置(系统设置)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "查看权限", key: "showRole", authority: 1, id: "" },
            { name: "编辑权限", key: "editRole", authority: 1, id: "" },
            { name: "添加业务权限", key: "addRole", authority: 1, id: "" },
            { name: "删除业务权限", key: "deleteRole", authority: 1, id: "" },
            { name: "编辑业务角色", key: "editBusinessRole", authority: 1, id: "" },
        ]
    }, {
        model: "employee", // 员工设置(系统设置)
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "修改记单点", key: "updateRecorder", authority: 1, id: "" },
            { name: "离职并交接", key: "departure", authority: 1, id: "" },
            { name: "绑定企业微信", key: "bind", authority: 1, id: "" },
            { name: "修改密码", key: "updatePsw", authority: 1, id: "" },
            { name: "外呼设置", key: "settingPhone", authority: 1, id: "" },
        ]
    }, {
        model: "myReimburse", // 我的报销(报销)
        feature: [
            { name: "新建报销", key: "addReimburse", authority: 1, id: "" },
            { name: "重新提交", key: "resubmitReimburse", authority: 1, id: "" },
            { name: "撤销", key: "cancelReimburse", authority: 1, id: "" },
            { name: "查看详情", key: "reimburseInfo", authority: 1, id: "" },
            { name: "删除报销", key: "delReimburse", authority: 1, id: "" },
            { name: "导入报销", key: "importReimburse", authority: 1, id: "" },
            { name: "导出报销", key: "reportReimburse", authority: 1, id: "" },
            { name: "付款", key: "payment", authority: 1, id: "" },
        ]
    },
    {
        model: "allReimburse", // 全部报销(报销)
        feature: [
            { name: "查看详情", key: "reimburseInfo", authority: 1, id: "" },
            { name: "删除报销", key: "delReimburse", authority: 1, id: "" },
            { name: "导出报销", key: "reportReimburse", authority: 1, id: "" },
        ]
    },
    {
        model: "reimburseExamine", // 我的审批(报销)
        feature: [
            { name: "查看详情", key: "reimburseInfo", authority: 1, id: "" },
            { name: "报销审批", key: "examine", authority: 1, id: "" },
            { name: "报销付款", key: "pay", authority: 1, id: "" },
            { name: "导出报销", key: "reportReimburse", authority: 1, id: "" },
            { name: "编辑支出信息", key: "editExpenditure", authority: 1, id: "" },
        ]
    },
    {
        model: "reimburseSetting", // 设置(报销)
        feature: [
            { name: "编辑选项值", key: "editOption", authority: 3, id: "" },
            { name: "选项值", key: "showOption", authority: 3, id: "" },
            { name: "报销流程", key: "process", authority: 3, id: "" },
            { name: "编辑报销流程", key: "editProcess", authority: 3, id: "" },
        ]
    },
    {
        model: "archivesInfo", // 客户档案
        feature: [
            { name: "新增档案", key: "add", authority: 1, id: "" },
            { name: "领用", key: "get", authority: 1, id: "" },
            { name: "存入", key: "save", authority: 1, id: "" },
            { name: "转移", key: "move", authority: 1, id: "" },
            { name: "归还", key: "return", authority: 1, id: "" },
            { name: "批量领用", key: "getBatch", authority: 1, id: "" },
            { name: "批量存入", key: "saveBatch", authority: 1, id: "" },
            { name: "批量转移", key: "moveBatch", authority: 1, id: "" },
            { name: "批量归还", key: "returnBatch", authority: 1, id: "" },
            { name: "操作记录", key: "record", authority: 1, id: "" },
        ]
    },
    {
        model: "archivesSetting", // 档案设置
        feature: [
            { name: "添加选项", key: "add", authority: 1, id: "" },
            { name: "添加子节点", key: "addChildren", authority: 1, id: "" },
        ]
    },
    {
        model: "expressageInfo", // 寄件管理
        feature: [
            { name: "寄件", key: "order", authority: 1, id: "" },
            { name: "取消", key: "cancel", authority: 1, id: "" },
            { name: "查询", key: "query", authority: 1, id: "" },
            { name: "支付", key: "pay", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
        ]
    },
    {
        model: "expressageMaintain", // 地址维护
        feature: [ 
            { name: "新增地址", key: "addAddress", authority: 1, id: "" },
            { name: "导入地址", key: "import", authority: 1, id: "" },
            { name: "修改", key: "modification", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
        ]
    },
    {
        model: "expressageAccount", // 我的账户 
        feature: [
            { name: "充值", key: "recharge", authority: 1, id: "" },
            { name: "开发票", key: "invoice", authority: 1, id: "" },
        ]
    }, 
    {
        model: "myAssignment", // 我的任务
        feature: [
            { name: "完成", key: "done", authority: 1, id: "" },
            { name: "详情", key: "detail", authority: 1, id: "" },
        ]
    }, 
    {  
        model: "myDispatch", // 我的派工
        feature: [
            { name: "自定义派工", key: "customDispatch", authority: 1, id: "" },
            { name: "撤销", key: "cancel", authority: 1, id: "" },
        ]
    }, 
    {
        model: "dispatchPool", // 派工池
        feature: [
            { name: "批量派工", key: "batchDispatch", authority: 1, id: "" },
            { name: "派工", key: "dispatch", authority: 1, id: "" },
        ]
    }, 
    {
        model: "dispatchContract", // 合同派工
        feature: [
        ]
    }, 
    {
        model: "dispatchSocial", // 社保派工
        feature: [
            { name: "审核", key: "check", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "查看详情", key: "detail", authority: 1, id: "" },
        ]
    }, 
    {
        model: "dispatchSetting", // 派工设置
        feature: [
            { name: "添加选项", key: "add", authority: 1, id: "" },
            { name: "添加子节点", key: "addChildren", authority: 1, id: "" },
            { name: "地址详情", key: "detail", authority: 1, id: "" },
        ]
    },
    {
        model: "reportCall", // 话单列表
        feature: [
        ]
    }, 
    {
        model: "reportDay", // 日账单
        feature: [
        ]
    }, 
    {
        model: "reportInvoice", // 发票报表
        feature: [
            { name: "开票", key: "takeInvoice", authority: 1, id: "" },
        ]
    }, 
    {
        model: "taxInfo", // 税务统计
        feature: [
            { name: "导入", key: "import", authority: 1, id: "" },
        ]
    }, 
    {
        model: "socialSecurity", // 社保统计
        feature: [
            { name: "增员", key: "add", authority: 1, id: "" },
            { name: "减员", key: "delete", authority: 1, id: "" },
            { name: "导入", key: "import", authority: 1, id: "" },
            { name: "操作记录", key: "record", authority: 1, id: "" },
        ]
    }, 
    {
        model: "socialCheck", // 社保审核
        feature: [
            { name: "审核", key: "check", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "查看详情", key: "detail", authority: 1, id: "" },
        ]
    }, 
    {
        model: "taxStatement", // 企业流水
        feature: [
            { name: "导出", key: "export", authority: 1, id: ""},
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "设置", key: "setting", authority: 1, id: "" },
        ]
    }, 
    {
        model: "taxPark", // 企业园区
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "设置", key: "setting", authority: 1, id: "" },
        ]
    }, 
    {
        model: "taxCost", // 财务数据
        feature: [
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "记账确认", key: "chargeUpConfirm", authority: 1, id: "" },
            { name: "审核", key: "check", authority: 1, id: "" },
            { name: "导入", key: "import", authority: 1, id: "" },
        ]
    }, 
    {
        model: "humanPayrollSetting", // 工资单设置
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "导入", key: "import", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "业务提成同步", key: "backupCopy", authority: 1, id: "" },
            { name: "发布", key: "issue", authority: 1, id: "" },
            { name: "批量发布", key: "batchIssue", authority: 1, id: "" },
        ]
    }, 
    {
        model: "humanPayroll", // 工资单
        feature: []
    }, 
    {
        model: "humanNote", // 记单合同
        feature: [
            { name: "导出", key: "export", authority: 1, id: "" },
            { name: "记单修改", key: "edit", authority: 1, id: "" },
        ]
    }, 
    {
        model: "humanPerformance", // 业绩提成表
        feature: [
            { name: "修改", key: "edit", authority: 1, id: "" },
            { name: "批量修改", key: "batchEdit", authority: 1, id: "" },
            { name: "导出", key: "export", authority: 1, id: "" },
        ]
    }, 
    {
        model: "humanDispatch", // 工商提成表
        feature: [
            { name: "修改", key: "edit", authority: 1, id: "" },
            { name: "批量修改", key: "batchEdit", authority: 1, id: "" },
        ]
    }, 
    {
        model: "humanContract", // 交付提成表
        feature: [
            { name: "修改", key: "edit", authority: 1, id: "" },
            { name: "同步", key: "synchronization", authority: 1, id: "" },
        ]
    }, 
    {
        model: "taskInfo", // 日程列表
        feature: [
            { name: "新增", key: "add", authority: 1, id: "" },
            { name: "编辑", key: "edit", authority: 1, id: "" },
            { name: "完结", key: "end", authority: 1, id: "" },
            { name: "删除", key: "delete", authority: 1, id: "" },
            { name: "查看", key: "look", authority: 1, id: "" },
            { name: "完成", key: "finish", authority: 1, id: "" },
        ]
    }, 
]

/**
 * 属性  保证和数据库中属性一致
 */
let attributeData = [{
    model: "myResources", // 我的资源(商机)
    attribute: [
        { name: "客户全称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 2, id: "" },
        { name: "跟进人",  key: "createName", authority: 1, sequence: 3, id: "" },
        { name: "联系人", width: '50px', key: "contact", authority: 1, sequence: 4, id: "" },
        { name: "手机",  key: "phone", authority: 1, sequence: 5, id: "" },
        { name: "地址", width: '120px', key: "address", authority: 1, sequence: 6, id: "" },
        { name: "备注", key: "remark", authority: 1, sequence: 7, id: "" },
    ]
}, {
    model: "myThread", // 我的商机(商机)
    attribute: [
        { name: "商机名称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "跟进联系人", key: "followName", authority: 1, sequence: 2, id: "" },
        { name: "商机状态", key: "threadStatus", authority: 1, sequence: 3, id: "" },
        { name: "跟进阶段", key: "followStage", authority: 1, sequence: 4, id: "" },
        { name: "商机创建人", key: "createName", authority: 1, sequence: 5, id: "" },
        { name: "商机提交人", key: "submitName", authority: 1, sequence: 6, id: "" },
        { name: "客户来源", key: "customerSource", authority: 1, sequence: 7, id: "" },
        { name: "增值税类型", key: "addedValueTaxType", authority: 1, sequence: 8, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 9, id: "" },
        { name: "意向产品", key: "intentionalProduct", authority: 1, sequence: 10, id: "" },
        { name: "上次联系时间", key: "lastContactTime", authority: 1, sequence: 11, id: "" },
        { name: "下次联系时间", key: "nextContactTime", authority: 1, sequence: 12, id: "" },
        { name: "最近分配时间", key: "allocateTime", authority: 1, sequence: 13, id: "" },
        { name: "跟进记录", key: "followUpRecords", authority: 1, sequence: 14, id: "" },
        { name: "保护时间", key: "protectTime", authority: 1, sequence: 15, id: "" },
        { name: "联系人", key: "contact", authority: 1, sequence: 16, id: "" },
        { name: "手机", key: "phone", authority: 1, sequence: 17, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: -1, id: "" },
        { name: "地区", key: "area", authority: 1, sequence: -1, id: "" },
        { name: "详细地址", key: "address", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "企业税号", key: "enterpriseNumber", authority: 1, sequence: -1, id: "" },
        { name: "联系电话", key: "tel", authority: 1, sequence: -1, id: "" },
        { name: "开户行", key: "bankName", authority: 1, sequence: -1, id: "" },
        { name: "银行账号", key: "bankAccount", authority: 1, sequence: -1, id: "" },
        { name: "商机共享人", key: "sharedUserId", authority: 1, sequence: -1, id: "" },
        { name: "代账到期时间", key: "accountDueTime", authority: 1, sequence: 3, id: "" },
    ]
}, {
    model: "allThread", // 全部商机(商机)
    attribute: [
        { name: "商机名称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "跟进联系人", key: "followName", authority: 1, sequence: 2, id: "" },
        { name: "商机状态", key: "threadStatus", authority: 1, sequence: 3, id: "" },
        { name: "跟进阶段", key: "followStage", authority: 1, sequence: 4, id: "" },
        { name: "商机创建人", key: "createName", authority: 1, sequence: 5, id: "" },
        { name: "商机提交人", key: "submitName", authority: 1, sequence: 6, id: "" },
        { name: "客户来源", key: "customerSource", authority: 1, sequence: 7, id: "" },
        { name: "增值税类型", key: "addedValueTaxType", authority: 1, sequence: 8, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 9, id: "" },
        { name: "意向产品", key: "intentionalProduct", authority: 1, sequence: 10, id: "" },
        { name: "上次联系时间", key: "lastContactTime", authority: 1, sequence: 11, id: "" },
        { name: "下次联系时间", key: "nextContactTime", authority: 1, sequence: 12, id: "" },
        { name: "最近分配时间", key: "allocateTime", authority: 1, sequence: 13, id: "" },
        { name: "跟进记录", key: "followUpRecords", authority: 1, sequence: 14, id: "" },
        { name: "保护时间", key: "protectTime", authority: 1, sequence: 15, id: "" },
        { name: "联系人", key: "contact", authority: 1, sequence: 16, id: "" },
        { name: "手机", key: "phone", authority: 1, sequence: 17, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: -1, id: "" },
        { name: "地区", key: "area", authority: 1, sequence: -1, id: "" },
        { name: "详细地址", key: "address", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "企业税号", key: "enterpriseNumber", authority: 1, sequence: -1, id: "" },
        { name: "联系电话", key: "tel", authority: 1, sequence: -1, id: "" },
        { name: "开户行", key: "bankName", authority: 1, sequence: -1, id: "" },
        { name: "银行账号", key: "bankAccount", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "clientThread", // 已签约客户(商机)
    attribute: [
        { name: "客户全称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "状态", key: "threadStatus", authority: 1, sequence: 2, id: "" },
        { name: "创建人", key: "createName", authority: 1, sequence: 3, id: "" },
        { name: "商机提交人", key: "submitName", authority: 1, sequence: 4, id: "" },
        { name: "跟进人", key: "followName", authority: 1, sequence: 5, id: "" },
        { name: "跟进记录", key: "followUpRecords", authority: 1, sequence: 6, id: "" },
        { name: "意向产品", key: "intentionalProduct", authority: 1, sequence: 7, id: "" },
        { name: "上次联系时间", key: "lastContactTime", authority: 1, sequence: 8, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 9, id: "" },
    ]
}, {
    model: "threadPool", // 商机池(商机)
    attribute: [
        { name: "商机名称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "跟进联系人", key: "followName", authority: 1, sequence: 2, id: "" },
        { name: "商机状态", key: "threadStatus", authority: 1, sequence: 3, id: "" },
        { name: "跟进阶段", key: "followStage", authority: 1, sequence: 4, id: "" },
        { name: "商机创建人", key: "createName", authority: 1, sequence: 5, id: "" },
        { name: "商机提交人", key: "submitName", authority: 1, sequence: 6, id: "" },
        { name: "客户来源", key: "customerSource", authority: 1, sequence: 7, id: "" },
        { name: "增值税类型", key: "addedValueTaxType", authority: 1, sequence: 8, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 9, id: "" },
        { name: "意向产品", key: "intentionalProduct", authority: 1, sequence: 10, id: "" },
        { name: "上次联系时间", key: "lastContactTime", authority: 1, sequence: 11, id: "" },
        { name: "下次联系时间", key: "nextContactTime", authority: 1, sequence: 12, id: "" },
        { name: "最近分配时间", key: "allocateTime", authority: 1, sequence: 13, id: "" },
        { name: "跟进记录", key: "followUpRecords", authority: 1, sequence: 14, id: "" },
        { name: "保护时间", key: "protectTime", authority: 1, sequence: 15, id: "" },
        { name: "联系人", key: "contact", authority: 1, sequence: 16, id: "" },
        { name: "详细地址", key: "address", authority: 1, sequence: 18, id: "" },
        { name: "手机", key: "phone", authority: 1, sequence: 17, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: -1, id: "" },
        { name: "地区", key: "area", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "企业税号", key: "enterpriseNumber", authority: 1, sequence: -1, id: "" },
        { name: "联系电话", key: "tel", authority: 1, sequence: -1, id: "" },
        { name: "开户行", key: "bankName", authority: 1, sequence: -1, id: "" },
        { name: "银行账号", key: "bankAccount", authority: 1, sequence: -1, id: "" },
    ]
},  {
    model: "threadShare", // 共享商机
    attribute: [
        { name: "商机名称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "法人姓名", key: "corporateName", authority: 1, sequence: 2, id: "" },
        { name: "意向等级", key: "intentionLevel", authority: 1, sequence: 3, id: "" },
        { name: "决策人", key: "decisionMaker", authority: 1, sequence: 3, id: "" },
        { name: "电话号码", key: "phoneNumber", authority: 1, sequence: 3, id: "" },
        { name: "代账情况", key: "accountOnBehalf", authority: 1, sequence: 3, id: "" },
        { name: "代账到期时间", key: "accountDueTime", authority: 1, sequence: 3, id: "" },
        { name: "商机状态", key: "status", authority: 1, sequence: 3, id: "" },
        { name: "共享商机类型", key: "type", authority: 1, sequence: 3, id: "" },
        { name: "经营情况", key: "businessSituation", authority: 1, sequence: 3, id: "" },
        { name: "公司注册地址", key: "registeredAddress", authority: 1, sequence: 3, id: "" },
        { name: "办公地址", key: "officeAddress", authority: 1, sequence: 3, id: "" },
        { name: "年营收规模", key: "annualRevenueScale", authority: 1, sequence: 3, id: "" },
        { name: "专职会计人数", key: "quantityOfAccountant", authority: 1, sequence: 3, id: "" },
        { name: "园区返税", key: "parkRebateFlag", authority: 1, sequence: 3, id: "" },
        { name: "合规意识", key: "complianceAwareness", authority: 1, sequence: 3, id: "" },
        { name: "支付能力", key: "abilityToPay", authority: 1, sequence: 3, id: "" },
        { name: "资质数量", key: "quantityOfQualification", authority: 1, sequence: 3, id: "" },
        { name: "目前代账费", key: "escrowFee", authority: 1, sequence: 3, id: "" },
        { name: "关联代账客户名称", key: "associatedAccountClientName", authority: 1, sequence: 3, id: "" },
        { name: "关联未代账名称", key: "associateAnUnaccountedName", authority: 1, sequence: 3, id: "" },
        { name: "客户存在问题机诉求说明", key: "problemsAndDemands", authority: 1, sequence: 3, id: "" },
    ]
},{
    model: "threadRecycle", // 回收站(商机)
    attribute: [
        { name: "商机名称", key: "threadName", authority: 1, sequence: 1, id: "" },
        { name: "跟进联系人", key: "followName", authority: 1, sequence: 2, id: "" },
        { name: "商机状态", key: "threadStatus", authority: 1, sequence: 3, id: "" },
        { name: "跟进阶段", key: "followStage", authority: 1, sequence: 4, id: "" },
        { name: "商机创建人", key: "createName", authority: 1, sequence: 5, id: "" },
        { name: "商机提交人", key: "submitName", authority: 1, sequence: 6, id: "" },
        { name: "客户来源", key: "customerSource", authority: 1, sequence: 7, id: "" },
        { name: "增值税类型", key: "addedValueTaxType", authority: 1, sequence: 8, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 9, id: "" },
        { name: "意向产品", key: "intentionalProduct", authority: 1, sequence: 10, id: "" },
        { name: "上次联系时间", key: "lastContactTime", authority: 1, sequence: 11, id: "" },
        { name: "下次联系时间", key: "nextContactTime", authority: 1, sequence: 12, id: "" },
        { name: "最近分配时间", key: "allocateTime", authority: 1, sequence: 13, id: "" },
        { name: "跟进记录", key: "followUpRecords", authority: 1, sequence: 14, id: "" },
        { name: "保护时间", key: "protectTime", authority: 1, sequence: 15, id: "" },
        { name: "联系人", key: "contact", authority: 1, sequence: 16, id: "" },
        { name: "手机", key: "phone", authority: 1, sequence: 17, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: -1, id: "" },
        { name: "地区", key: "area", authority: 1, sequence: -1, id: "" },
        { name: "详细地址", key: "address", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "企业税号", key: "enterpriseNumber", authority: 1, sequence: -1, id: "" },
        { name: "联系电话", key: "tel", authority: 1, sequence: -1, id: "" },
        { name: "开户行", key: "bankName", authority: 1, sequence: -1, id: "" },
        { name: "银行账号", key: "bankAccount", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "contractInfo", // 全部合同(合同)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: 1, id: "" },
        { name: "合同编号", key: "contractNumber", authority: 1, sequence: 2, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: 3, id: "" },
        { name: "服务项", key: "services", authority: 1, sequence: 4, id: "" },
        { name: "签单日期", key: "signTime", authority: 1, sequence: 5, id: "" },
        { name: "到期日期", key: "dateDue", authority: 1, sequence: 6, id: "" },
        { name: "合同金额", key: "money", authority: 1, sequence: 7, id: "" },
        { name: "已收费至", key: "hasChargeTo", authority: 1, sequence: 8, id: "" },
        { name: "已审核收入", key: "auditedIncome", authority: 1, sequence: 9, id: "" },
        { name: "支出金额", key: "amountMoney", authority: 1, sequence: 10, id: "" },
        { name: "利润", key: "profit", authority: 1, sequence: 11, id: "" },
        { name: "服务产品", key: "product", authority: 1, sequence: 12, id: "" },
        { name: "开始日期", key: "contractStartTime", authority: 1, sequence: 13, id: "" },
        { name: "收费周期", key: "unit", authority: 1, sequence: 14, id: "" },
        { name: "已登记收入", key: "registeredIncome", authority: 1, sequence: 15, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: 16, id: "" },
        { name: "客户等级", key: "clientLevel", authority: 1, sequence: 17, id: "" },
        { name: "续签状态", key: "renewStatus", authority: 1, sequence: 18, id: "" },
        { name: "自动续签", key: "autoRenew", authority: 1, sequence: 19, id: "" },
        { name: "服务顾问", key: "serviceConsultant", authority: 1, sequence: 20, id: "" },
        { name: "收费负责人", key: "charge", authority: 1, sequence: 21, id: "" },
        { name: "合同状态", key: "status", authority: 1, sequence: 22, id: "" },
        { name: "签单人", key: "single", authority: 1, sequence: 23, id: "" },
        { name: "首次收费时间", key: "firstChargeTime", authority: 1, sequence: 24, id: "" },
        { name: "签单人部门", key: "signDepartment", authority: 1, sequence: 25, id: "" },
        { name: "来源", key: "customerSource", authority: 1, sequence: 26, id: "" },
        { name: "记单", key: "achievement", authority: 1, sequence: 27, id: "" },
        { name: "合同附件", key: "attachment", authority: 1, sequence: 28, id: "" },
        { name: "收款状态", key: "receiveStatus", authority: 1, sequence: 29, id: "" },

        { name: "创建人", key: "creatName", authority: 1, sequence: -1, id: "" },
        { name: "标准金额", key: "productPrice", authority: 1, sequence: -1, id: "" },
        { name: "合同成本", key: "cost", authority: 1, sequence: -1, id: "" },
        { name: "是否优惠", key: "isDiscounts", authority: 1, sequence: -1, id: "" },
        { name: "优惠金额", key: "discounts", authority: 1, sequence: -1, id: "" },
        { name: "产品名称", key: "productName", authority: 1, sequence: -1, id: "" },
        { name: "签约期限", key: "contractLimit", authority: 1, sequence: -1, id: "" },
        { name: "合同有效期", key: "validDate", authority: 1, sequence: -1, id: "" },
        { name: "是否赠送", key: "isFree", authority: 1, sequence: -1, id: "" },
        { name: "赠送的服务项目", key: "freeServes", authority: 1, sequence: -1, id: "" },
        { name: "合同备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "产品数量", key: "proNum", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "contractCheck", // 合同审核(合同)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: 1, id: "" },
        { name: "合同编号", key: "contractNumber", authority: 1, sequence: 2, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: 3, id: "" },
        { name: "服务项", key: "services", authority: 1, sequence: 4, id: "" },
        { name: "签单日期", key: "signTime", authority: 1, sequence: 5, id: "" },
        { name: "到期日期", key: "dateDue", authority: 1, sequence: 6, id: "" },
        { name: "合同金额", key: "money", authority: 1, sequence: 7, id: "" },
        { name: "已收费至", key: "hasChargeTo", authority: 1, sequence: 8, id: "" },
        { name: "已审核收入", key: "auditedIncome", authority: 1, sequence: 9, id: "" },
        { name: "支出金额", key: "amountMoney", authority: 1, sequence: 10, id: "" },
        { name: "利润", key: "profit", authority: 1, sequence: 11, id: "" },
        { name: "服务产品", key: "product", authority: 1, sequence: 12, id: "" },
        { name: "开始日期", key: "contractStartTime", authority: 1, sequence: 13, id: "" },
        { name: "收费周期", key: "unit", authority: 1, sequence: 14, id: "" },
        { name: "已登记收入", key: "registeredIncome", authority: 1, sequence: 15, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: 16, id: "" },
        { name: "客户等级", key: "clientLevel", authority: 1, sequence: 17, id: "" },
        { name: "续签状态", key: "renewStatus", authority: 1, sequence: 18, id: "" },
        { name: "自动续签", key: "autoRenew", authority: 1, sequence: 19, id: "" },
        { name: "服务顾问", key: "serviceConsultant", authority: 1, sequence: 20, id: "" },
        { name: "收费负责人", key: "charge", authority: 1, sequence: 21, id: "" },
        { name: "合同状态", key: "status", authority: 1, sequence: 22, id: "" },
        { name: "跟进人", key: "single", authority: 1, sequence: 23, id: "" },
        { name: "首次收费时间", key: "firstChargeTime", authority: 1, sequence: 24, id: "" },
        { name: "签单人部门", key: "signDepartment", authority: 1, sequence: 25, id: "" },
        { name: "来源", key: "customerSource", authority: 1, sequence: 26, id: "" },
        { name: "记单", key: "achievement", authority: 1, sequence: 27, id: "" },
        { name: "合同附件", key: "attachment", authority: 1, sequence: 28, id: "" },
        { name: "收款状态", key: "receiveStatus", authority: 1, sequence: 29, id: "" },

        { name: "创建人", key: "creatName", authority: 1, sequence: -1, id: "" },
        { name: "标准金额", key: "productPrice", authority: 1, sequence: -1, id: "" },
        { name: "合同成本", key: "cost", authority: 1, sequence: -1, id: "" },
        { name: "是否优惠", key: "isDiscounts", authority: 1, sequence: -1, id: "" },
        { name: "优惠金额", key: "discounts", authority: 1, sequence: -1, id: "" },
        { name: "产品名称", key: "productName", authority: 1, sequence: -1, id: "" },
        { name: "签约期限", key: "contractLimit", authority: 1, sequence: -1, id: "" },
        { name: "合同有效期", key: "validDate", authority: 1, sequence: -1, id: "" },
        { name: "是否赠送", key: "isFree", authority: 1, sequence: -1, id: "" },
        { name: "赠送的服务项目", key: "freeServes", authority: 1, sequence: -1, id: "" },
        { name: "合同备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "产品数量", key: "proNum", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "ContractNote", // 记单合同(合同)
    attribute: [
        { name: "签单人", key: "userName", authority: 1, sequence: -1, id: "" },
        { name: "客户名称", key: "clientName", authority: 1, sequence: -1, id: "" },
        { name: "合同金额", key: "money", authority: 1, sequence: -1, id: "" },
        { name: "服务项目", key: "serviceName", authority: 1, sequence: -1, id: "" },
        { name: "利润", key: "profits", authority: 1, sequence: -1, id: "" },
        { name: "记单", key: "note", authority: 1, sequence: -1, id: "" },
        { name: "签约时间", key: "time", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "ContractPayment", // 财务收款(合同)
    attribute: [
        { name: "交易单号", key: "voucherNo", authority: 1, sequence: -1, id: "" },
        { name: "收款总金额", key: "money", authority: 1, sequence: -1, id: "" },
        { name: "剩余金额", key: "remainingAmount", authority: 1, sequence: -1, id: "" },
        { name: "已领金额", key: "haveReceivedAmount", authority: 1, sequence: -1, id: "" },
        { name: "退款金额", key: "refundMoney", authority: 1, sequence: -1, id: "" },
        { name: "收款时间", key: "paymentTime", authority: 1, sequence: -1, id: "" },
        { name: "付款人", key: "paymentUser", authority: 1, sequence: -1, id: "" },
        { name: "收款账号", key: "paymentAccount", authority: 1, sequence: -1, id: "" },
        { name: "创建人", key: "creater", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "des", authority: 1, sequence: -1, id: "" },
    ] 
}, 
{ 
    model: "contractWork", // 合同派工(合同)
    attribute: [
        // { name: "客户全称", key: "clientName", authority: 1, sequence: -1, id: "" },
        { name: "合同编号", key: "contractNum", authority: 1, sequence: -1, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: -1, id: "" },
        { name: "服务产品", key: "product", authority: 1, sequence: -1, id: "" },
        { name: "业务类型名称", key: "professionName", authority: 1, sequence: -1, id: "" },
        { name: "提交人", key: "submitter", authority: 1, sequence: -1, id: "" },
        { name: "合同状态", key: "status", authority: 1, sequence: -1, id: "" },
        { name: "服务顾问", key: "serviceConsultant", authority: 1, sequence: -1, id: "" },
        { name: "签单时间", key: "signTime", authority: 1, sequence: -1, id: "" },
        { name: "下单日期", key: "orderTime", authority: 1, sequence: -1, id: "" },
        { name: "当前步骤", key: "step", authority: 1, sequence: -1, id: "" },
        { name: "当前步骤执行人", key: "stepExecute", authority: 1, sequence: -1, id: "" },
        { name: "赠送服务项", key: "give", authority: 1, sequence: -1, id: "" },
        { name: "业务类型备注", key: "professionDes", authority: 1, sequence: -1, id: "" },
        { name: "项目要求", key: "projectRequirement", authority: 1, sequence: -1, id: "" },
        { name: "项目所需资料", key: "projectRequiredInformation", authority: 1, sequence: -1, id: "" },
        { name: "项目成果", key: "projectResults", authority: 1, sequence: -1, id: "" },
        { name: "项目步骤", key: "projectStep", authority: 1, sequence: -1, id: "" },
        { name: "合同备注", key: "remark", authority: 1, sequence: -1, id: "" },
        { name: "项目记录", key: "itemRecord", authority: 1, sequence: -1, id: "" },
    ]
},
 {
    model: "contractRenew", // 续签管理(合同)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: 1, id: "" },
        { name: "合同编号", key: "contractNumber", authority: 1, sequence: 2, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: 3, id: "" },
        { name: "服务项", key: "services", authority: 1, sequence: 4, id: "" },
        { name: "签单日期", key: "signTime", authority: 1, sequence: 5, id: "" },
        { name: "到期日期", key: "dateDue", authority: 1, sequence: 6, id: "" },
        { name: "合同金额", key: "money", authority: 1, sequence: 7, id: "" },
        { name: "已收费至", key: "hasChargeTo", authority: 1, sequence: 8, id: "" },
        { name: "已审核收入", key: "auditedIncome", authority: 1, sequence: 9, id: "" },
        { name: "支出金额", key: "amountMoney", authority: 1, sequence: 10, id: "" },
        { name: "利润", key: "profit", authority: 1, sequence: 11, id: "" },
        { name: "服务产品", key: "product", authority: 1, sequence: 12, id: "" },
        { name: "开始日期", key: "contractStartTime", authority: 1, sequence: 13, id: "" },
        { name: "收费周期", key: "unit", authority: 1, sequence: 14, id: "" },
        { name: "已登记收入", key: "registeredIncome", authority: 1, sequence: 15, id: "" },
        { name: "片区", key: "zone", authority: 1, sequence: 16, id: "" },
        { name: "客户等级", key: "clientLevel", authority: 1, sequence: 17, id: "" },
        { name: "续签状态", key: "renewStatus", authority: 1, sequence: 18, id: "" },
        { name: "自动续签", key: "autoRenew", authority: 1, sequence: 19, id: "" },
        { name: "服务顾问", key: "serviceConsultant", authority: 1, sequence: 20, id: "" },
        { name: "收费负责人", key: "charge", authority: 1, sequence: 21, id: "" },
        { name: "合同状态", key: "status", authority: 1, sequence: 22, id: "" },
        { name: "跟进人", key: "single", authority: 1, sequence: 23, id: "" },
        { name: "首次收费时间", key: "firstChargeTime", authority: 1, sequence: 24, id: "" },
        { name: "签单人部门", key: "signDepartment", authority: 1, sequence: 25, id: "" },
        { name: "来源", key: "customerSource", authority: 1, sequence: 26, id: "" },
        { name: "记单", key: "achievement", authority: 1, sequence: 27, id: "" },
        { name: "合同附件", key: "attachment", authority: 1, sequence: 28, id: "" },
        { name: "收款状态", key: "receiveStatus", authority: 1, sequence: 29, id: "" },
    ]
}, {
    model: "clientInfo", // 客户资料(客户)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: 1, id: "" },
        { name: "创建时间", key: "createTime", authority: 1, sequence: 2, id: "" },
        // { name: "客户类型", key: "type", authority: 1, sequence: 3, id: "" },
        { name: "服务项", key: "service", authority: 1, sequence: 4, id: "" },
        { name: "客户经理", key: "manager", authority: 1, sequence: 5, id: "" },
        { name: "服务顾问", key: "adviser", authority: 1, sequence: 6, id: "" },
        { name: "客户状态", key: "clientStatus", authority: 1, sequence: 7, id: "" },
        { name: "客户等级", key: "clientLevel", authority: 1, sequence: 8, id: "" },
        { name: "所属片区", key: "zone", authority: 1, sequence: 9, id: "" },
        { name: "增值税", key: "addedValueTaxType", authority: 1, sequence: 10, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: 11, id: "" },
        { name: "成立日期", key: "setTime", authority: 1, sequence: 12, id: "" },
        { name: "详细地址", key: "address", authority: 1, sequence: 13, id: "" },
        { name: "所属地区", key: "area", authority: 1, sequence: 14, id: "" },
        { name: "标签", key: "tip", authority: 1, sequence: 15, id: "" },
        { name: "所属行业", key: "industryType", authority: 1, sequence: 16, id: "" },
        { name: "创建人", key: "createName", authority: 1, sequence: 17, id: "" },
        { name: "来源", key: "source", authority: 1, sequence: 18, id: "" },
        { name: "跟进时间", key: "followTime", authority: 1, sequence: 19, id: "" },
        { name: "社保", key: "isSocialSecurity", authority: 1, sequence: 20, id: "" },
        { name: "流水", key: "isStatement", authority: 1, sequence: 21, id: "" },
        { name: "园区", key: "isTaxPark", authority: 1, sequence: 22, id: "" }, 
        { name: "企业税号", key: "enterpriseNumber", authority: 1, sequence: -1, id: "" },
        { name: "联系电话", key: "tel", authority: 1, sequence: -1, id: "" },
        { name: "开户行", key: "bankName", authority: 1, sequence: -1, id: "" },
        { name: "银行账号", key: "bankAccount", authority: 1, sequence: -1, id: "" },
        { name: "开票客户", key: "isInvoicing", authority: 1, sequence: -1, id: "" },
        { name: "业务角色", key: "role", authority: 1, sequence: -1, id: "" },
        { name: "包含社保", key: "isSocialSecurity", authority: 1, sequence: -1, id: "" },
        { name: "包含流水", key: "isStatement", authority: 1, sequence: -1, id: "" },
        { name: "包含园区", key: "isTaxPark", authority: 1, sequence: -1, id: "" },
        { name: "包含财务数据", key: "isFinancialData", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "clientConnect", // 工作交接(客户)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: -1, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: -1, id: "" },
        { name: "业务角色", key: "role", authority: 1, sequence: -1, id: "" },
    ]
}, {
    model: "clientCheck", // 流失审核(客户)
    attribute: [
        { name: "客户全称", key: "clientName", authority: 1, sequence: -1, id: "" },
        { name: "客户编号", key: "clientNum", authority: 1, sequence: -1, id: "" },
        { name: "客户状态", key: "clientStatus", authority: 1, sequence: -1, id: "" },
        { name: "流失服务类别", key: "lossServiceType", authority: 1, sequence: -1, id: "" },
        { name: "提交人", key: "submit", authority: 1, sequence: -1, id: "" },
        { name: "申请时间", key: "applyTime", authority: 1, sequence: -1, id: "" },
        { name: "流失原因", key: "cause", authority: 1, sequence: -1, id: "" },
        { name: "申请状态", key: "applyStatus", authority: 1, sequence: -1, id: "" },
        { name: "备注", key: "des", authority: 1, sequence: -1, id: "" },
        { name: "附件", key: "file", authority: 1, sequence: -1, id: "" },
    ]
}]

/**
 * 获取当前界面所有功能按钮
 */
export function getAllFunctionButtons(active) {
    console.log("进入方法");
    let buttonList = []
    for (let index = 0; index < featureData.length; index++) {
        let element = featureData[index];
        if (element.model == active) {
            buttonList = JSON.parse(JSON.stringify(element.feature))
        }
    }
    return buttonList
}

/**
 * 获取当前界面属性(所有属性)/表头
 */
export function getAttributeData(active, isMenu, drawerDataKeys, del) {
    let attribute = []
    if (isMenu) {
        // 表头,只拿 sequence 不为 -1 的
        for (let index = 0; index < attributeData.length; index++) {
            let element = attributeData[index];
            if (element.model == active) {
                attribute = []
                    // if (isMenu) {
                for (let i = 0; i < element.attribute.length; i++) {
                    let el = element.attribute[i];
                    if (el.sequence > 0) {
                        attribute.push(JSON.parse(JSON.stringify(el)))
                    }
                }
                // 排序
                attribute = attribute.sort((a, b) => a.sequence - b.sequence)
                
                    // }
                    // else {
                    //     attribute = JSON.parse(JSON.stringify(element.attribute))
                    // }
            }
        }

    } else {
        attribute = drawerDataKeys
    }
    del(attribute)
}