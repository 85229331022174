<template>
  <div id="test_paper">
    <div class="test_paper-title">
      <div class="test_paper-title-item">
        <div class="title">试卷标题:</div>
        <div class="value">
          <el-input v-model="data.data.title"></el-input>
        </div>
      </div>
      <div class="test_paper-title-item">
        <div class="title">试卷分类:</div>
        <div class="value">
          <el-cascader
            v-model="data.data.enterpriseCollegeCategoryId"
            :props="{ emitPath: false, value: 'id' }"
            :options="data.configuration.categoryList"
            clearable
          ></el-cascader>
        </div>
      </div>
      <div class="test_paper-title-item">
        <div class="title">理论总分:</div>
        <div class="value">
          <el-input disabled v-model="data.data.totalScore"></el-input>
        </div>
      </div>
      <div class="test_paper-title-item">
        <div class="title">考试时长(分钟):</div>
        <div class="value">
          <el-input v-model="data.data.duration"></el-input>
        </div>
      </div>
      <div class="test_paper-title-item">
        <div class="title">考试及格配置(分):</div>
        <div class="value">
          <el-input v-model="data.data.passingScore"></el-input>
        </div>
      </div>
    </div>
    <div class="test_paper-content">
      <div
        class="test_paper-content-item"
        v-for="(item, index) in data.data.examPaperRules"
        :key="index"
      >
        <div class="left">
          <div class="left-item">
            <div class="left-item-title">题型</div>
            <div class="left-item-value">
              <el-select v-model="item.type">
                <el-option
                  v-for="item in examinationTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="left-item">
            <div class="left-item-title">数量</div>
            <div class="left-item-value">
              <el-input v-model="item.count"></el-input>
            </div>
          </div>
          <div class="left-item">
            <div class="left-item-title">每题分值</div>
            <div class="left-item-value">
              <el-input v-model="item.score"></el-input>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-item">
            <el-button
              size="small"
              icon="el-icon-top"
              @click="handleClick(item, 'up', index)"
              >上调</el-button
            >
          </div>
          <div class="right-item">
            <el-button
              size="small"
              icon="el-icon-bottom"
              @click="handleClick(item, 'down', index)"
              >下调</el-button
            >
          </div>
          <div class="right-item">
            <el-button
              size="small"
              icon="el-icon-delete"
              @click="handleClick(item, 'delete', index)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="test_paper-footer">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="handleClick('', 'add')"
        >添加配置</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      examinationTypeList: this.$examinationTypeList,
    };
  },
  watch: {
    "data.data.examPaperRules": {
      handler(newVal, oldVal) {
        this.data.data.totalScore = newVal.reduce(
          (item, currentValue) =>
            item + currentValue.score * currentValue.count,
          0
        );
      },
      deep: true,
    },
  },
  methods: {
    handleClick(item, flag, index) {
      let temp = {};
      switch (flag) {
        case "add":
          // 添加配置
          this.data.data.examPaperRules.push({
            type: "",
            count: "",
            score: "",
          });
          break;
        case "up":
          // 上调
          if (index === 0) {
            return this.$message.error("该配置项已位于第一项，无法上调");
          }
          temp = this.data.data.examPaperRules[index - 1];
          this.$set(this.data.data.examPaperRules, index, temp);
          this.$set(this.data.data.examPaperRules, index - 1, item);
          break;
        case "down":
          // 下调
          if (index === this.data.data.examPaperRules.length - 1) {
            return this.$message.error("该配置项已位于最后一项，无法下调");
          }
          temp = this.data.data.examPaperRules[index + 1];
          this.$set(this.data.data.examPaperRules, index, temp);
          this.$set(this.data.data.examPaperRules, index + 1, item);
          break;
        case "delete":
          // 删除配置
          this.data.data.examPaperRules.splice(index, 1);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    console.log("this.data", this.data);
  },
};
</script>

<style lang="less" scoped>
#test_paper {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #fbfcfe;
  height: 600px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  .test_paper-title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .test_paper-title-item {
      width: 50%;
      display: flex;
      align-items: center;
      margin: 10px 0;
      .title {
        min-width: 150px;
        text-align: right;
        margin-right: 5px;
        font-size: 15px;
        font-weight: 700;
      }
      .value {
        max-width: 200px;
      }
    }
  }
  .test_paper-content {
    width: 100%;
    margin: 20px 0;
    .test_paper-content-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #fff;
      margin: 10px 0;
      .left {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        border-right: 1px solid #dcdfe6;
        .left-item {
          width: 50%;
          display: flex;
          align-items: center;
          margin: 10px 0;
          .left-item-title {
            min-width: 150px;
            text-align: right;
            margin-right: 5px;
            font-size: 15px;
            font-weight: 700;
          }
          .left-item-value {
            max-width: 200px;
          }
        }
      }
      .right {
        width: 20%;
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        .right-item {
          width: 49%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .test_paper-footer {
    width: 100%;
    display: flex;
  }
}
</style>
