<template>
  <!-- 出访记录 -->
  <div id="thread-visit">
    <div class="thread-visit-screen">
      <div class="thread-visit-screen-line">
        <div class="thread-visit-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-visit-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-visit-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="thread-visit-screen-search">
          <el-select
            v-model="searchData.visitArea"
            placeholder="请选择区域"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in clientAreaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-visit-screen-search">
          <el-select
            v-model="searchData.status"
            placeholder="请选择状态"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option label="未完成" value="1"></el-option>
            <el-option label="已完成" value="2"></el-option>
          </el-select>
        </div>
        <div class="thread-visit-screen-active"></div>
        <el-button
          style="margin-right: 5px;"
          type="primary"
          size="small"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          @click="handleClick('', 0)"
          >添加出访</el-button
        >
        <el-button
          style="margin-right: 10px;"
          type="primary"
          size="small"
          v-showFunction="{
            active: 'export',
            array: allFunctionButtons,
          }"
          @click="handleClick('', 1)"
          >导出</el-button
        >
      </div>
      <div class="thread-visit-screen-line">
        <div class="thread-visit-screen-search">
          <el-date-picker
            v-model="searchData.visitTime"
            type="daterange"
            range-separator="至"
            start-placeholder="出访时间"
            end-placeholder="出访时间"
            value-format="yyyy-MM-dd"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="thread-visit-screen-search">
          <el-select
            v-model="searchData.visitReason"
            placeholder="请选择出访事由"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in this.visitReasonList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="thread-visit-screen-search">
          <el-select
            v-model="searchData.accompanyUserId"
            placeholder="请选择陪访人员"
            @change="selectInitTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.userListRemoveAll"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="thread-visit-table">
      <div class="thread-visit-table-card">
        <div class="thread-visit-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @sort-change="sortChange"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="clientName"
              label="客户名称"
              show-overflow-tooltip
              width="230"
            >
            </el-table-column>
            <template v-for="(item, index) in tableMenu">
              <el-table-column
                :key="index"
                :width="item.width"
                :label="item.title"
                :prop="item.prop"
                :fixed="item.fixed"
              >
                <template slot-scope="scope">
                  <div v-if="item.prop == 'contactNumber'">
                    <span
                      v-if="
                        scope.row.registeredUserId == baseInfo.userId ||
                          whetherAgencyHead
                      "
                      >{{ scope.row.contactNumber }}</span
                    >
                    <span v-else>***********</span>
                  </div>
                  <div v-else-if="item.prop == 'duration'">
                    <span v-if="scope.row.duration">
                      {{ scope.row.duration + "小时" }}</span
                    >
                    <span v-else></span>
                  </div>
                  <div v-else-if="item.prop == 'status'">
                    <span
                      style="color: #F56C6C; cursor: pointer"
                      v-if="scope.row.status == '未完成'"
                      @click="rowClick(scope.row, scope.$index)"
                    >
                      <i class="el-icon-edit"></i>{{ scope.row.status }}</span
                    >
                    <span
                      style="color: #67C23A; cursor: pointer"
                      v-if="scope.row.status == '已完成'"
                      @click="rowClick(scope.row, scope.$index)"
                    >
                      <i class="el-icon-edit"></i>{{ scope.row.status }}</span
                    >
                  </div>
                  <span v-else>
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  v-showFunction="{
                    active: 'del',
                    array: allFunctionButtons,
                  }"
                  @click="handleClick(scope.row, 2)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="thread-visit-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="visitDrawerData.flag">
      <my_drawer
        :drawerData="visitDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'myThread'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
import * as threadData from "./js/threadData.js";
import * as myThreadData from "@/assets/js/myThread.js";
export default {
  components: {
    my_dialog,
    my_drawer,
  },
  data() {
    return {
      options: [
        {
          value: 1,
          label: "东南",
          children: [
            {
              value: 2,
              label: "上海",
              children: [
                { value: 3, label: "普陀" },
                { value: 4, label: "黄埔" },
                { value: 5, label: "徐汇" },
              ],
            },
            {
              value: 7,
              label: "江苏",
              children: [
                { value: 8, label: "南京" },
                { value: 9, label: "苏州" },
                { value: 10, label: "无锡" },
              ],
            },
            {
              value: 12,
              label: "浙江",
              children: [
                { value: 13, label: "杭州" },
                { value: 14, label: "宁波" },
                { value: 15, label: "嘉兴" },
              ],
            },
          ],
        },
        {
          value: 17,
          label: "西北",
          children: [
            {
              value: 18,
              label: "陕西",
              children: [
                { value: 19, label: "西安" },
                { value: 20, label: "延安" },
              ],
            },
            {
              value: 21,
              label: "新疆维吾尔族自治区",
              children: [
                { value: 22, label: "乌鲁木齐" },
                { value: 23, label: "克拉玛依" },
              ],
            },
          ],
        },
      ],
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        userId: "",
        userIdList: [],
        tempUserId: [],
        userList: [],
        userListRemoveAll: [],
        address: "",
        department: [],
        visitArea: "",
        status: "",
        visitTime: [],
        visitReason: "",
        accompanyUserId: "",
      },
      tableMenu: threadData.threadVisitMenuData(),
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "threadVisit"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempWeiXinData: {},
      whetherAgencyHead: false,
      defaultSort: { prop: "gmt_create", order: "descending" },
      allEmployeeList: [],
      clientAreaOptions: [],
      visitReasonList: [],
      visitData: [],
      visitDrawerData: threadData.visitDrawerData([]),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadVisit",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          this.searchData.userListRemoveAll = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
            this.searchData.userListRemoveAll.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.getEmployeeListForDownBox();
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
          this.getClientAreaOptions();
          this.getVisitList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户片区
     */
    getClientAreaOptions() {
      let requestData = {
        type: ["片区", "出访事由"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "片区") {
              this.clientAreaOptions = element.value.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                };
              });
            } else if (element.type == "出访事由") {
              this.visitReasonList = element.value.map((item) => {
                return {
                  label: item.name,
                  value: item.value,
                };
              });
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取出访设置列表
     */
    getVisitList() {
      this.$request(
        "get",
        "/labelSetting/queryByModel?model=thread",
        {},
        (data) => {
          this.visitData = data;
          this.visitData.forEach((item, index) => {
            if (item.options) {
              item.options = JSON.parse(item.options);
            }
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // this.tableData = [];
      this.loading = true;
      let requestData = {
        clientName: this.searchData.input, // 客户简称
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIds: this.searchData.tempUserId, // 员工 id 集合
        visitTime: this.searchData.visitTime, // 筛选项 出访时间
        visitArea: this.searchData.visitArea, // 筛选项 片区
        visitReason: this.searchData.visitReason, // 筛选项 出访事由
        accompanyUserId: this.searchData.accompanyUserId, // 筛选项 陪访人员
        status: this.searchData.status, // 筛选项 片区
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/visitRegistration/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            if (item.details) {
              item.details = JSON.parse(item.details);
            }
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempWeiXinData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 添加出访记录
          let options = [];
          for (let index = 1; index <= 23; index++) {
            options[index - 1] = {
              label: index + "小时",
              value: index,
            };
          }
          options.push(
            { label: "1天", value: 24 },
            { label: "2天", value: 48 },
            { label: "3天", value: 72 }
          );
          this.dialogData.width = "600px";
          this.dialogData.title = "出访记录添加";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "客户名称",
              key: "threadId",
              type: "select-filterable",
              value: "",
              options: [],
            },
            {
              title: "联系人",
              key: "contactPerson",
              type: "input",
              value: "",
            },
            {
              title: "手机号",
              key: "contactNumber",
              type: "input",
              value: "",
            },
            {
              title: "出访时间",
              key: "visitTime",
              type: "timess",
              value1: this.$formatTime(new Date(), "YYYY-MM-DD"),
              value2: "",
              value3: "",
              options: options,
            },
            {
              title: "出访事由",
              key: "visitReason",
              type: "select",
              value: "",
              options: this.visitReasonList,
            },
            {
              title: "出访地区",
              key: "visitArea",
              type: "select",
              value: "",
              options: this.clientAreaOptions,
            },
            {
              title: "详细地址",
              key: "visitAddress",
              type: "input",
              value: "",
            },
            {
              title: "陪访人员",
              key: "accompanyUserId",
              type: "select-object",
              value: "",
              options: this.allEmployeeList,
            },
          ];
          console.log("新增dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 1:
          // 导出
          requestData = {
            clientName: this.searchData.input, // 客户简称
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "", // 部门 id
            userIds: this.searchData.tempUserId, // 员工 id 集合
            visitArea: this.searchData.visitArea, // 筛选项 片区
            status: this.searchData.status, // 筛选项 片区
            currentPage: this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
          };
          window.location.href =
            this.$baseurl +
            "/visitRegistration/export?data=" +
            JSON.stringify(requestData);
          break;
        case 2:
          // 删除出访记录
          this.$confirm("此操作将永久删除该出访记录, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let requestData = {
                ids: [this.tempWeiXinData.id],
              };
              this.$request(
                "post",
                "/visitRegistration/del",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});

          break;
        default:
          break;
      }
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, index) {
      this.tempData = row;
      let modelListLine = [];
      this.visitData.forEach((item, index) => {
        if (row.details && row.details[index]) {
          modelListLine[index] = row.details[index];
        } else {
          switch (item.type) {
            case "1":
              // 输入框
              modelListLine[index] = {
                horizontal: true,
                isShow: true,
                isDisable: false,
                isSelect: false,
                title: item.label,
                type: "input",
                tempValue: "",
                value: "",
              };
              if (item.label == "面访记录")
                modelListLine[index].type = "textarea";
              break;
            case "2":
              // 单选框
              modelListLine[index] = {
                horizontal: true,
                isShow: true,
                isDisable: false,
                isSelect: false,
                title: item.label,
                type: "tags-select",
                tempValue: "",
                value: [],
                options: item.options,
              };
              break;
            case "3":
              // 多选框
              modelListLine[index] = {
                horizontal: true,
                isShow: true,
                isDisable: false,
                isSelect: false,
                title: item.label,
                type: "tags-select",
                tempValue: "",
                value: [],
                options: item.options,
              };
              break;
            default:
              break;
          }
        }
      });
      this.visitDrawerData = threadData.visitDrawerData(modelListLine);
      this.visitDrawerData.flag = true;
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          requestData = {
            threadId: "", // 商机 id
            contactPerson: "", // 联系人
            contactNumber: "", // 联系电话
            visitArea: "", // 出访区域
            visitAddress: "", // 出访详细地址
            visitTimeStart: "", // 出访时间，yyyy-MM-dd HH:mm:ss
            duration: "", // 出访时长，
            registeredUserId: this.baseInfo.userId, // 登记用户
            accompanyUserId: "", // 陪访用户
          };
          for (let index = 0; index < this.dialogData.data.length; index++) {
            const element = this.dialogData.data[index];
            if (element.key == "accompanyUserId") {
              requestData[element.key] = JSON.stringify(
                element.value.map((item) => item.value)
              );
            } else if (element.key == "visitTime") {
              requestData.visitTimeStart =
                element.value1 + " " + element.value2 + ":00";
              requestData.duration = element.value3;
            } else {
              requestData[element.key] = element.value;
            }
          }
          for (const key in requestData) {
            if (
              requestData[key] === null ||
              requestData[key] === undefined ||
              requestData[key] === ""
            ) {
              this.$message.error("请填写完整信息!");
              return;
            }
          }
          let flag1 = true;
          if (
            !this.$phoneCheck.mobilePhoneNumberCheck(requestData.contactNumber)
          ) {
            // 做手机号格式校验
            flag1 = false;
          }
          if (!flag1) {
            this.$message.error(
              "联系人手机号格式不正确,请确认填入正确的手机号!"
            );
            return;
          }
          this.$request(
            "post",
            "/visitRegistration/add",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("出访记录添加成功!");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "exportDetails":
          // 导出
          window.location.href =
            this.$baseurl +
            "/visitRegistration/exportWord?id=" +
            this.tempData.id;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 客户地址导入成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "导入成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[1].value.value = response.result;
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop ? "gmt_create" : "";
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.visitDrawerData = value;
    },
    saveDrawerData() {
      let requestData = {
        id: this.tempData.id, // id
        details: this.visitDrawerData.data.modelList[0].line, // 出访详情
        status: "2", // 完成情况，'1' - 未完成；'2' - 已完成
      };
      requestData.details = JSON.stringify(requestData.details);
      this.$request(
        "post",
        "/visitRegistration/update",
        requestData,
        (data) => {
          console.log("this.tempData", this.tempData);
          this.$message.success("修改成功!");
          this.initTableData();
          // this.rowClick(this.tempData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.visitDrawerData.colseanimationflag = true;
      this.visitDrawerData.flag = false;
    },
    /**-
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.visitDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.visitDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData();
    },
    /**
     * 添加标签事件
     */
    addTags(data) {
      if (this.visitDrawerData.data.modelList[data.i].line[data.j].isSelect) {
        let value = this.visitDrawerData.data.modelList[data.i].line[data.j]
          .tempValue;
        if (
          value &&
          this.visitDrawerData.data.modelList[data.i].line[
            data.j
          ].value.indexOf(value) < 0
        ) {
          // 排除重复选项
          this.visitDrawerData.data.modelList[data.i].line[data.j].value.push(
            value
          );
          this.saveDrawerData("角色信息");
        }
        this.visitDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = false;
        this.visitDrawerData.data.modelList[data.i].line[data.j].tempValue = "";
      } else {
        this.visitDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = true;
      }
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    let currentDay = new Date();
    currentDay.setDate(1);
    let endDay = new Date(currentDay);
    endDay.setMonth(currentDay.getMonth() + 1);
    endDay.setDate(0);
    this.searchData.visitTime[0] = this.$formatTime(
      new Date().setDate(1),
      "YYYY-MM-DD"
    );
    this.searchData.visitTime[1] = this.$formatTime(endDay, "YYYY-MM-DD");
    this.searchData.input = this.$route.query.clientName;
  },
};
</script>

<style lang="less" scoped>
#thread-visit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-visit-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .thread-visit-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-visit-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-visit-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-visit-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .thread-visit-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .thread-visit-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-visit-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-visit-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .thread-visit-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .thread-visit-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .thread-visit-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-visit-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .thread-visit-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-visit-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .thread-visit-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
          margin-left: 3px;
        }
      }
      .thread-visit-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;

        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
