import * as systemAuthorityData from "../assets/js/systemAuthorityData"
/**
 * 所有权限的配置文件
 */
export function allAuthorityConfiguration() {
    let data = {
        //全局菜单 头像下的菜单
        generalMenu: [{
                title: "邀请同事",
                isShow: false,
                isDisable: true,
                path: '/invite'
            },
            {
                title: "机构设置",
                isShow: true,
                isDisable: false,
                path: '/main/department'
            },
            {
                title: "个人设置",
                isShow: true,
                isDisable: false,
                path: '/main/person'
            },
            {
                title: "系统设置",
                isShow: true,
                isDisable: false,
                path: '/main/setting'
            },
            {
                title: "常用菜单配置",
                isShow: false,
                isDisable: true,
                path: '/main/config_menu'
            },
            {
                title: "意见反馈",
                isShow: false,
                isDisable: true,
                path: '/main/feedback'
            }, {
                title: "退出登录",
                isShow: true,
                isDisable: false,
                path: '/'
            },
        ],

        //顶部菜单
        topMenu: [{
                title: "首页",
                isShow: true,
                isDisable: false,
                key: "index",
                path: '/main/index'
            },
            {
                title: "商机",
                isShow: true,
                isDisable: false,
                key: "index",
                path: '/main/thread'
            },
            {
                title: "合同",
                isShow: true,
                isDisable: false,
                key: "index",
                path: '/main/contract'
            },
            {
                title: "客户",
                isShow: true,
                isDisable: false,
                key: "index",
                path: '/main/client'
            },
            {
                title: "发票",
                isShow: false,
                isDisable: true,
                key: "index",
                path: '/main/invoice'
            },
            {
                title: "产品",
                isShow: true,
                isDisable: false,
                key: "index",
                path: '/main/product'
            },
            {
                title: "待办",
                isShow: true,
                isDisable: false,
                key: "waite",
                path: '/main/waite'
            },
            {
                title: "报销",
                isShow: true,
                isDisable: false,
                key: "reimbursement",
                path: '/main/reimbursement'
            },
            {
                title: "报表",
                isShow: true,
                isDisable: true,
                key: "waite",
                path: '/main/report'
            },
            {
                title: "知识库",
                isShow: true,
                isDisable: true,
                key: "knowledge",
                path: '/main/knowledge'
            },
            {
                title: "人才",
                isShow: true,
                isDisable: false,
                key: "human",
                path: '/main/human'
            },
            // {
            //     title: "人资",
            //     isShow: true,
            //     isDisable: false,
            //     key: "waite",
            //     path: '/main/humanResources'
            // },
        ],

        // 商机模块
        thread: {
            //每次进入一个模块 会得到一次左侧模块子菜单列表
            //左侧模块子菜单  如果children为空,或者children全部不可见,则父菜单也不可见
            modelChildMenu: [{
                    title: "我的商机",
                    path: '1',
                    isShow: true,
                    isDisable: false,
                    children: [{
                            title: "我的商机",
                            path: 'my-thread',
                            model: "myThread",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "全部商机",
                            path: 'all-thread',
                            model: "allThread",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "我的资源",
                            path: 'my-resources',
                            model: "myResources",
                            isShow: true,
                            isDisable: false,
                        }
                    ]
                }, {
                    title: "公海",
                    path: "high-seas",
                    model: "highSeas",
                    isShow: true,
                    isDisable: false,
                    children: [{
                        title: "已签约客户",
                        path: 'client-thread',
                        model: "clientThread",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "商机池",
                        path: 'threads',
                        model: "threadPool",
                        isShow: true,
                        isDisable: false,
                    }],
                },
                {
                    title: "回收站",
                    path: "threads-recyle",
                    model: "threadRecycle",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
                {
                    title: "设置",
                    path: "thread-setting",
                    model: "threadSetting",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
            ],

            // 子模块---我的商机  √
            myThread: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "导出",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "新增客户商机",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "跟进",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "转成交",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "查看客户",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "置为无效",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "释放至商机池",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "删除",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "操作记录",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                ],

                //表格中每条数据展开的侧栏数据
                explicitData: [{
                        title: "商机名称",
                        key: "threadName", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进联系人",
                        key: "followName",
                        width: "200",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "商机状态",
                        key: "threadStatus",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进阶段",
                        key: "followStage",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "片区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机创建人",
                        key: "createName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机提交人",
                        key: "submitName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "客户来源",
                        key: "customerSource",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "增值税类型",
                        key: "addedValueTaxType",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "创建时间",
                        key: "createTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "地区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "详细地址",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "意向产品",
                        key: "intentionalProduct",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "备注",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "跟进记录",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "上次联系时间",
                        key: "lastContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "下次联系时间",
                        key: "nextContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "最近分配时间",
                        key: "allocateTime",
                        width: "150",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "企业税号",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系电话",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "开户行",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "银行账号",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "跟进记录",
                        key: "followUpRecords",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "保护时间",
                        key: "protectTime",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系人",
                        key: "contact",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "手机",
                        key: "phone",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }
                ]

            },

            // 子模块---全部商机
            allThread: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "导出",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "新增客户商机",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "跟进",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "转成交",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "查看客户",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "置为无效",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "释放至商机池",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "删除",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "操作记录",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                ],

                //表格中每条数据展开的侧栏数据
                explicitData: [{
                        title: "商机名称",
                        key: "threadName", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进记录",
                        key: "followUpRecords", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "保护时间",
                        key: "protectTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "跟进联系人",
                        key: "followName",
                        width: "200",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "商机状态",
                        key: "threadStatus",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进阶段",
                        key: "followStage",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机提交人",
                        key: "submitName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "片区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机创建人",
                        key: "createName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "客户来源",
                        key: "customerSource",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系人",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "手机",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "增值税类型",
                        key: "addedValueTaxType",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "创建时间",
                        key: "createTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "地区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "详细地址",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "意向产品",
                        key: "intentionalProduct",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "备注",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "跟进记录",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "上次联系时间",
                        key: "lastContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "下次联系时间",
                        key: "nextContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "最近分配时间",
                        key: "allocateTime",
                        width: "150",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "企业税号",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系电话",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "开户行",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "银行账号",
                        isShow: true,
                        isDisable: false
                    }
                ]

            },

            // 我的资源
            myResources: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "新增",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: []
            },

            // 子模块 --- 已签约客户
            clientThread: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "导出",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "调户",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "新建合同",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "删除",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "操作记录",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                ],

                //表格中每条数据展开的侧栏数据
                explicitData: [{
                        title: "商机名称",
                        key: "threadName", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "跟进联系人",
                        key: "followName",
                        width: "200",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "商机状态",
                        key: "threadStatus",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进阶段",
                        key: "followStage",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机提交人",
                        key: "submitName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "片区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机创建人",
                        key: "createName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "客户来源",
                        key: "customerSource",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系人",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "手机",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "增值税类型",
                        key: "addedValueTaxType",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "创建时间",
                        key: "createTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "地区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "详细地址",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "意向产品",
                        key: "intentionalProduct",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "备注",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "跟进记录",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "上次联系时间",
                        key: "lastContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "下次联系时间",
                        key: "nextContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "最近分配时间",
                        key: "allocateTime",
                        width: "150",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "企业税号",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系电话",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "开户行",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "银行账号",
                        isShow: true,
                        isDisable: false
                    }
                ]

            },

            // 子模块---商机池
            threadPool: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "导出",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "分配",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "转成交",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "恢复商机",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "删除",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "操作记录",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                ],

                //表格中每条数据展开的侧栏数据
                explicitData: [{
                        title: "商机名称",
                        key: "threadName", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "跟进联系人",
                        key: "followName",
                        width: "200",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "商机提交人",
                        key: "submitName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    },
                    {
                        title: "商机状态",
                        key: "threadStatus",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进阶段",
                        key: "followStage",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "片区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机创建人",
                        key: "createName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "客户来源",
                        key: "customerSource",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系人",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "手机",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "增值税类型",
                        key: "addedValueTaxType",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "创建时间",
                        key: "createTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "地区",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "详细地址",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "意向产品",
                        key: "intentionalProduct",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "备注",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "跟进记录",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "上次联系时间",
                        key: "lastContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "下次联系时间",
                        key: "nextContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "最近分配时间",
                        key: "allocateTime",
                        width: "150",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "企业税号",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系电话",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "开户行",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "银行账号",
                        isShow: true,
                        isDisable: false
                    }
                ]

            },

            // 子模块---回收站
            threadRecycle: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "导出",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "还原",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    }, {
                        title: "删除",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "操作记录",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                ],

                //表格中每条数据展开的侧栏数据
                explicitData: [{
                        title: "商机名称",
                        key: "threadName", // 是否成为表头菜单,没有key,表示不是表头菜单
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "跟进联系人",
                        key: "followName",
                        width: "200",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "商机提交人",
                        key: "submitName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    },
                    {
                        title: "商机状态",
                        key: "threadStatus",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "跟进阶段",
                        key: "followStage",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "商机创建人",
                        key: "createName",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "客户来源",
                        key: "customerSource",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "联系人",
                        key: "contact",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "手机",
                        key: "phone",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "创建时间",
                        key: "createTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "详细地址",
                        key: "address",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "备注",
                        key: "remark",
                        width: "100",
                        isShow: true,
                        isDisable: false
                    }, {
                        title: "上次联系时间",
                        key: "lastContactTime",
                        width: "120",
                        isShow: true,
                        isDisable: false
                    }
                ]

            },

            // 子模块--权限设置
            threadSetting: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "选项值设置",
                        isShow: true,
                        isDisable: false,
                        path: ''
                    },
                    {
                        title: "商机保护",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "权限设置",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "同行电话",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "同行编辑",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    }
                ],

            }
        },

        // 客户模块
        client: {
            //每次进入一个模块 会得到一次左侧模块子菜单列表
            //左侧模块子菜单  如果children为空,或者children全部不可见,则父菜单也不可见
            modelChildMenu: [{
                    title: "客户",
                    path: '1',
                    isShow: true,
                    isDisable: false,
                    children: [{
                            title: "客户资料",
                            path: 'client-info',
                            model: "clientInfo",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "工作交接",
                            path: 'client-connect',
                            model: "clientConnect",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "流失审核",
                            path: 'client-check',
                            model: "clientCheck",
                            isShow: true,
                            isDisable: false,
                        }
                    ]
                },
                {
                    title: "回收站",
                    path: "client-recyle",
                    model: "clientRecycle",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
                {
                    title: "设置",
                    path: "client-setting",
                    model: "clientSetting",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
            ],

            // 客户资料
            clientInfo: {
                modelChildMenu: [{
                    title: "新增客户",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "客户资质",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "新建合同",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "删除客户",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "申请代账流失",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "新建客户联系人",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "更新工商信息",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: [{
                        key: "clientName",
                        title: "客户全称",
                        width: "160",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "clientNum",
                        title: "客户编号",
                        width: "90",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "type",
                        title: "客户类型",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "service",
                        title: "服务项",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "manager",
                        title: "客户经理",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "adviser",
                        title: "服务顾问",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "clientStatus",
                        title: "客户状态",
                        width: "120",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "clientLevel",
                        title: "客户等级",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "pianqu",
                        title: "所属片区",
                        width: "150",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "addedValueTaxType",
                        title: "增值税",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "setTime",
                        title: "成立日期",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "address",
                        title: "详细地址",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "area",
                        title: "所属地区",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "tip",
                        title: "标签",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "industryType",
                        title: "所属行业",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "createTime",
                        title: "创建时间",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "createName",
                        title: "创建人",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "source",
                        title: "来源",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "企业全称",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "企业税号",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "所在地区",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "客户联系人",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "合同",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "角色",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "联系电话",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "开户行",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "银行账号",
                        isShow: true,
                        isDisable: false,
                    }, {
                        title: "是否是开票客户",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        key: "followTime",
                        title: "跟进时间",
                        width: "100",
                        isShow: true,
                        isDisable: false,
                    }
                ]
            },

            // 工作交接
            clientConnect: {
                modelChildMenu: [{
                    title: "批量分配",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "批量回收角色",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, ],

                explicitData: []
            },

            // 流失审核
            clientCheck: {
                modelChildMenu: [{
                    title: "新增流失",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "删除流失",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "查看操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "审核",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],
                explicitData: [{
                    title: "客户全称",
                    key: "clientName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "160",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户编号",
                    key: "clientNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户状态",
                    key: "clientStatus", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "流失服务类别",
                    key: "lossServiceType", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "提交人",
                    key: "submit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "申请时间",
                    key: "applyTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "流失原因",
                    key: "cause", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "申请状态",
                    key: "applyStatus", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "原因说明",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "附件",
                    isShow: true,
                    isDisable: false,
                }, ]
            },

            // 回收站
            clientRecycle: {
                modelChildMenu: [{
                    title: "还原",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, ],

                explicitData: []
            },

            // 设置
            clientSetting: {
                modelChildMenu: [{
                        title: "选项值设置",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "流程设置",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "权限设置",
                        isShow: false,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "签约主体/开票抬头设置",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }
                ],

                explicitData: []
            },
        },

        // 合同模块
        contract: {
            modelChildMenu: [{
                    title: "合同",
                    path: '1',
                    isShow: true,
                    isDisable: false,
                    children: [{
                            title: "全部合同",
                            path: 'contract-info',
                            model: "contractInfo",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "合同审核",
                            path: 'contract-check',
                            model: "contractCheck",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "合同派工",
                            path: 'contract-work',
                            model: "contractWork",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "续签管理",
                            path: 'contract-renew',
                            model: "contractRenew",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "财务收款",
                            path: 'contract-payment',
                            model: "ContractPayment",
                            isShow: false,
                            isDisable: true,
                        }, {
                            title: "记单合同",
                            path: 'contract-note',
                            model: "ContractNote",
                            isShow: false,
                            isDisable: true,
                        }
                    ]
                },
                {
                    title: "设置",
                    path: "contract-setting",
                    model: "contractSetting",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
            ],

            // 周期合同
            contractInfo: {
                modelChildMenu: [{
                    title: "新增合同",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "合同变更",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "添加服务项目",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "修改回款计划",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "添加合同支出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "上传合同附件",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "设置提成",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除合同",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "撤销提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "退回待提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "审核",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "派工",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "续签",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "恢复合同",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "回款",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "查账",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "下单",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "手动结束",
                    isShow: false,
                    isDisable: true,
                    path: ""
                }, {
                    title: "电子合同生成",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "支出审核",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: [{
                    title: "客户全称",
                    key: "clientName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "160",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同编号",
                    key: "contractNumber", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "120",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户编号",
                    key: "clientNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务项",
                    key: "services", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已收费至",
                    key: "hasChargeTo", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同状态",
                    key: "status", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同金额",
                    key: "money", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "标准金额",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已登记收入",
                    key: "registeredIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已审核收入",
                    key: "auditedIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "支出金额",
                    key: "amountMoney", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "利润",
                    key: "profit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "到期日期",
                    key: "dateDue", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务产品",
                    key: "product", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费周期",
                    key: "unit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "首次收费时间",
                    key: "firstChargeTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "120",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "片区",
                    key: "pianqu", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "所属部门",
                    key: "department", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户等级",
                    key: "clientLevel", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "续签状态",
                    key: "renewStatus", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "自动续签",
                    key: "autoRenew", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务顾问",
                    key: "serviceConsultant", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费负责人",
                    key: "charge", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "跟进人",
                    key: "single", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单日期",
                    key: "signTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "开始日期",
                    key: "contractStartTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人部门",
                    key: "signDepartment", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "来源",
                    key: "customerSource", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "是否赠送服务",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同成本",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "记单",
                    key: "achievement",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同附件",
                    key: "attachment",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收款状态",
                    key: "receiveStatus",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人",
                    isShow: true,
                    isDisable: false,
                }, ]

            },

            // 合同审核
            contractCheck: {
                modelChildMenu: [{
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "查账",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "下单",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "删除合同",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "审核",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "派工",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "续签",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],

                explicitData: [{
                    title: "客户全称",
                    key: "clientName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "160",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同编号",
                    key: "contractNumber", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "120",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户编号",
                    key: "clientNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务项",
                    key: "services", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已收费至",
                    key: "hasChargeTo", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同状态",
                    key: "status", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同金额",
                    key: "money", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "标准金额",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已登记收入",
                    key: "registeredIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已审核收入",
                    key: "auditedIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "支出金额",
                    key: "amountMoney", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "利润",
                    key: "profit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "到期日期",
                    key: "dateDue", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务产品",
                    key: "product", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费周期",
                    key: "unit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "首次收费时间",
                    key: "firstChargeTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "120",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "片区",
                    key: "pianqu", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "所属部门",
                    key: "department", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户等级",
                    key: "clientLevel", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "续签状态",
                    key: "renewStatus", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "自动续签",
                    key: "autoRenew", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务顾问",
                    key: "serviceConsultant", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费负责人",
                    key: "charge", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人",
                    key: "single", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单日期",
                    key: "signTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "开始日期",
                    key: "contractStartTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人部门",
                    key: "signDepartment", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "来源",
                    key: "customerSource", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "凭证交接",
                    isShow: true,
                    isDisable: false,
                }, ]
            },

            // 财务收款
            contractPayment: {
                modelChildMenu: [{
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "企业微信同步",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "退款",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 合同记单
            contractNote: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 合同派工
            contractWork: {

                modelChildMenu: [{
                    title: "提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "派工审核",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "派工",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],

                explicitData: [{
                    title: "客户全称",
                    key: "clientName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "160",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同编号",
                    key: "contractNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户编号",
                    key: "clientNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务项",
                    key: "services", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "业务类型名称",
                    key: "professionName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同状态",
                    key: "status", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "到期日期",
                    key: "dateDue", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务顾问",
                    key: "serviceConsultant", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人",
                    key: "single", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单时间",
                    key: "signTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同开始时间",
                    key: "contractStartTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "当前步骤",
                    key: "step", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "当前步骤执行人员",
                    key: "stepExecute", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "业务类型备注",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "项目要求",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "所需资料",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "项目成果",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "项目步骤",
                    isShow: true,
                    isDisable: false,
                }, ]
            },

            // 续签管理
            contractRenew: {
                modelChildMenu: [{
                    title: "续费提醒",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "合同变更",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "添加服务项目",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "修改回款计划",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "添加合同支出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "上传合同附件",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "设置提成",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "删除合同",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "提交",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "审核",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "派工",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "续签",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "操作记录",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "手动结束",
                    isShow: false,
                    isDisable: true,
                    path: ""
                }],

                explicitData: [{
                    title: "客户全称",
                    key: "clientName", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "160",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同编号",
                    key: "contractNumber", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户编号",
                    key: "clientNum", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务项",
                    key: "services", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已收费至",
                    key: "hasChargeTo", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同状态",
                    key: "status", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "合同金额",
                    key: "money", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "标准金额",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已登记收入",
                    key: "registeredIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "已审核收入",
                    key: "auditedIncome", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "支出金额",
                    key: "amountMoney", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "利润",
                    key: "profit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "到期日期",
                    key: "dateDue", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务产品",
                    key: "product", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费周期",
                    key: "unit", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "首次收费时间",
                    key: "firstChargeTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "片区",
                    key: "pianqu", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "所属部门",
                    key: "department", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "客户等级",
                    key: "clientLevel", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "续签状态",
                    key: "renewStatus", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "自动续签",
                    key: "autoRenew", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "服务顾问",
                    key: "serviceConsultant", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "收费负责人",
                    key: "charge", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人",
                    key: "single", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单日期",
                    key: "signTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "开始日期",
                    key: "contractStartTime", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "推荐人",
                    key: "referrer", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "签单人部门",
                    key: "signDepartment", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "来源",
                    key: "customerSource", // 是否成为表头菜单,没有key,表示不是表头菜单
                    width: "100",
                    isShow: true,
                    isDisable: false,
                }, {
                    title: "凭证交接",
                    isShow: true,
                    isDisable: false,
                }, ]
            },

            // 设置
            contractSetting: {
                modelChildMenu: [{
                    title: "业务设置",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, {
                    title: "流程设置",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "流程编辑",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "权限设置",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "签约主体/开票抬头设置",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }],

                explicitData: []
            }
        },

        // 产品模块
        product: {
            modelChildMenu: [{
                title: "产品",
                path: '1',
                isShow: true,
                isDisable: false,
                children: [{
                        title: "合同模板设置",
                        path: 'product-list',
                        model: "productList",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "服务项管理",
                        path: 'serve-item',
                        model: "serveItem",
                        isShow: true,
                        isDisable: false,
                    }
                ]
            }, ],

            // 产品组合
            productList: {
                modelChildMenu: [{
                    title: "添加",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "停用",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: []
            },

            // 服务项管理
            serveItem: {
                modelChildMenu: [{
                    title: "添加服务项类别",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑服务项类别",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除服务项类别",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "添加服务项",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑服务项",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "停用服务项",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除服务项",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: []
            },
        },

        // 发票模块
        invoice: {
            modelChildMenu: [{
                title: "发票开具",
                path: '1',
                isShow: false,
                isDisable: true,
                children: [{
                        title: "发票开具",
                        path: 'invoice-info',
                        model: "invoiceInfo",
                        isShow: false,
                        isDisable: true,
                    },
                    {
                        title: "发票审核",
                        path: 'invoice-check',
                        model: "InvoiceCheck",
                        isShow: false,
                        isDisable: true,
                    },
                    {
                        title: "发票汇总",
                        path: 'invoice-collect',
                        model: "InvoiceCollect",
                        isShow: false,
                        isDisable: true,
                    }
                ]
            }, {
                title: "发票采集",
                path: '1',
                isShow: false,
                isDisable: true,
                children: [{
                        title: "理票登记",
                        path: 'invoice-register',
                        model: "invoiceRegister",
                        isShow: false,
                        isDisable: true,
                    },
                    {
                        title: "进销项采集",
                        path: 'invoice-gather',
                        model: "InvoiceGather",
                        isShow: false,
                        isDisable: true,
                    }
                ]
            }, {
                title: "开票设置",
                path: '1',
                isShow: false,
                isDisable: true,
                children: [{
                        title: "客户设置",
                        path: 'invoice-setting',
                        model: "InvoiceSetting",
                        isShow: false,
                        isDisable: true,
                    },
                    {
                        title: "常用商品设置",
                        path: 'commodity-setting',
                        model: "CommoditySetting",
                        isShow: false,
                        isDisable: true,
                    }
                ]
            }, ],

            // 发票开具
            invoiceInfo: {
                modelChildMenu: [{
                    title: "发票开具",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "发票预览",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "发票提交",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导入",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 发票审核
            InvoiceCheck: {
                modelChildMenu: [{
                    title: "发票审核",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 发票汇总
            InvoiceCollect: {
                modelChildMenu: [{
                    title: "发票汇总",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 理票登记
            invoiceRegister: {
                modelChildMenu: [{
                    title: "理票登记",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 进销项采集
            InvoiceGather: {
                modelChildMenu: [{
                    title: "发票录入",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导入",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 开票设置
            InvoiceSetting: {
                modelChildMenu: [{
                    title: "税号绑定",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "机柜绑定",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, {
                    title: "解绑",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 开票设置
            CommoditySetting: {
                modelChildMenu: [{
                    title: "常用商品设置",
                    isShow: false,
                    isDisable: true,
                    path: ''
                }],
                explicitData: []
            },

        },

        // 待办模块
        waite: {
            modelChildMenu: [{
                title: "全部待办",
                path: '1',
                isShow: true,
                isDisable: false,
                children: []
            }]
        },

        // 报表模块
        report: {
            modelChildMenu: [{
                    title: "报表",
                    path: '1',
                    isShow: true,
                    isDisable: true,
                    children: [{
                        title: "收款报表",
                        path: 'report-collection',
                        model: "reportCollection",
                        isShow: true,
                        isDisable: true,
                    }, {
                        title: "销售统计",
                        path: 'report-sales',
                        model: "reportSales",
                        isShow: true,
                        isDisable: true,
                    }, {
                        title: "续费统计",
                        path: 'report-renewal',
                        model: "reportRenewal",
                        isShow: true,
                        isDisable: true,
                    }, {
                        title: "跟进记录",
                        path: 'report-follow',
                        model: "reportFollow",
                        isShow: true,
                        isDisable: true,
                    }, {
                        title: "报销报表",
                        path: 'report-reimburse',
                        model: "reportReimburse",
                        isShow: true,
                        isDisable: true,
                    }, ]
                },
                {
                    title: "员工信息",
                    path: "employee-information",
                    model: "employeeInformation",
                    isShow: true,
                    isDisable: true,
                    children: [],
                },
            ],

            // 收款报表
            reportCollection: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }, ],
            },

            // 销售统计
            reportSales: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],
            },

            // 续费统计
            reportRenewal: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],
            },

            // 跟进记录
            reportFollow: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],
            },

            // 报销报表
            reportReimburse: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],
            },

            // 员工信息
            employeeInformation: {
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: false,
                    path: ''
                }],
            },
        },

        // 系统配置模块
        system: {

            //左侧模块子菜单  如果children为空,或者children全部不可见,则父菜单也不可见
            modelChildMenu: [{
                title: "机构设置",
                path: '1',
                isShow: true,
                isDisable: false,
                children: [{
                        title: "机构设置",
                        path: 'company-setting',
                        model: "company",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "部门设置",
                        path: 'department-setting',
                        model: "department",
                        isShow: true,
                        isDisable: false,
                    },
                    {
                        title: "员工设置",
                        path: 'employee-setting',
                        model: "employee",
                        isShow: true,
                        isDisable: false,
                    }
                ]
            }],
            // 子模块--机构设置
            company: {

                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "新建机构",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "修改机构信息",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "删除机构",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }
                ],

                explicitData: []


            },

            // 子模块--员工设置
            employee: {

                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "新建员工",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "修改员工信息",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "删除员工",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "记单修改",
                        isShow: false,
                        isDisable: true,
                        path: ""
                    }, {
                        title: "离职并交接",
                        isShow: false,
                        isDisable: true,
                        path: ""
                    }
                ],

                explicitData: []

            },

            // 子模块--部门及角色设置
            department: {

                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                        title: "新增部门",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "编辑部门",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "删除部门",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "编辑部门主管权限",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "编辑部门员工权限",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    },
                    {
                        title: "添加/删除主管",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }, {
                        title: "添加/删除员工",
                        isShow: true,
                        isDisable: true,
                        path: ''
                    }
                ],

                explicitData: []

            },
        },

        // 业务角色配置
        businessRole: {

            //左侧模块子菜单  如果children为空,或者children全部不可见,则父菜单也不可见
            modelChildMenu: [{
                title: "系统设置",
                path: '1',
                isShow: true,
                isDisable: false,
                children: [{
                    title: "角色设置",
                    path: 'role-setting',
                    model: "businessRole",
                    isShow: true,
                    isDisable: false,
                }]
            }],

            // 业务角色
            businessRole: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                    title: "新增角色",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑角色",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除角色",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "设置默认人员",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],

                explicitData: []

            }
        },

        // 知识库模块
        knowledge: {
            modelChildMenu: [{
                title: "知识库",
                path: 'knowledge-base',
                model: "knowledgeBase",
                isShow: true,
                isDisable: false,
                children: []
            }, {
                title: "公告",
                path: 'public-notice',
                model: "publicNotice",
                isShow: true,
                isDisable: false,
                children: []
            }, {
                title: "设置",
                path: 'knowledge-setting',
                model: "knowledgeSetting",
                isShow: true,
                isDisable: false,
                children: []
            }, ],

            knowledgeBase: {
                modelChildMenu: [{
                    title: "新增",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: []
            },
            publicNotice: {
                modelChildMenu: [{
                    title: "新增",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],

                explicitData: []
            }
        },

        // 人力资源模块
        human: {
            modelChildMenu: [{
                title: "我的人才",
                path: 'my-human',
                model: "myHuman",
                isShow: true,
                isDisable: false,
                children: []
            }, {
                title: "人才库",
                path: 'human-base',
                model: "humanBase",
                isShow: true,
                isDisable: false,
                children: []
            }, {
                title: "人才库",
                path: 'human-setting',
                model: "humanSetting",
                isShow: true,
                isDisable: false,
                children: []
            }, ],

            // 我的人才
            myHuman: {
                modelChildMenu: [{
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "管理",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "放弃",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "预约",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 人才库
            humanBase: {
                modelChildMenu: [{
                    title: "导入",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "获取",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "跟进",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, ],
                explicitData: []
            },

            // 人才设置
            humanSetting: {
                modelChildMenu: [],
                explicitData: []
            }
        },

        // 报销模块
        reimbursement: {
            modelChildMenu: [{
                    title: "报销",
                    path: '1',
                    isShow: true,
                    isDisable: false,
                    children: [{
                            title: "我的报销",
                            path: 'my-reimbursement',
                            model: "myReimbursement",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "全部报销",
                            path: 'all-reimbursement',
                            model: "allReimbursement",
                            isShow: true,
                            isDisable: false,
                        },
                        {
                            title: "我的审批",
                            path: "check-reimbursement",
                            model: "checkReimbursement",
                            isShow: true,
                            isDisable: false,
                        }
                    ]
                },
                {
                    title: "报销设置",
                    path: "reimbursement-setting",
                    model: "reimbursementSetting",
                    isShow: true,
                    isDisable: false,
                    children: [],
                },
            ],

            // 子模块 -- 我的报销
            myReimbursement: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                    title: "新建报销",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "重新提交",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "撤销",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],

                //表格中每条数据展开的侧栏数据
                explicitData: []
            },

            // 子模块 -- 全部报销
            allReimbursement: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "删除",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],

                //表格中每条数据展开的侧栏数据
                explicitData: []
            },

            // 子模块 -- 我的审批
            checkReimbursement: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                    title: "导出",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "审批",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "驳回",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "付款",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],

                //表格中每条数据展开的侧栏数据
                explicitData: []
            },

            // 子模块 -- 报销设置
            reimbursementSetting: {
                //每个子菜单下的功能按钮 每进一个子菜单 单独加载一次当前子菜单界面的所有功能按钮
                modelChildMenu: [{
                    title: "编辑选项值",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "报销流程",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }, {
                    title: "编辑流程",
                    isShow: true,
                    isDisable: true,
                    path: ''
                }],

                //表格中每条数据展开的侧栏数据
                explicitData: []
            },
        }
    }

    return data
}


/**
 * 获取对应模块的对应数据的权限,
 * 如获取商机模块下我的商机中,功能按钮的权限
 *      getModelAuthority(["thread","myThread"],"modelChildMenu",(data)=>{},this)
 * 若获取的模块没有子模块,如获取商机模块下左侧模块子菜单权限
 *      getModelAuthority(["thread"],"modelChildMenu",(data)=>{},this)
 * 若只获取顶部菜单等这种不在模块下的
 *      getModelAuthority([],"topMenu",(data)=>{},this)
 * @param {*} model 模块
 * @param {*} part 要获取权限的对应部分
 * @param {*} real  回调方法
 * @param {*} this_ 
 */
export function getModelAuthority(model, part, this_, isMenu, real) {
    let requestData = {
        model: model,
        part: part,
        userId: this_.baseInfo.userId,
        company: this_.baseInfo.company.companyId,
        isMenu: isMenu
    }
    if (this_.baseInfo.userId == "super") {
        real([])
    } else {
        this_.$request(
            "post",
            "/getModelAuthority",
            requestData,
            (data) => {
                real(data);
            },
            (errorMessage) => {
                this_.$message.error(errorMessage);
            }
        )
    }
}

/**
 * 获取权限显示/修改界面,即查看部门或业务角色所有权限
 * @param {*} model 当前要显示在界面上的模块权限
 * @param {*} data 该角色下的所有权限
 */
export function getAllRoleAuthorityList(model, data) {
    let modelName = ""
    let modelShow = true
    let modelMenuAuthor = []
    let authorData = []
    let flag = 0

    let dd = {
        menu: "",
        model: "",
        isShow: true
    }

    let authorDataItem = {
        title: "",
        model: "",
        buttonAuth: [],
        data: []
    }
    let buttonAuth = {
        title: "",
        value: 1, // 1为读写;2为只读;3为不可见
        isShow: true,
        isDisable: false
    }
    let authorDataItemData = {
        title: "",
        value: 1, // 1为读写;2为只读;3为不可见
        width: "100",
        key: "",
        isShow: true,
        isDisable: false
    }
    switch (model) {
        case "thread":
            // 选择商机模块
            modelName = "商机"
                // 商机模块子菜单的显示
            data.thread.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 商机 --> 我的商机
            authorDataItem.title = "我的商机"
            data.thread.myThread.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.myThread.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 全部商机
            authorDataItem.title = "全部商机"
            data.thread.allThread.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.allThread.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 我的资源
            authorDataItem.title = "我的资源"
            data.thread.myResources.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.myResources.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 已签约客户
            authorDataItem.title = "已签约客户"
            data.thread.clientThread.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.clientThread.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 商机池
            authorDataItem.title = "商机池"
            data.thread.threadPool.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.threadPool.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 回收站
            authorDataItem.title = "回收站"
            data.thread.threadRecycle.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.threadRecycle.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 商机 --> 权限设置
            authorDataItem.title = "权限设置"
            data.thread.threadSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.thread.threadSetting.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }
            break;
        case "client":
            // 选择客户模块
            modelName = "客户"

            // 客户模块子菜单的显示
            data.client.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 客户 --> 客户资料
            data.client.clientInfo.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            data.client.clientInfo.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 客户 --> 工作交接
            data.client.clientConnect.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            data.client.clientConnect.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 客户 --> 流失审核
            data.client.clientCheck.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            data.client.clientCheck.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 客户 --> 回收站
            data.client.clientRecycle.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            data.client.clientRecycle.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 客户 --> 设置
            data.client.clientSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            data.client.clientSetting.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            break;
        case "contract":
            // 选择合同模块
            modelName = "合同"
                // 合同模块子菜单的显示
            data.contract.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 合同 --> 周期合同
            data.contract.contractInfo.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractInfo.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 合同审核
            data.contract.contractCheck.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractCheck.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 合同派工
            data.contract.contractWork.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractWork.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 续签管理
            data.contract.contractRenew.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractRenew.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 财务收款
            data.contract.contractPayment.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractPayment.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 合同记单
            data.contract.contractNote.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractNote.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 合同 --> 设置
            data.contract.contractSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.contract.contractSetting.explicitData = []
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }
            break;
        case "product":
            modelName = "产品"

            // 产品模块子菜单的显示
            data.product.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 产品 --> 产品组合
            data.product.productList.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.product.productList.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 产品 --> 服务项管理
            data.product.serveItem.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.product.serveItem.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }
            break;
        case "invoice":
            modelName = "发票"

            // 产品模块子菜单的显示
            data.invoice.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 发票 --> 发票开具
            data.invoice.invoiceInfo.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.invoiceInfo.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 发票审核
            data.invoice.InvoiceCheck.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.InvoiceCheck.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 发票汇总
            data.invoice.InvoiceCollect.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.InvoiceCollect.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 理票登记
            data.invoice.invoiceRegister.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.invoiceRegister.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 进销项采集
            data.invoice.InvoiceGather.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.InvoiceGather.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 客户设置
            data.invoice.InvoiceSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.InvoiceSetting.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 发票 --> 常用商品设置
            data.invoice.CommoditySetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.invoice.CommoditySetting.explicitData = [];
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }
            break;
        case "system":
            // 选择系统模块
            modelName = "机构设置"
                // 系统模块子菜单的显示
            data.system.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 系统配置 --> 机构设置
            data.system.company.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.system.company.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 系统配置 --> 部门设置
            data.system.department.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.system.department.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 系统配置 --> 员工配置
            data.system.employee.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.system.employee.explicitData.forEach(element => {
                authorDataItemData.title = element.title
                authorDataItemData.key = element.key
                authorDataItemData.width = element.width
                authorDataItemData.isShow = element.isShow
                authorDataItemData.isDisable = element.isDisable
                authorDataItemData.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.data.push(JSON.parse(JSON.stringify(authorDataItemData)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            break
        case "businessRole":
            // 选择业务模块
            modelName = "角色设置"
                // 业务角色模块子菜单的显示
            data.businessRole.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 业务角色 --> 业务角色
            data.businessRole.businessRole.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });

            data.businessRole.businessRole.explicitData = []
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            break;
        case "waite":
            // 选择系统模块
            modelName = "待办"
                // 系统模块子菜单的显示
            data.waite.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            })
            break
        case "report":
            // 报表模块
            // 选择报表模块
            modelName = "报表"
                // 报表模块子菜单的显示
            data.report.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 报表 --> 收款报表
            data.report.reportCollection.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报表 --> 销售统计
            data.report.reportSales.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报表 --> 续费统计
            data.report.reportRenewal.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报表 --> 跟进记录
            data.report.reportFollow.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报表 --> 报销报表
            data.report.reportReimburse.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报表 --> 员工信息
            data.report.employeeInformation.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            break;
        case "knowledge":
            // 知识库
            modelName = "知识库"
                // 报表模块子菜单的显示
            data.knowledge.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 知识库 --> 知识库
            data.knowledge.knowledgeBase.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 知识库 --> 公告
            data.knowledge.publicNotice.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }



            break;
        case "human":
            // 人力资源模块
            modelName = "人才"
                // 报表模块子菜单的显示
            data.human.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 人力资源模块 --> 我的人才
            data.human.myHuman.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 人力资源模块 --> 人才库
            data.human.humanBase.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 人力资源模块 --> 人才设置
            data.human.humanSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }



            break;
        case "reimbursement":
            // 报销模块
            modelName = "报销"
                // 报销模块子菜单的显示
            data.reimbursement.modelChildMenu.forEach(auth => {
                if (auth.children.length > 0) {
                    auth.children.forEach(el => {
                        dd.menu = el.title
                        dd.model = el.model
                        dd.isShow = el.isShow
                        modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else {
                    dd.menu = auth.title
                    dd.model = auth.model
                    dd.isShow = auth.isShow
                    modelMenuAuthor.push(JSON.parse(JSON.stringify(dd)))
                }
            });

            // 报销模块 --> 我的报销
            data.reimbursement.myReimbursement.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报销模块 --> 全部报销
            data.reimbursement.allReimbursement.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报销模块 --> 我的审批
            data.reimbursement.checkReimbursement.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }

            // 报销模块 --> 设置
            data.reimbursement.reimbursementSetting.modelChildMenu.forEach(element => {
                buttonAuth.title = element.title
                buttonAuth.isShow = element.isShow
                buttonAuth.isDisable = element.isDisable
                buttonAuth.path = element.path
                buttonAuth.value = element.isShow ? (element.isDisable ? 2 : 1) : 3
                authorDataItem.buttonAuth.push(JSON.parse(JSON.stringify(buttonAuth)))
            });
            authorData.push(JSON.parse(JSON.stringify(authorDataItem)))
            authorDataItem = {
                title: "",
                model: "",
                buttonAuth: [],
                data: []
            }



            break;
        default:
            break;
    }
    // 当前大模块是否显示 在顶部菜单中找
    data.topMenu.forEach(menu => {
        if (menu.title == modelName) {
            flag = 1
            modelShow = menu.isShow
        }
    });
    if (flag == 0) {
        //  当前大模块是否显示 在全局菜单、头像下的菜单中找
        data.generalMenu.forEach(menu => {
            if (menu.title == modelName) {
                flag = 1
                modelShow = menu.isShow
            }
        });
    }
    let returnData = {
        model: model,
        modelName: modelName, // 模块
        modelShow: modelShow, // 当前模块的可见性
        modelMenuAuthor: modelMenuAuthor,
        authorData: authorData
    }

    return returnData

}

/**
 * 格式化权限叠加提交数据
 * @param {*} authorityData  从数据库中读取的所有权限数据
 * @param {*} model 当前修改的model
 * @param {*} data 当前修改的model权限数据
 */
export function formatSubmitAllRoleAuthorityList(authorityData, model, data) {
    console.log("权限设置:", data);
    switch (model) {
        case "thread":
            // 修改商机模块
            // 修改大模块权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "商机") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.thread.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 我的商机
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.thread.myThread.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.thread.myThread.explicitData = data.authorData[0].data

            // 全部商机
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.thread.allThread.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.thread.allThread.explicitData = data.authorData[1].data

            // 我的资源
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.thread.myResources.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.thread.myResources.explicitData = data.authorData[2].data

            // 已签约客户
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.thread.clientThread.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.thread.clientThread.explicitData = data.authorData[3].data

            // 商机池
            data.authorData[4].buttonAuth = systemAuthorityData.formatValueData(data.authorData[4].buttonAuth)
            data.authorData[4].data = systemAuthorityData.formatValueData(data.authorData[4].data)
            authorityData.thread.threadPool.modelChildMenu = data.authorData[4].buttonAuth
            authorityData.thread.threadPool.explicitData = data.authorData[4].data

            // 回收站
            data.authorData[5].buttonAuth = systemAuthorityData.formatValueData(data.authorData[5].buttonAuth)
            data.authorData[5].data = systemAuthorityData.formatValueData(data.authorData[5].data)
            authorityData.thread.threadRecycle.modelChildMenu = data.authorData[5].buttonAuth
            authorityData.thread.threadRecycle.explicitData = data.authorData[5].data

            // 设置
            data.authorData[6].buttonAuth = systemAuthorityData.formatValueData(data.authorData[6].buttonAuth)
            data.authorData[6].data = systemAuthorityData.formatValueData(data.authorData[6].data)
            authorityData.thread.threadSetting.modelChildMenu = data.authorData[6].buttonAuth
            authorityData.thread.threadSetting.explicitData = data.authorData[6].data
            break;

        case "client":
            authorityData.topMenu.forEach(element => {
                if (element.title == "客户") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.client.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 客户资料
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.client.clientInfo.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.client.clientInfo.explicitData = data.authorData[0].data

            // 工作交接
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.client.clientConnect.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.client.clientConnect.explicitData = data.authorData[1].data

            // 流失审核
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.client.clientCheck.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.client.clientCheck.explicitData = data.authorData[2].data

            // 回收站
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.client.clientRecycle.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.client.clientRecycle.explicitData = data.authorData[3].data

            // 设置
            data.authorData[4].buttonAuth = systemAuthorityData.formatValueData(data.authorData[4].buttonAuth)
            data.authorData[4].data = systemAuthorityData.formatValueData(data.authorData[4].data)
            authorityData.client.clientSetting.modelChildMenu = data.authorData[4].buttonAuth
            authorityData.client.clientSetting.explicitData = data.authorData[4].data
            break;

        case "contract":
            // 修改合同模块
            // 修改合同权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "合同") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.contract.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 周期合同
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.contract.contractInfo.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.contract.contractInfo.explicitData = data.authorData[0].data

            // 合同审核
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.contract.contractCheck.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.contract.contractCheck.explicitData = data.authorData[1].data

            // 合同派工
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.contract.contractWork.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.contract.contractWork.explicitData = data.authorData[2].data

            // 续签管理
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.contract.contractRenew.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.contract.contractRenew.explicitData = data.authorData[3].data

            // 设置财务收款
            data.authorData[4].buttonAuth = systemAuthorityData.formatValueData(data.authorData[4].buttonAuth)
            data.authorData[4].data = systemAuthorityData.formatValueData(data.authorData[4].data)
            authorityData.contract.contractPayment.modelChildMenu = data.authorData[4].buttonAuth
            authorityData.contract.contractPayment.explicitData = data.authorData[4].data

            // 合同记单
            data.authorData[5].buttonAuth = systemAuthorityData.formatValueData(data.authorData[5].buttonAuth)
            data.authorData[5].data = systemAuthorityData.formatValueData(data.authorData[5].data)
            authorityData.contract.contractNote.modelChildMenu = data.authorData[5].buttonAuth
            authorityData.contract.contractNote.explicitData = data.authorData[5].data


            // 设置
            data.authorData[6].buttonAuth = systemAuthorityData.formatValueData(data.authorData[6].buttonAuth)
            data.authorData[6].data = systemAuthorityData.formatValueData(data.authorData[6].data)
            authorityData.contract.contractSetting.modelChildMenu = data.authorData[6].buttonAuth
            authorityData.contract.contractSetting.explicitData = data.authorData[6].data
            break;

        case "product":
            authorityData.topMenu.forEach(element => {
                if (element.title == "产品") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.product.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 我的商机
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.product.productList.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.product.productList.explicitData = data.authorData[0].data

            // 全部商机
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.product.serveItem.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.product.serveItem.explicitData = data.authorData[1].data
            break;

        case "invoice":
            authorityData.topMenu.forEach(element => {
                if (element.title == "发票") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.invoice.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 发票开具
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.invoice.invoiceInfo.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.invoice.invoiceInfo.explicitData = data.authorData[0].data

            // 发票审核
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.invoice.InvoiceCheck.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.invoice.InvoiceCheck.explicitData = data.authorData[1].data

            // 发票汇总
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.invoice.InvoiceCollect.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.invoice.InvoiceCollect.explicitData = data.authorData[2].data

            // 理票登记
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.invoice.invoiceRegister.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.invoice.invoiceRegister.explicitData = data.authorData[3].data

            // 进销项采集
            data.authorData[4].buttonAuth = systemAuthorityData.formatValueData(data.authorData[4].buttonAuth)
            data.authorData[4].data = systemAuthorityData.formatValueData(data.authorData[4].data)
            authorityData.invoice.InvoiceGather.modelChildMenu = data.authorData[4].buttonAuth
            authorityData.invoice.InvoiceGather.explicitData = data.authorData[4].data

            // 客户设置
            data.authorData[5].buttonAuth = systemAuthorityData.formatValueData(data.authorData[5].buttonAuth)
            data.authorData[5].data = systemAuthorityData.formatValueData(data.authorData[5].data)
            authorityData.invoice.InvoiceSetting.modelChildMenu = data.authorData[5].buttonAuth
            authorityData.invoice.InvoiceSetting.explicitData = data.authorData[5].data

            // 常用商品设置
            data.authorData[6].buttonAuth = systemAuthorityData.formatValueData(data.authorData[6].buttonAuth)
            data.authorData[6].data = systemAuthorityData.formatValueData(data.authorData[6].data)
            authorityData.invoice.CommoditySetting.modelChildMenu = data.authorData[6].buttonAuth
            authorityData.invoice.CommoditySetting.explicitData = data.authorData[6].data
            break;

        case "system":
            // 修改系统配置模块
            // 修改大模块权限
            authorityData.generalMenu.forEach(element => {
                if (element.title == "机构设置") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.system.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.title) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                }
                            }
                        });
                        menu.isShow = flag
                    } else {
                        if (menu.title == sonModelAuth.title) {
                            menu.isShow = sonModelAuth.isShow
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 机构设置
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.system.company.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.system.company.explicitData = data.authorData[0].data

            // 员工设置
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.system.department.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.system.department.explicitData = data.authorData[1].data

            // 部门设置
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.system.employee.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.system.employee.explicitData = data.authorData[2].data
            break;

        case "businessRole":
            // 修改系统配置模块
            // 修改大模块权限
            authorityData.generalMenu.forEach(element => {
                if (element.title == "角色设置") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.businessRole.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.title) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                }
                            }
                        });
                        menu.isShow = flag
                    } else {
                        if (menu.title == sonModelAuth.title) {
                            menu.isShow = sonModelAuth.isShow
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 机构设置
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.businessRole.businessRole.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.businessRole.businessRole.explicitData = data.authorData[0].data
            break;

        case "waite":
            // 修改系统配置模块
            // 修改大模块权限
            authorityData.generalMenu.forEach(element => {
                if (element.title == "待办") {
                    element.isShow = data.modelShow
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.waite.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.title) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });
            break;

        case "report":
            // 报表模块
            // 修改报表权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "报表") {
                    element.isShow = data.modelShow
                    element.isDisable = false
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.report.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 收款报表
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.report.reportCollection.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.report.reportCollection.explicitData = data.authorData[0].data


            // 销售统计
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.report.reportSales.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.report.reportSales.explicitData = data.authorData[1].data


            // 续费统计
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.report.reportRenewal.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.report.reportRenewal.explicitData = data.authorData[2].data


            // 跟进记录
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.report.reportFollow.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.report.reportFollow.explicitData = data.authorData[3].data


            // 报销报表
            data.authorData[4].buttonAuth = systemAuthorityData.formatValueData(data.authorData[4].buttonAuth)
            data.authorData[4].data = systemAuthorityData.formatValueData(data.authorData[4].data)
            authorityData.report.reportReimburse.modelChildMenu = data.authorData[4].buttonAuth
            authorityData.report.reportReimburse.explicitData = data.authorData[4].data


            // 员工信息
            data.authorData[5].buttonAuth = systemAuthorityData.formatValueData(data.authorData[5].buttonAuth)
            data.authorData[5].data = systemAuthorityData.formatValueData(data.authorData[5].data)
            authorityData.report.employeeInformation.modelChildMenu = data.authorData[5].buttonAuth
            authorityData.report.employeeInformation.explicitData = data.authorData[5].data
            break

        case "knowledge":
            // 报表模块
            // 修改报表权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "知识库") {
                    element.isShow = data.modelShow
                    element.isDisable = data.modelShow == true ? false : true
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.knowledge.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 知识库
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.knowledge.knowledgeBase.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.knowledge.knowledgeBase.explicitData = data.authorData[0].data


            // 公告
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.knowledge.publicNotice.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.knowledge.publicNotice.explicitData = data.authorData[1].data


            break

        case "human":
            // 人力资源模块
            // 修改人力资源权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "人才") {
                    element.isShow = data.modelShow
                    element.isDisable = data.modelShow == true ? false : true
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.human.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 我的人才
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.human.myHuman.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.human.myHuman.explicitData = data.authorData[0].data


            // 人才库
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.human.humanBase.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.human.humanBase.explicitData = data.authorData[1].data


            // 人才设置
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.human.humanSetting.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.human.humanSetting.explicitData = data.authorData[2].data


            break

        case "reimbursement":
            // 报销模块
            // 修改报销权限
            authorityData.topMenu.forEach(element => {
                if (element.title == "报销") {
                    element.isShow = data.modelShow
                    element.isDisable = data.modelShow == true ? false : true
                }
            });

            // 修改模块下子模块菜单权限
            data.modelMenuAuthor.forEach(sonModelAuth => {
                authorityData.reimbursement.modelChildMenu.forEach(menu => {
                    if (menu.children.length > 0) {
                        let flag = false
                        menu.children.forEach(childMenu => {
                            if (childMenu.title == sonModelAuth.menu) {
                                childMenu.isShow = sonModelAuth.isShow
                                if (childMenu.isShow) {
                                    flag = true
                                    childMenu.isDisable = false
                                } else {
                                    childMenu.isDisable = true
                                }
                            }
                        });
                        menu.isShow = flag
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    } else {
                        if (menu.title == sonModelAuth.menu) {
                            menu.isShow = sonModelAuth.isShow
                        }
                        if (!menu.isShow) {
                            menu.isDisable = true
                        } else {
                            menu.isDisable = false
                        }
                    }
                });
            });

            // 修改模块下子模块具体权限
            // 我的人才
            data.authorData[0].buttonAuth = systemAuthorityData.formatValueData(data.authorData[0].buttonAuth)
            data.authorData[0].data = systemAuthorityData.formatValueData(data.authorData[0].data)
            authorityData.reimbursement.myReimbursement.modelChildMenu = data.authorData[0].buttonAuth
            authorityData.reimbursement.myReimbursement.explicitData = data.authorData[0].data


            // 人才库
            data.authorData[1].buttonAuth = systemAuthorityData.formatValueData(data.authorData[1].buttonAuth)
            data.authorData[1].data = systemAuthorityData.formatValueData(data.authorData[1].data)
            authorityData.reimbursement.allReimbursement.modelChildMenu = data.authorData[1].buttonAuth
            authorityData.reimbursement.allReimbursement.explicitData = data.authorData[1].data


            // 人才设置
            data.authorData[2].buttonAuth = systemAuthorityData.formatValueData(data.authorData[2].buttonAuth)
            data.authorData[2].data = systemAuthorityData.formatValueData(data.authorData[2].data)
            authorityData.reimbursement.checkReimbursement.modelChildMenu = data.authorData[2].buttonAuth
            authorityData.reimbursement.checkReimbursement.explicitData = data.authorData[2].data


            // 人才设置
            data.authorData[3].buttonAuth = systemAuthorityData.formatValueData(data.authorData[3].buttonAuth)
            data.authorData[3].data = systemAuthorityData.formatValueData(data.authorData[3].data)
            authorityData.reimbursement.reimbursementSetting.modelChildMenu = data.authorData[3].buttonAuth
            authorityData.reimbursement.reimbursementSetting.explicitData = data.authorData[3].data


            break


        default:
            break;
    }

    return authorityData
}

/**
 * 初始化表头数据
 */
export function initTableMenuData(data) {
    let list = []
    data.forEach(element => {
        if (element.key && element.isShow) {
            list.push(element)
        }
    });
    return list
}


/**
 * 4.15 获取当前用户在当前模块的显示权限
 */
export function getUserAuthorityByModels(this_, models, del) {
    
    let model = []
    let pathList = []
    for (let index = 0; index < models.length; index++) {
        
        let element = models[index];
        if (element.children && element.children.length > 0) {
            element.children.forEach(el => {
                model.push(el.active)
            });
        } else {
            model.push(element.active)
        }
    }
    let requestData = {
        userId: this_.baseInfo.userId,
        companyId: this_.baseInfo.company.companyId,
        model: model
    }
    this_.$request("post", "/featureRoleRel/getUserAuthorityByModels",
        requestData, (data) => {
            console.log('data', data)
            for (let index = 0; index < models.length; index++) {
                let element = models[index];
                if (element.children && element.children.length > 0) {
                    let ff = false;
                    element.children.forEach((el) => {
                        el.flag = data[el.active]
                        console.log('el.flag', el.flag)
                        console.log('data[el.active]', el.active)
                        if (el.flag == 1) {
                            ff = true;
                            pathList.push(el.path)
                        }
                    });
                    if (ff) {
                        element.flag = 1;
                    } else {
                        element.flag = 3;
                    }
                } else {
                    element.flag = data[element.active]
                    pathList.push(element.path)
                }

            }
            del(models, pathList)
        })
}

/**
 * 4.15 模拟接口
 * @param {*} this_ 
 * @param {*} models 
 * @param {*} del 
 */
export function testGetUserAuthorityByModels(this_, models, del) {
    let model = []
    let pathList = []
    for (let index = 0; index < models.length; index++) {
        let element = models[index];
        if (element.children && element.children.length > 0) {
            element.children.forEach(el => {
                model.push(el.active)
            });
        } else {
            model.push(element.active)
        }
    }
    let data = {}
    for (let index = 0; index < model.length; index++) {
        let element = model[index];
        this_.$set(data, element, Math.round(Math.random() * 2) + 1)

    }
    console.log(data);
    for (let index = 0; index < models.length; index++) {
        let element = models[index];
        if (element.children && element.children.length > 0) {
            let ff = false;
            element.children.forEach((el) => {
                el.flag = data[el.active]
                if (el.flag == 1) {
                    ff = true;
                    pathList.push(el.path)
                }
            });
            if (ff) {
                element.flag = 1;
            } else {
                element.flag = 3;
            }
        } else {
            element.flag = data[element.active]
            if (element.flag == 1) {
                pathList.push(element.path)
            }
        }

    }
    del(models, pathList)
}

/**
 * 4.16 获取当前用户在当前模块的功能/属性权限
 */
export function getModelFeatureAuthority(this_, model, keys, del) {
    let key = []
    for (let index = 0; index < keys.length; index++) {
        let element = keys[index];
        key.push(element.key)
    }
    let requestData = {    
        userId: this_.baseInfo.userId,
        companyId: this_.baseInfo.company.companyId,
        model: model,
        key: key
    }
   
    this_.$request("post", "/featureRoleRel/getModelFeatureAuthority", requestData, (data) => {

        for (let index = 0; index < keys.length; index++) {
            let element = keys[index];
            element.authority = data[element.key]
        }
        
        del(keys)
    })
}

/**
 * 4.16 模拟接口
 * @param {*} this_ 
 * @param {*} model 
 * @param {*} keys 
 * @param {*} del 
 */
export function testGetModelFeatureAuthority(this_, model, keys, del) {
    let key = []
    for (let index = 0; index < keys.length; index++) {
        let element = keys[index];
        key.push(element.key)
    }
    console.log(keys, key, "dddddddd");
    let data = {}
    for (let index = 0; index < key.length; index++) {
        let element = key[index];
        this_.$set(data, element, 1)
            // this_.$set(data, element, Math.round(Math.random() * 2) + 1)

    }
    console.log(data, "随机数");
    for (let index = 0; index < keys.length; index++) {
        let element = keys[index];
        element.authority = data[element.key]
    }
    del(keys)
}

/**
 * 4.17 获取当前用户在当前模块的习惯菜单以及对应属性的权限
 */
export function getModelMenuAndAuthority(this_, model, keys, del) {

    let key = []
    for (let index = 0; index < keys.length; index++) {
        let element = keys[index];
        key.push(element.active)
    }
    let requestData = {
        userId: this_.baseInfo.userId,
        companyId: this_.baseInfo.company.companyId,
        model: model,
        key: key
    }
    this_.$request("post", "/featureRoleRel/getModelMenuAndAuthority", requestData, (data) => {
        for (let index = 0; index < keys.length; index++) {
            let element = keys[index];
            element.flag = data[element.active]
        }
        del(data.menu, keys)
    })
}